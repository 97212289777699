import React, { useRef, useState } from "react";
import { RxCross1 } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import {
  clearFile,
  setLoading,
  uploadFile,
} from "../features/Files/FilesSlice";
import { IoAdd } from "react-icons/io5";
import { useEffect } from "react";
import {
  getCatagories,
  postCatagory,
  setLoadingCatg,
} from "../features/Catagory/CatagorySlice";
import {
  fetchAllSubCategory,
  fetchSubCategory,
  postSubCategory,
  setLoadingSubCatg,
  updateSubCategory,
} from "../features/SubCategory/SubCategorySlice";

const AddSubCategory = ({ handleAdding, CatgId, handleEditing, edit }) => {
  const initialState = {
    name: "",
    rating: "3",
    review: "400",
    description: "",
    title: "",
    iconUrls: "",
    thumbnailUrls: "",
    smallBannerM: "",
    imageIUrlA: "",
  };
  const { isLoading, uploadUrl } = useSelector((state) => state.file);
  const { isLoadingSubCatg } = useSelector((state) => state.subcategory);
  const dispatch = useDispatch();
  const [subCatagory, setSubCatagory] = useState(edit || initialState);
  const [uploading, setUploading] = useState(null); // 0 for icon 1 for thumbnail
  const [image0, setImage0] = useState(null);
  const [image1, setImage1] = useState(null);
  const [image2, setImage2] = useState(null);
  const [image3, setImage3] = useState(null);
  const inputRef0 = useRef(null);
  const inputRef1 = useRef(null);
  const inputRef2 = useRef(null);
  const inputRef3 = useRef(null);
  const handleImgClick = (select) => {
    if (select == 0) inputRef0.current.click();
    else if (select == 1) inputRef1.current.click();
    else if (select == 2) inputRef2.current.click();
    else if (select == 3) inputRef3.current.click();
  };

  const handleImageChange = async (e, select) => {
    const getImage = e.target.files[0];

    if (!getImage) {
      return;
    }
    console.log(select === 0, select, 0);
    if (select === 0) {
      setImage0({
        ...image0,
        file: getImage,
        filepreview: URL.createObjectURL(getImage),
      });
    } else if (select === 1) {
      console.log("went to else");
      setImage1({
        ...image1,
        file: getImage,
        filepreview: URL.createObjectURL(getImage),
      });
    } else if (select === 2) {
      console.log("went to else");
      setImage2({
        ...image2,
        file: getImage,
        filepreview: URL.createObjectURL(getImage),
      });
    } else if (select === 3) {
      console.log("went to else");
      setImage3({
        ...image3,
        file: getImage,
        filepreview: URL.createObjectURL(getImage),
      });
    }
  };

  // To capitalize String
  const capitalizeFirstLetter = (inputString) => {
    if (inputString.length === 0) {
      return inputString;
    }

    return inputString.charAt(0).toUpperCase() + inputString.slice(1);
  };
  const handleChange = (e) => {
    const name = e.target.name;
    const value = capitalizeFirstLetter(e.target.value);
    setSubCatagory({ ...subCatagory, [name]: value });
  };

  //copy image
  const handleCopyIcon = () => {
    const value = subCatagory?.iconUrls;
    setSubCatagory({ ...subCatagory, imageIUrlA: value });
  };
  const handleCopyBanner = () => {
    const value = subCatagory?.thumbnailUrls;
    setSubCatagory({ ...subCatagory, smallBannerM: value });
  };
  const handleUpload = (upload) => {
    const fileData = new FormData();
    setUploading(upload);
    if (upload == 0) {
      if (image0?.file) {
        fileData.append("file", image0.file, image0?.file?.name);
        dispatch(uploadFile(fileData));
      } else {
        alert("Nothing to upload!");
      }
    } else if (upload == 1) {
      if (image1?.file) {
        fileData.append("file", image1.file, image1?.file?.name);
        dispatch(uploadFile(fileData));
      } else {
        alert("Nothing to upload!");
      }
    } else if (upload == 2) {
      if (image2?.file) {
        fileData.append("file", image2.file, image2?.file?.name);
        dispatch(uploadFile(fileData));
      } else {
        alert("Nothing to upload!");
      }
    } else if (upload == 3) {
      if (image3?.file) {
        fileData.append("file", image3.file, image3?.file?.name);
        dispatch(uploadFile(fileData));
      } else {
        alert("Nothing to upload!");
      }
    }
  };

  const clearState = () => {
    dispatch(clearFile());
    handleAdding(false);
    handleEditing();
  };

  const handleSubmit = async () => {
    const {
      id,
      name,
      rating,
      review,
      description,
      title,
      iconUrls,
      thumbnailUrls,
      imageIUrlA,
      smallBannerM,
    } = subCatagory;
    if (
      name === "" ||
      iconUrls === "" ||
      smallBannerM === "" ||
      imageIUrlA === "" ||
      thumbnailUrls === ""
    ) {
      alert("Please Fill All Details!");
      return;
    }
    console.log(subCatagory);
    if (edit) {
      if (edit === subCatagory) {
        alert("No changes found!");
        return;
      }
      await dispatch(
        updateSubCategory({
          subCatId: id,
          data: {
            id: id,
            name: name,
            rating: rating,
            review: review,
            iconUrls: iconUrls,
            thumbnailUrls: thumbnailUrls,
            description: description,
            title: title,
            smallBannerM: smallBannerM,
            imageIUrlA: imageIUrlA,
          },
        })
      );
    } else
      await dispatch(
        postSubCategory({
          categoryId: CatgId,
          data: {
            name: name,
            rating: rating,
            review: review,
            iconUrls: iconUrls,
            thumbnailUrls: thumbnailUrls,
            description: description,
            title: title,
            smallBannerM: smallBannerM,
            imageIUrlA: imageIUrlA,
          },
        })
      );

    setTimeout(() => {
      if (CatgId >= 0) {
        dispatch(fetchSubCategory(CatgId));
      } else {
        dispatch(fetchAllSubCategory());
      }
      clearState();
    }, 2000);
  };

  useEffect(() => {
    console.log("useEffect", uploading);
    if (uploading == 0) setSubCatagory({ ...subCatagory, iconUrls: uploadUrl });
    else if (uploading == 1)
      setSubCatagory({ ...subCatagory, thumbnailUrls: uploadUrl });
    else if (uploading == 2)
      setSubCatagory({ ...subCatagory, imageIUrlA: uploadUrl });
    else if (uploading == 3)
      setSubCatagory({ ...subCatagory, smallBannerM: uploadUrl });
  }, [uploadUrl]);
  useEffect(() => {
    dispatch(setLoadingSubCatg());
    dispatch(setLoadingCatg(false));
  }, []);
  console.log(subCatagory);
  return (
    <div className="fixed w-full h-screen left-0 bg-[#00000094] z-40 flex items-center justify-center top-0">
      <div className="relative w-[450px] h-[65vh] bg-white flex items-center justify-center top-10 ">
        <RxCross1
          className="absolute right-0 -top-10 p-1 bg-white rounded-full cursor-pointer text-3xl"
          onClick={clearState}
        />

        <div className="uppercase font-mono absolute top-0 py-3 text-center text-xl shadow-inner w-full ">
          {(edit ? "Edit" : "Add") + " Subcatagory"}
        </div>
        <div className="w-11/12 h-5/6 overflow-auto no-scrollbar">
          {/* Name of catagory */}
          <div className="w-full h-14 my-6 border relative">
            <input
              type="text"
              className="w-full h-full outline-none p-4"
              placeholder="Name"
              name="name"
              value={subCatagory.name}
              onChange={handleChange}
            />
            <span className="absolute -top-2 bg-white px-1 text-gray-400 text-xs left-4 capitalize">
              Sub-Catagory Name
            </span>
          </div>
          {/* Review/ratings */}
          <div className="w-full h-14 flex gap-16 ">
            {/* review */}
            <div className="w-full h-full  border relative">
              <input
                type="text"
                className="w-full h-full outline-none p-4 opacity-70"
                placeholder="Review"
                name="review"
                value={subCatagory.review}
                onChange={handleChange}
                disabled
              />
              <span className="absolute -top-2 bg-white px-1 text-gray-400 text-xs left-4 capitalize">
                Reviews
              </span>
            </div>
            {/* Reating */}
            <div className="w-full h-full  border relative">
              <input
                type="text"
                className="w-full h-full outline-none p-4 opacity-70"
                placeholder="Rating"
                name="rating"
                value={subCatagory.rating}
                onChange={handleChange}
                disabled
              />
              <span className="absolute -top-2 bg-white px-1 text-gray-400 text-xs left-4 capitalize">
                Rating
              </span>
            </div>
          </div>
          {/* Icon */}
          <div className="w-[200px] mx-auto my-10 relative flex flex-col items-center justify-center gap-2 shadow p-2 pt-3 border-t">
            {image0 || subCatagory?.iconUrls ? (
              <img
                src={image0?.filepreview || subCatagory?.iconUrls}
                alt="Catagory Icon"
                className="h-24 w-24 object-cover bg-gray-50 text-xs cursor-pointer"
              />
            ) : (
              <div
                className="h-24 w-24  border-2 border-dashed text-gray-300 font-semibold font-mono text-xl cursor-pointer flex flex-col items-center justify-center"
                onClick={() => handleImgClick(0)}>
                <IoAdd className="text-3xl" />
                <span>Icon</span>
              </div>
            )}
            <span className="flex w-full gap-1">
              <button
                className="bg-gray-100 w-1/2 p-1"
                onClick={() => handleImgClick(0)}>
                Change
              </button>
              <button
                className="bg-[#eebf00] text-white w-1/2 p-1 "
                onClick={() => handleUpload(0)}>
                {isLoading ? (
                  <span className="text-sm">Uploading...</span>
                ) : (
                  "Upload"
                )}
              </button>
            </span>
            <input
              type="file"
              className="hidden  p-4"
              placeholder="showCategory"
              ref={inputRef0}
              onChange={(e) => handleImageChange(e, 0)}
            />
            <span className="absolute -top-2 bg-white px-1 text-gray-400 text-xs left-2 capitalize">
              Icon
            </span>
          </div>
          {edit && (
            <div
              className="text-center px-2 bg-gray-200 rounded-md py-1 w-max mx-auto cursor-pointer"
              onClick={handleCopyIcon}>
              Make Destop icons as mobile
            </div>
          )}
          {/* Thumbnail*/}
          <div className="w-[200px] mx-auto my-10 relative flex flex-col items-center justify-center gap-2 shadow p-2 pt-3 border-t ">
            {image1 || subCatagory?.thumbnailUrls ? (
              <img
                src={image1?.filepreview || subCatagory?.thumbnailUrls}
                alt="Catagory Icon"
                className="h-24 w-24 object-cover bg-gray-50 text-xs cursor-pointer"
              />
            ) : (
              <div
                className="h-24 w-24  border-2 border-dashed text-gray-300 font-semibold font-mono text-xl cursor-pointer flex flex-col items-center justify-center"
                onClick={() => handleImgClick(1)}>
                <IoAdd className="text-3xl" />
                <span>Icon</span>
              </div>
            )}
            <span className="flex w-full gap-1">
              <button
                className="bg-gray-100 w-1/2 p-1"
                onClick={() => handleImgClick(1)}>
                Change
              </button>
              <button
                className="bg-[#eebf00] text-white w-1/2 p-1 "
                onClick={() => handleUpload(1)}>
                {isLoading ? (
                  <span className="text-sm">Uploading...</span>
                ) : (
                  "Upload"
                )}
              </button>
            </span>
            <input
              type="file"
              className="hidden  p-4"
              placeholder="showCategory"
              ref={inputRef1}
              onChange={(e) => handleImageChange(e, 1)}
            />
            <span className="absolute -top-2 bg-white px-1 text-gray-400 text-xs left-2 capitalize">
              Thumbnail
            </span>
          </div>
          {edit && (
            <div
              className="text-center px-2 bg-gray-200 rounded-md py-1 w-max mx-auto cursor-pointer"
              onClick={handleCopyBanner}>
              Make Destop Banner as mobile
            </div>
          )}
          {/* App images */}
          <div className="w-[200px] mx-auto my-10 relative flex flex-col items-center justify-center gap-2 shadow p-2 pt-3 border-t">
            {image2 || subCatagory?.imageIUrlA ? (
              <img
                src={image2?.filepreview || subCatagory?.imageIUrlA}
                alt="Catagory Icon"
                className="h-24 w-24 object-cover bg-gray-50 text-xs cursor-pointer"
              />
            ) : (
              <div
                className="h-24 w-24  border-2 border-dashed text-gray-300 font-semibold font-mono text-xl cursor-pointer flex flex-col items-center justify-center"
                onClick={() => handleImgClick(2)}>
                <IoAdd className="text-3xl" />
                <span>Icon</span>
              </div>
            )}
            <span className="flex w-full gap-1">
              <button
                className="bg-gray-100 w-1/2 p-1"
                onClick={() => handleImgClick(2)}>
                Change
              </button>
              <button
                className="bg-[#eebf00] text-white w-1/2 p-1 "
                onClick={() => handleUpload(2)}>
                {isLoading ? (
                  <span className="text-sm">Uploading...</span>
                ) : (
                  "Upload"
                )}
              </button>
            </span>
            <input
              type="file"
              className="hidden  p-4"
              placeholder="showCategory"
              ref={inputRef2}
              onChange={(e) => handleImageChange(e, 2)}
            />
            <span className="absolute -top-2 bg-white px-1 text-gray-400 text-xs left-2 capitalize">
              App Icon
            </span>
          </div>
          {/* Thumbnail*/}
          <div className="w-[200px] mx-auto my-10 relative flex flex-col items-center justify-center gap-2 shadow p-2 pt-3 border-t ">
            {image3 || subCatagory?.smallBannerM ? (
              <img
                src={image3?.filepreview || subCatagory?.smallBannerM}
                alt="Catagory Icon"
                className="h-24 w-24 object-cover bg-gray-50 text-xs cursor-pointer"
              />
            ) : (
              <div
                className="h-24 w-24  border-2 border-dashed text-gray-300 font-semibold font-mono text-xl cursor-pointer flex flex-col items-center justify-center"
                onClick={() => handleImgClick(3)}>
                <IoAdd className="text-3xl" />
                <span>Icon</span>
              </div>
            )}
            <span className="flex w-full gap-1">
              <button
                className="bg-gray-100 w-1/2 p-1"
                onClick={() => handleImgClick(3)}>
                Change
              </button>
              <button
                className="bg-[#eebf00] text-white w-1/2 p-1 "
                onClick={() => handleUpload(3)}>
                {isLoading ? (
                  <span className="text-sm">Uploading...</span>
                ) : (
                  "Upload"
                )}
              </button>
            </span>
            <input
              type="file"
              className="hidden  p-4"
              placeholder="showCategory"
              ref={inputRef3}
              onChange={(e) => handleImageChange(e, 3)}
            />
            <span className="absolute -top-2 bg-white px-1 text-gray-400 text-xs left-2 capitalize">
              App Thumbnail
            </span>
          </div>
          {/* description */}
          <div className="w-full h-max my-6 border relative">
            <textarea
              type="text"
              className="w-full  outline-none   text-sm   rounded-md   p-2 h-20"
              placeholder="Description"
              name="description"
              value={subCatagory.description}
              onChange={handleChange}
            />
            <span className="absolute -top-2 bg-white px-1 text-gray-400 text-xs left-4 capitalize">
              Description
            </span>
          </div>
          {/* Name of catagory */}
          <div className="w-full h-14 my-6 border relative">
            <input
              type="text"
              className="w-full h-full outline-none p-4"
              placeholder="Title"
              name="title"
              value={subCatagory.title}
              onChange={handleChange}
            />
            <span className="absolute -top-2 bg-white px-1 text-gray-400 text-xs left-4 capitalize">
              Title
            </span>
          </div>
        </div>
        <div className="absolute bottom-0 w-full h-12 ">
          <button
            className="w-1/2 h-full uppercase bg-gray-200"
            onClick={clearState}>
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            className="w-1/2 h-full uppercase text-white bg-primary-max">
            {isLoadingSubCatg ? "Saving..." : "Save"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddSubCategory;
