import React, { useEffect, useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { fetchPartner } from "../../features/Partner/PartnerSlice";
import ManagePartner from "../../components/ManagePartner";
import PaginationBar from "../../components/PaginationBar";

const Partners = () => {
  const dispatch = useDispatch();

  const { partnerList, totalPages } = useSelector((store) => store.partner);

  const [isAdding, setAdding] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const handleAddingPartner = (flag) => {
    setAdding(flag);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
    dispatch(fetchPartner({ pageNo: page - 1, pageSize: 20 }));
  };

  useEffect(() => {
    dispatch(fetchPartner({ pageNo: 0, pageSize: 20 }));
    window.scroll(0, 0);
  }, []);
  return (
    <div className="relative w-full h-full bg-white mb-10 min-h-screen">
      {/* popus */}
      {isAdding && <ManagePartner handleAdding={handleAddingPartner} />}
      {/* Search and add */}
      <div className="px-4 relative flex justify-between items-center pb-4 pt-8 m-auto ">
        <input
          type="text"
          className="p-2 my-4 rounded-md outline-none border"
          placeholder="Search "
        />
        <button
          className="bg-primary-600 text-white px-4 py-2 rounded-md"
          onClick={() => handleAddingPartner(true)}>
          Add New
        </button>
      </div>
      {/* data */}
      <div className=" w-full px-4 m-auto min-h-max max-[1300px]:text-xs max-[1000px]:text-[8px] max-[1450px]:text-sm">
        {/* Head of the table */}
        <div className="h-10 border-x border-t  shadow-md bg-primary-70 text-white  flex items-center  mb-3 overflow-hidden rounded-md">
          <span className="w-[12%] pl-5 h-full text-left capitalize py-2 overflow-hidden">
            Partner ID
          </span>
          <span className="w-[16%] h-full text-left capitalize py-2">Name</span>

          <span className="w-2/12 h-full text-left capitalize py-2">Email</span>

          <span className="w-1/12 h-full text-left capitalize py-2">
            Mobile
          </span>
          <span className="flex-1 h-full text-center capitalize py-2">
            Skills
          </span>

          <span className="w-[13%] h-full text-center capitalize py-2">
            Category Type
          </span>
          <span className="w-[3.5%] h-full text-center py-2 "></span>
        </div>

        {partnerList?.map((elem, index) => {
          return (
            <Link
              to={`/partner/partner-detail/${elem?.id}`}
              className={
                "h-12 w-full hover:border-gray-400 flex items-center border-x  rounded-md relative  tracking-wider text-sm  " +
                (index % 2 !== 0 && " bg-gray-200")
              }
              key={index}>
              <div className="w-[12%] pl-5   text-left capitalize  py-2 overflow-hidden font-semibold">
                {elem?.id}
              </div>
              <span className="w-[16%]   text-left capitalize py-2 ">
                {elem?.partnerFirstName + " " + elem?.partnerLastName || "--"}
              </span>
              <span className="w-2/12   text-left capitalize py-2 overflow-hidden ">
                {elem?.partnerEmail || "--"}
              </span>
              <span className="w-1/12  text-left  py-2 max-[1300px]:text-[6px] max-[1000px]:text-[8px] max-[1450px]:text-xs  text-sm  overflow-hidden flex gap-1 pr-2 items-center ">
                <div className="overflow-ellipsis whitespace-nowrap overflow-hidden inline ">
                  {elem?.partnerMobile || "--"}
                </div>
              </span>

              <span className="flex-1 text-center  capitalize py-2 font-semibold  ">
                {elem?.skillSet?.join(", ") || "--"}
              </span>
              <span className="w-[13%] text-center  capitalize py-2  text-xs font-semibold">
                {elem?.categoryType || "--"}
              </span>
              <span className="w-[3.5%]  flex justify-center py-2  cursor-pointer relative z-10">
                {/* <BsThreeDotsVertical className="text-lg" /> */}
              </span>
            </Link>
          );
        })}
        <PaginationBar
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default Partners;
