import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchProfile } from "../../features/Profile/ProfileSlice";
import { Link, useNavigate } from "react-router-dom";
import ProfileSidebar from "../../components/ProfileSidebar";

const Profile = () => {
  const dispatch = useDispatch();

  const { isLoading, userData } = useSelector((state) => state.profile);
  useEffect(() => {
    dispatch(fetchProfile());
  }, [dispatch]);
  const navigate = useNavigate();

  return (
    <div className=" bg-slate-50 overflow-hidden">
      <div className=" flex justify-center w-full h-fit">
        <div className="  w-[900px] h-fit my-20  ">
          {/* Head */}
          <div className="flex flex-col w-full border-b mx-4 pb-5 border-gray-400">
            <span className="text-xl font-bold my-1">Account</span>
            <span className="text-sm  font-thin">
              {userData?.firstName + " " + userData?.lastName}
            </span>
          </div>

          {/* Profile box */}
          <div className="flex h-fit w-full ">
            {/* Side options */}
            <ProfileSidebar userData={userData} current={"profile"} />

            {/* main details */}
            <div className="flex justify-center items-center w-full h-full">
              <div className="flex justify-center max-w-max  w-11/12 h-full mt-8 border border-gray-400">
                <div className="flex flex-col items-center py-6 px-24 w-full  text-gray-600">
                  <div className="flex justify-center border-b-2 w-full mb-10">
                    <h1 className="font-bold text-md my-6 ">Profile Details</h1>
                  </div>

                  {/* Main Details */}
                  <div className="mx-8 gap-8 min-w-max text-md grid grid-cols-2 ">
                    <div>Full Name</div>
                    <div className="ml-auto">
                      {userData?.firstName + " " + userData?.lastName}
                    </div>

                    <div>Mobile number</div>
                    <div className="ml-auto">{userData?.mobile}</div>

                    <div>Email</div>
                    <div className="ml-auto">{userData?.email}</div>

                    <div>Age</div>
                    <div className="ml-auto">
                      {userData?.age ? userData.age : "- not added -"}
                    </div>

                    <div>Gender</div>
                    <div className="ml-auto">
                      {userData?.gender ? userData.gender : "-not added-"}
                    </div>
                    <div>Alternate Mobile</div>
                    <div className="ml-auto">
                      {userData?.alterPhone
                        ? userData.alterPhone
                        : "- not added -"}
                    </div>

                    <div>Location</div>
                    <div className="ml-auto">
                      {userData.address?.city
                        ? userData.address?.city + "," + userData.address?.state
                        : "- not added -"}
                    </div>
                    {/* 
                    <div>Mobile number</div>
                    <div className="ml-auto">+9199999999999</div> */}
                  </div>
                  <div className="flex justify-center w-full mt-10">
                    <Link
                      to="./edit"
                      className="flex justify-center w-full  p-1 my-6 bg-[#000] rounded-sm">
                      <span className="font-normal text-lg uppercase text-white">
                        edit
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
