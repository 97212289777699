import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { AiFillCamera } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import {
  clearFile,
  updateImage,
  uploadFile,
} from "../features/Profile/ProfileSlice";

const ProfileSidebar = ({ userData, current }) => {
  const dispatch = useDispatch();
  const [selectedFile, setSelectedFile] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const { status, uploadedFile } = useSelector((state) => state.profile);
  const { userId } = useSelector((state) => state.user.user);

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    setSelectedFile(null);
  };

  const fileSelectedHandler = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const fileUploadHandler = () => {
    const fd = new FormData();
    fd.append("file", selectedFile, selectedFile.name);

    console.log(dispatch(uploadFile(fd)));
  };
  useEffect(() => {
    console.log("Effect");
    if (uploadedFile) {
      dispatch(
        updateImage({
          id: userId,
          profile_image_url:
            "https://clapurban.blr1.digitaloceanspaces.com/1695069331541_niyam.jpg",
        })
      );
      clearFile();
    }
  }, []);
  return (
    <div className="h-fit bg-[#000] text-gray-100 px-4 py-6 border-r w-2/6 border-gray-400 max-md:hidden">
      <div className="w-full h-fit my-2 flex flex-col items-center justify-center ">
        <div className="relative w-max h-full">
          {/* Hidden file input with label */}
          <label htmlFor="fileInput" className="hidden">
            <input type="file" id="fileInput" onChange={fileSelectedHandler} />
          </label>

          {/* User profile image */}
          <div className="relative">
            <img
              src={userData?.profile_image_url}
              alt={userData?.firstName}
              className="object-cover h-32 w-32 border border-[#eebf00] bg-white rounded-full"
            />
            <label
              htmlFor="fileInput"
              className="absolute bg-[#eebe00f9] w-10 h-10 p-2 rounded-full bottom-0 right-0 border-double text-white cursor-pointer"
              onClick={openPopup}>
              <AiFillCamera className=" absolute bg-[#eebe00f9] w-10 h-10 p-2 rounded-full bottom-0 right-0 border-double text-white " />
            </label>
          </div>

          {/* Popup */}
          {/* Popup */}
          {selectedFile && isPopupOpen && (
            <div className="fixed top-0 left-0 flex items-center justify-center w-screen h-screen bg-black bg-opacity-70">
              <div className="bg-white p-4 rounded-lg shadow-md">
                {/* Display selected image */}
                <img
                  src={URL.createObjectURL(selectedFile)}
                  alt="Selected"
                  className="object-cover w-52 mb-4"
                />

                {/* Upload and Cancel buttons */}
                <div className="flex justify-center">
                  <button
                    onClick={closePopup}
                    className="text-black rounded p-2 mr-2" // Add margin to separate the buttons
                  >
                    Cancel
                  </button>
                  <button
                    onClick={fileUploadHandler}
                    className="bg-[#eebe00f9] text-white rounded p-2">
                    Upload
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
        <Link
          to="/profile"
          className="text-gray-300 font-bold text-lg font-mono my-3">
          {userData?.firstName}
        </Link>
      </div>
      <div className="border-[#eebf00] py-3 border-t-2 border-b">
        <h4 className="text-gray-300 font-semibold text-md font-mono my-3">
          Services
        </h4>
        <p>Rebook or Cancel</p>
      </div>
      <div className="border-[#eebf00] py-3 border-y ">
        <h4 className="text-gray-300 font-semibold text-md font-mono my-3">
          Credits
        </h4>
        <p>Credit1</p>
        <p>Credit4</p>
        <p>Credit2</p>
        <p>Credit3</p>
      </div>
      <div className="flex flex-col border-[#eebf00] py-3 border-y ">
        <h4 className="text-gray-300 font-semibold text-md font-mono my-3">
          Account
        </h4>
        <Link
          to="/profile"
          className={
            current === "profile"
              ? "text-[#eebf00] font-semibold scale-105  hover:underline capitalize"
              : "transition ease-in-out  hover:scale-105  duration-100 capitalize"
          }>
          Profile
        </Link>
        <Link
          to="/address"
          className={
            current === "address"
              ? "text-[#eebf00] font-semibold scale-105 hover:underline capitalize"
              : "transition ease-in-out  hover:scale-105  duration-100 capitalize"
          }>
          saved address
        </Link>
        <Link
          to="/change-password"
          className={
            current === "privacy"
              ? "text-[#eebf00] font-semibold scale-105 hover:underline capitalize"
              : "transition ease-in-out  hover:scale-105  duration-100 capitalize"
          }>
          privacy
        </Link>
      </div>
      <div className="border-[#eebf00] py-3 border-t border-b-2 ">
        <h4 className="text-gray-300 font-semibold text-md font-mono my-3">
          Legal
        </h4>
        <p>Term of uses</p>
      </div>
    </div>
  );
};

export default ProfileSidebar;
