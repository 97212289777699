import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getPartnerFromLocalStorage, getUserFromLocalStorage } from "../../utils/localStorage";
import customFetch, { partnerCustomFetch } from "../../utils/axios";
import { toast } from "sonner";

const initialState = {
  isLoadingBooking: false,

  status: null,
  totalPages: null,
  bookings: [],
};

export const getBooking = createAsyncThunk(
  "booking/getcatagories",
  async (thunkAPI) => {
    const { userId, accessToken } = getUserFromLocalStorage();

    try {
      const resp = await customFetch.get(`api/bookings/${userId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return resp?.data;
    } catch (error) {
      if (error) {
        alert("Fialed to load booking!");
      }
      return thunkAPI.rejectWithValue(error.response.msg);
    }
  }
);

export const getAllBooking = createAsyncThunk(
  "booking/getAllBooking",
  async (queryParams, thunkAPI) => {
    const { accessToken } = getUserFromLocalStorage();

    try {
      const resp = await customFetch.get(`api/bookings`, {
        params: queryParams,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      console.log(resp);
      return resp?.data;
    } catch (error) {
      if (error) {
        alert("Fialed to load booking!");
      }
      return thunkAPI.rejectWithValue(error.response.msg);
    }
  }
);

export const postBooking = createAsyncThunk(
  "booking/postBooking",
  async ({ userId, data }, thunkAPI) => {
    console.log(data);
    const { accessToken } = getUserFromLocalStorage();

    try {
      const resp = await customFetch.post(`/api/bookings/${userId}`, data, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return resp?.data;
    } catch (error) {
      if (error.response) {
        alert("Error Occured!");
      }
      return thunkAPI.rejectWithValue(error.response.msg);
    }
  }
);

//Assign Partner
export const postAssignPartner = createAsyncThunk(
  "partner/postAssignPartner",
  async (data, thunkAPI) => {
    const { accessToken } = getPartnerFromLocalStorage();
    try {
      const resp = await partnerCustomFetch.post(`/api/Assign/partner`, data, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      thunkAPI.dispatch(getAllBooking());
      return resp?.data;
    } catch (error) {
      if (error.response) {
        alert("Error Occured!");
      }
      return thunkAPI.rejectWithValue(error.response.msg);
    }
  }
);


//Delete Partner
export const postRemovingPartner = createAsyncThunk(
  "partner/postRemovingPartner",
  async ({bookingId , partnerId}, thunkAPI) => {
    const { accessToken } = getPartnerFromLocalStorage();
    try {
      const resp = await partnerCustomFetch.delete(`/api/Assign/re-assign/single/partner?bookingId=${bookingId}&partnerId=${partnerId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      console.log(resp?.data);
      thunkAPI.dispatch(getAllBooking());
      return resp?.data;
    } catch (error) {
      if (error.response) {
        alert("Error Occured!");
      }
      return thunkAPI.rejectWithValue(error.response.msg);
    }
  }
);


//Assign Company
export const postAssignCompany = createAsyncThunk(
  "partner/postAssignCompany",
  async (data, thunkAPI) => {
    const { accessToken } = getPartnerFromLocalStorage();
    try {
      const resp = await partnerCustomFetch.post(`/api/Assign/company`, data, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      console.log(resp?.data);
      thunkAPI.dispatch(getAllBooking());
      return resp?.data;
    } catch (error) {
      if (error.response) {
        alert("Error Occured!");
      }
      return thunkAPI.rejectWithValue(error.response.msg);
    }
  }
);


//Delete Company
export const postRemovingCompany = createAsyncThunk(
  "partner/postRemovingCompany",
  async ({bookingId , companyId}, thunkAPI) => {
    const { accessToken } = getPartnerFromLocalStorage();
    try {
      const resp = await partnerCustomFetch.delete(`/api/Assign/company/cancel?bookingId=${bookingId}&companyId=${companyId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      console.log(resp?.data);
      thunkAPI.dispatch(getAllBooking());
      return resp?.data;
    } catch (error) {
      if (error.response) {
        alert("Error Occured!");
      }
      return thunkAPI.rejectWithValue(error.response.msg);
    }
  }
);


//ONGOING AND COMPLETED STATUS 

export const onGoingOTP = createAsyncThunk(
  "partner/onGoingOTP",
  async ({ bookingId, otp }, thunkAPI) => {
    const { accessToken } = getPartnerFromLocalStorage();

    try {
      const resp = await partnerCustomFetch.get(
        `api/bookings/${bookingId}/ongoing/${otp}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      toast.success("OTP verified successfully");
      thunkAPI.dispatch(getAllBooking());

      return resp?.data;
    } catch (error) {
      toast.error("Invalid OTP");
      return thunkAPI?.rejectWithValue(error?.message);
    }
  }
);

export const isCompleted = createAsyncThunk(
  "partner/isCompleted",
  async ({ bookingId, partnerId }, thunkAPI) => {
    console.log("bookingId", bookingId);
    console.log("partnerId", partnerId);
    const { accessToken } = getPartnerFromLocalStorage();

    try {
      const resp = await partnerCustomFetch.get(
        `api/bookings/${bookingId}/completed/${partnerId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      toast.success("Booking completed successfully");
      thunkAPI.dispatch(getAllBooking());

      return resp?.data;
    } catch (error) {
      toast.error("Failed to complete booking");
      return thunkAPI?.rejectWithValue(error?.message);
    }
  }
);

//Get Booking Detail
export const getBookingDetail = createAsyncThunk(
  "partner/getbookingdetail",
  async (bookingId, thunkAPI) => {
    const { userId, accessToken } = getPartnerFromLocalStorage();
    try {
      const resp = await partnerCustomFetch.get(`api/bookings/${bookingId}`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      // console.log(resp);
      return resp?.data;
    } catch (error) {
      return thunkAPI?.rejectWithValue(error?.message);
    }
  }
);




// bookingCancel

export const bookingCancel = createAsyncThunk(
  "booking/getbookingdetail",
  async ({bookingId, userId, couponId, data}, thunkAPI) => {
    const { accessToken } = getUserFromLocalStorage();
    console.log(bookingId, userId, couponId, data);
    try {
      const resp = await customFetch.post(`/api/bookings/cancel/${bookingId}/${userId}/${couponId}`,data, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      console.log(resp);
      thunkAPI.dispatch(getAllBooking());
      return resp?.data;
    } catch (error) {
      return thunkAPI?.rejectWithValue(error?.message);
    }
  }
);




















//Change status

export const postStatus = createAsyncThunk(
  "partner/postStatus",
  async ({ bookingId, data }, thunkAPI) => {
    const { accessToken } = getUserFromLocalStorage();
    try {
      const resp = await customFetch.post(
        `/api/bookings/cancel/${bookingId}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      thunkAPI.dispatch(getAllBooking());
      alert("Status succesfully changes!");
      return resp?.data;
    } catch (error) {
      if (error.response) {
        alert("Error Occured!");
      }
      return thunkAPI.rejectWithValue(error?.response?.msg);
    }
  }
);
// export const updateCategory = createAsyncThunk(
//   "booking/updatecategory",
//   async (data, thunkAPI) => {
//     console.log(data);
//     const { accessToken } = getUserFromLocalStorage();
//     try {
//       const resp = await customFetch.put(`api/category/${data.id}`, data, {
//         headers: {
//           authorization: `Bearer ${accessToken}`,
//         },
//       });
//       return resp.data;
//     } catch (error) {
//       if (error.response) {
//         alert("Error Occured!");
//       }
//       return thunkAPI.rejectWithValue(error.response.msg);
//     }
//   }
// );

const BookingSlice = createSlice({
  name: "booking",
  initialState,
  reducers: {
    setLoadingBooking: (state, { payload }) => {
      state.isLoadingBooking = payload;
    },
    clearStatus: (state) => {
      state.status = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postBooking.fulfilled, (state, { payload }) => {
        state.bookings.push(payload);
        state.status = 201;
        alert("booking Successfully Added!");
        state.isLoadingBooking = false;
      })
      .addCase(postBooking.pending, (state) => {
        state.isLoadingBooking = true;
      })
      .addCase(postBooking.rejected, (state) => {
        state.isLoadingBooking = false;
      })
      .addCase(getAllBooking.fulfilled, (state, { payload }) => {
        state.bookings = payload.content;
        state.totalPages = payload.totalPages;

        state.isLoadingBooking = false;
      })
      .addCase(getAllBooking.pending, (state) => {
        state.isLoadingBooking = true;
      })
      .addCase(getAllBooking.rejected, (state) => {
        state.isLoadingBooking = false;
      })
      //ASSIGN PARTNER
      .addCase(postAssignPartner.fulfilled, (state, { payload }) => {
        alert("Partner added succesfully!");
        state.isLoadingBooking = false;
      })
      .addCase(postAssignPartner.pending, (state) => {
        state.isLoadingBooking = true;
      })
      .addCase(postAssignPartner.rejected, (state) => {
        state.isLoadingBooking = false;
      })
      //ASSIGN COMPANY
      .addCase(postAssignCompany.fulfilled, (state, { payload }) => {
        alert("Company added succesfully!");
        state.isLoadingBooking = false;
      })
      .addCase(postAssignCompany.pending, (state) => {
        state.isLoadingBooking = true;
      })
      .addCase(postAssignCompany.rejected, (state) => {
        state.isLoadingBooking = false;
      })
      //ASSIGN STATUS
      .addCase(postStatus.fulfilled, (state) => {
        state.isLoadingBooking = false;
      })
      .addCase(postStatus.pending, (state) => {
        state.isLoadingBooking = true;
      })
      .addCase(postStatus.rejected, (state) => {
        state.isLoadingBooking = false;
      })
      //ongoing top
      .addCase(onGoingOTP.fulfilled, (state) => {
        state.isLoadingBooking = false;
      })
      .addCase(onGoingOTP.pending, (state) => {
        state.isLoadingBooking = true;
      })
      .addCase(onGoingOTP.rejected, (state) => {
        state.isLoadingBooking = false;
      })
      //on Completed
      .addCase(isCompleted.fulfilled, (state) => {
        state.isLoadingBooking = false;
      })
      .addCase(isCompleted.pending, (state) => {
        state.isLoadingBooking = true;
      })
      .addCase(isCompleted.rejected, (state) => {
        state.isLoadingBooking = false;
      });
  },
});
export default BookingSlice.reducer;
export const { setLoadingBooking, clearStatus } = BookingSlice.actions;
