import React, { useRef, useState } from "react";
import { RxCross1 } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import {
  clearFile,
  setLoading,
  uploadFile,
} from "../features/Files/FilesSlice";
import { IoAdd } from "react-icons/io5";
import { useEffect } from "react";
import {
  getCatagories,
  postCatagory,
  setLoadingCatg,
  updateCategory,
} from "../features/Catagory/CatagorySlice";

const AddCatagory = ({ handleAdding, edit, handleEditing }) => {
  const initialState = {
    categoryType: "",
    categoryName: "",
    categoryUrl: "",
    appCategoryUrl: "",
    showCategory: false,
  };
  console.log(edit);

  const { isLoading, uploadUrl } = useSelector((state) => state.file);
  const { isLoadingCatg } = useSelector((state) => state.catagory);
  const dispatch = useDispatch();
  const [image, setImage] = useState(null);
  const [imageMobile, setImageMobile] = useState(null);
  const [uploadedImage, setUploadedImage] = useState("");
  const [catagory, setCatagory] = useState(edit || initialState);
  const inputRef = useRef(null);
  const inputRef2 = useRef(null);
  const handleImgClick = () => {
    inputRef.current.click();
  };
  const handleAppImgClick = () => {
    inputRef2.current.click();
  };

  const handleImageChange = async (e) => {
    const getImage = e.target.files[0];
    if (!getImage) {
      return;
    }
    setImage({
      ...image,
      file: getImage,
      filepreview: URL.createObjectURL(getImage),
    });
  };

  const handleAppImageChange = async (e) => {
    const getImage = e.target.files[0];
    if (!getImage) {
      return;
    }
    setImageMobile({
      ...imageMobile,
      file: getImage,
      filepreview: URL.createObjectURL(getImage),
    });
  };
  const capitalizeFirstLetter = (inputString) => {
    if (inputString.length === 0) {
      return inputString;
    }
    console.log(inputString);
    return inputString.charAt(0).toUpperCase() + inputString.slice(1);
  };
  const handleChange = (e) => {
    const name = e.target.name;
    const value = capitalizeFirstLetter(e.target.value);
    setCatagory({ ...catagory, [name]: value });
  };

  // Copy image
  const handleCopyImg = () => {
    const value = catagory?.categoryUrl;
    setCatagory({ ...catagory, appCategoryUrl: value });
  };
  const handleUpload = (loc) => {
    setUploadedImage(loc);
    const fileData = new FormData();
    if (image?.file && loc === "web") {
      fileData.append("file", image.file, image?.file?.name);
      dispatch(uploadFile(fileData));
    } else if (imageMobile?.file && loc === "app") {
      fileData.append("file", imageMobile.file, imageMobile?.file?.name);
      dispatch(uploadFile(fileData));
    }
  };
  const clearState = () => {
    console.log("called");
    dispatch(clearFile());
    handleAdding();
    handleEditing();
  };

  const handleSubmit = (e) => {
    console.log(catagory);
    e.preventDefault();
    const {
      id,
      categoryType,
      categoryName,
      categoryUrl,
      showCategory,
      appCategoryUrl,
    } = catagory;
    if (
      categoryType === "" ||
      categoryName === "" ||
      categoryUrl === "" ||
      appCategoryUrl === "" ||
      showCategory == null
    ) {
      alert("Please fill all the details!");
      return;
    }
    if (edit) {
      if (edit === catagory) {
        alert("No changes found!");
        return;
      }
      dispatch(
        updateCategory({
          id: id,
          categoryType: categoryType,
          categoryName: categoryName,
          categoryUrl: categoryUrl,
          showCategory: showCategory,
          appCategoryUrl: appCategoryUrl,
        })
      );
    } else
      dispatch(
        postCatagory({
          categoryType: categoryType,
          categoryName: categoryName,
          categoryUrl: categoryUrl,
          showCategory: showCategory,
          appCategoryUrl: appCategoryUrl,
        })
      );

    setTimeout(() => {
      dispatch(getCatagories());
      clearState();
    }, 2000);
  };

  useEffect(() => {
    if (uploadedImage === "web") {
      setUploadedImage("");
      setCatagory({ ...catagory, categoryUrl: uploadUrl });
    } else if (uploadedImage === "app") {
      setUploadedImage("");
      setCatagory({ ...catagory, appCategoryUrl: uploadUrl });
    }
  }, [uploadUrl]);
  useEffect(() => {
    dispatch(setLoading(false));
    dispatch(setLoadingCatg(false));
  }, []);
  console.log(catagory);
  return (
    <div className="fixed w-full h-screen bg-[#00000094] z-40 flex items-center justify-center top-0 left-0">
      <form
        className="relative w-[450px] h-[65vh] bg-white flex items-center justify-center top-10 "
        onSubmit={handleSubmit}>
        <RxCross1
          className="absolute right-0 -top-10 p-1 bg-white rounded-full cursor-pointer text-3xl"
          onClick={clearState}
        />

        <div className="uppercase font-mono absolute top-0 py-3 text-center text-xl shadow-inner w-full ">
          {(edit ? "Edit" : "Add") + " catagory"}
        </div>
        <div className="w-11/12 h-5/6 overflow-auto no-scrollbar">
          {/* categoryType */}
          <div className=" flex gap-2 my-6 h-12 ">
            <div className="w-full h-full  border relative">
              <select
                name="categoryType"
                id="categoryType"
                className=" outline-none w-full h-full p-3 text-gray-600 appearance-none"
                value={catagory?.categoryType}
                onChange={handleChange}
                required>
                <option disabled selected value={""}>
                  --Select an option--
                </option>
                <option value="Electronics">Electronics</option>
                <option value="Mechanical">Mechanical</option>
                <option value="Elctrical">Electrical</option>
                <option value="other">other</option>
              </select>
              <span className="absolute -top-2 bg-white px-1 text-gray-400 text-xs left-4 capitalize">
                Catagory type
              </span>
            </div>
            {/* showCategory */}
            <div className="w-full h-full  border relative">
              <select
                name="showCategory"
                id="showCategory"
                className="capitalize outline-none w-full h-full p-3 text-gray-600 appearance-none"
                onChange={handleChange}
                value={catagory?.showCategory}
                required>
                <option disabled selected>
                  --Select an option--
                </option>
                <option value={true}>Active</option>
                <option value={false}>inactive</option>
              </select>
              <span className="absolute -top-2 bg-white px-1 text-gray-400 text-xs left-4 capitalize">
                Status
              </span>
            </div>
          </div>
          {/* categoryName */}
          <div className="w-full h-14 my-6 border relative">
            <input
              type="text"
              className="w-full h-full outline-none p-4"
              placeholder="categoryName"
              name="categoryName"
              value={catagory?.categoryName}
              onChange={handleChange}
              required
            />
            <span className="absolute -top-2 bg-white px-1 text-gray-400 text-xs left-4 capitalize">
              Catagory Name
            </span>
          </div>

          {/* categoryUrl */}
          <h1 className="w-full text-center">Web Icon</h1>
          <div className="w-[200px] mx-auto my-8 relative flex flex-col items-center justify-center gap-2 shadow p-2 pt-3">
            {image || edit?.categoryUrl ? (
              <img
                src={
                  image?.filepreview ? image?.filepreview : edit?.categoryUrl
                }
                alt="Catagory Icon"
                className="h-24 w-24 object-cover bg-gray-50 text-xs cursor-pointer"
              />
            ) : (
              <div
                className="h-24 w-24  border-2 border-dashed text-gray-300 font-semibold font-mono text-xl cursor-pointer flex flex-col items-center justify-center"
                onClick={handleImgClick}>
                <IoAdd className="text-3xl" />
                <span>Icon</span>
              </div>
            )}
            <span className="flex w-full gap-1">
              <button
                type="button"
                className="bg-gray-100 w-1/2 p-1"
                onClick={handleImgClick}>
                Change
              </button>
              <button
                type="button"
                className="bg-[#eebf00] text-white w-1/2 p-1 "
                onClick={() => handleUpload("web")}>
                {isLoading ? (
                  <span className="text-sm">Uploading...</span>
                ) : (
                  "Upload"
                )}
              </button>
            </span>
            <input
              type="file"
              className="hidden  p-4"
              placeholder="showCategory"
              ref={inputRef}
              onChange={handleImageChange}
            />
          </div>
          {edit && (
            <div
              className="capitalize px-2 py-1 bg-gray-200 border rounded-md mx-auto w-max cursor-pointer"
              onClick={handleCopyImg}>
              Make mobile as desktop
            </div>
          )}
          {/* appCategoryUrl */}
          <h1 className="w-full text-center">App Icon</h1>
          <div className="w-[200px] mx-auto my-8 relative flex flex-col items-center justify-center gap-2 shadow p-2 pt-3">
            {imageMobile || edit?.appCategoryUrl ? (
              <img
                src={
                  imageMobile?.filepreview
                    ? imageMobile?.filepreview
                    : edit?.appCategoryUrl
                }
                alt="Catagory Icon"
                className="h-24 w-24 object-cover bg-gray-50 text-xs cursor-pointer"
              />
            ) : (
              <div
                className="h-24 w-24  border-2 border-dashed text-gray-300 font-semibold font-mono text-xl cursor-pointer flex flex-col items-center justify-center"
                onClick={handleAppImgClick}>
                <IoAdd className="text-3xl" />
                <span>Icon</span>
              </div>
            )}
            <span className="flex w-full gap-1">
              <button
                type="button"
                className="bg-gray-100 w-1/2 p-1"
                onClick={handleAppImgClick}>
                Change
              </button>
              <button
                type="button"
                className="bg-[#eebf00] text-white w-1/2 p-1 "
                onClick={() => handleUpload("app")}>
                {isLoading ? (
                  <span className="text-sm">Uploading...</span>
                ) : (
                  "Upload"
                )}
              </button>
            </span>
            <input
              type="file"
              className="hidden  p-4"
              placeholder="showCategory"
              ref={inputRef2}
              onChange={handleAppImageChange}
            />
          </div>
        </div>
        <div className="absolute bottom-0 w-full h-12 ">
          <button
            type="button"
            className="w-1/2 h-full uppercase bg-gray-200"
            onClick={clearState}>
            Cancel
          </button>
          <button
            type="submit"
            className="w-1/2 h-full uppercase text-white bg-primary-max">
            {isLoadingCatg ? "Saving..." : "Save"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddCatagory;
