import React from "react";

const Confirm = ({ question, handleConfirmation, sence }) => {
  return (
    <div className="fixed w-screen h-screen flex items-center justify-center z-50 bg-[#0000002f] top-0 ">
      <div className="bg-white rounded-xl flex flex-col items-center justify-between py-8 px-12 w-[20vw] min-w-max  min-h-max gap-10 tracking-wide popup">
        <div className="text-lg font-light">{question}</div>
        <div className="flex justify-between w-4/6">
          <button
            className={`px-4 py-2  rounded-lg ${
              sence ? " bg-gray-200 " : " bg-[#000000] text-white"
            }`}
            onClick={() =>
              handleConfirmation ? handleConfirmation(true) : ""
            }>
            Yes
          </button>
          <button
            className={`px-4 py-2 rounded-lg ${
              sence ? " bg-[#000000] text-white" : " bg-gray-200 "
            }`}
            onClick={() =>
              handleConfirmation ? handleConfirmation(false) : ""
            }>
            No
          </button>
        </div>
      </div>
    </div>
  );
};

export default Confirm;
