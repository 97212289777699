import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import customFetch, { partnerCustomFetch } from "../../utils/axios";
import {
  getPartnerFromLocalStorage,
  getUserFromLocalStorage,
} from "../../utils/localStorage";

const initialState = {
  isLoadingPartner: false,
  partnerList: [],
  companyList: [],
  addedPartnerData: {},
  currentPage: 0,
  pageSize: 0,
  totalPages: 0,
};

// Partner GET API
export const fetchPartner = createAsyncThunk(
  "partner/fetchPartner",
  async (pagination, thunkAPI) => {
    const { accessToken } = getPartnerFromLocalStorage();
    try {
      const resp = await partnerCustomFetch.get("/api/profile", {
        params: pagination,
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      });
      return resp?.data;
    } catch (error) {
      console.error("Error fetching partners:", error);
      return thunkAPI.rejectWithValue(error.response?.data?.msg || "Failed to fetch partners.");
    }
  }
);

// Company GET API
export const fetchCompany = createAsyncThunk(
  "partner/fetchCompany",
  async (thunkAPI) => {
    const { accessToken } = getPartnerFromLocalStorage();
    console.log(accessToken);
    try {
      const resp = await partnerCustomFetch.get("/api/company", {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      });
      console.log(resp?.data);
      return resp?.data;
    } catch (error) {
      if (error) {
        console.log(error);
      }
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
//Add partner
export const addPartner = createAsyncThunk(
  "partner/addPartner",
  async (partnerData, thunkAPI) => {
    console.log(partnerData);

    try {
      const resp = await partnerCustomFetch.post(
        "/api/auth/register",
        partnerData
      );
      return resp?.data;
    } catch (error) {
      if (error) {
        console.log(error);
      }
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

//Add company
export const addCompany = createAsyncThunk(
  "partner/addCompany",
  async (companyData, thunkAPI) => {
    console.log(companyData);

    try {
      const resp = await partnerCustomFetch.post("/api/company", companyData);
      return resp?.data;
    } catch (error) {
      if (error) {
        console.log(error);
      }
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

//post aadhar
export const postAadhar = createAsyncThunk(
  "partner/postAadhar",
  async ({ partnerId, data }, thunkAPI) => {
    const { accessToken } = getPartnerFromLocalStorage();

    try {
      const resp = await partnerCustomFetch.post(
        `/api/profile/${partnerId}/upload-adhar-card`,
        data,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      thunkAPI?.dispatch(fetchPartner());
      return resp.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
      } else {
        alert(error.response.data.msg);
      }
      return thunkAPI.rejectWithValue(error.response.data.msg);
    }
  }
);

//post PAN
export const postPAN = createAsyncThunk(
  "partner/postpan",
  async ({ partnerId, data }, thunkAPI) => {
    const { accessToken } = getPartnerFromLocalStorage();
    try {
      const resp = await partnerCustomFetch.post(
        `/api/profile/${partnerId}/upload-pan-card`,
        data,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      thunkAPI?.dispatch(fetchPartner());
      console.log(resp);
      return resp?.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
      } else {
        alert(error.response.data.msg);
      }
      return thunkAPI.rejectWithValue(error.response.data.msg);
    }
  }
);

const PartnerSlice = createSlice({
  name: "partner",
  initialState,
  reducers: {
    removePartnerData: (state) => {
      state.isLoadingPartner = false;
      state.addedPartnerData = {};
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(fetchPartner.pending, (state) => {
        state.isLoadingPartner = true;
      })
      .addCase(fetchPartner.fulfilled, (state, { payload }) => {
        state.isLoadingPartner = false;
        state.partnerList = payload.content;
        state.totalPages = payload.totalPages;
        state.pageSize = payload.pageSize;
        state.currentPage = payload.currentPage;
      })
      .addCase(fetchPartner.rejected, (state) => {
        state.isLoadingPartner = false;
        alert("Unable to display partner list");
      })
      .addCase(addPartner.pending, (state) => {
        state.isLoadingPartner = true;
      })
      .addCase(addPartner.fulfilled, (state, { payload }) => {
        state.isLoadingPartner = false;
        state.addedPartnerData = payload;
        alert("Partner added successfully!");
      })
      .addCase(addPartner.rejected, (state) => {
        state.isLoadingPartner = false;
        alert("Unable to add partner!");
      })
      .addCase(fetchCompany.pending, (state) => {
        state.isLoadingPartner = true;
      })
      .addCase(fetchCompany.fulfilled, (state, { payload }) => {
        state.isLoadingPartner = false;
        state.companyList = payload;
      })
      .addCase(fetchCompany.rejected, (state) => {
        state.isLoadingPartner = false;
        alert("Unable to display partner list");
      })
      .addCase(postAadhar.pending, (state) => {
        state.isLoadingPartner = true;
      })
      .addCase(postAadhar.fulfilled, (state, { payload }) => {
        state.isLoadingPartner = false;
      })
      .addCase(postAadhar.rejected, (state) => {
        state.isLoadingPartner = false;
        alert("Unable to upload!");
      })
      .addCase(postPAN.pending, (state) => {
        state.isLoadingPartner = true;
      })
      .addCase(postPAN.fulfilled, (state, { payload }) => {
        state.isLoadingPartner = false;
      })
      .addCase(postPAN.rejected, (state) => {
        state.isLoadingPartner = false;
        alert("Unable to upload!");
      });
  },
});

export const { removePartnerData } = PartnerSlice?.actions;
export default PartnerSlice.reducer;
