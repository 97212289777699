import React from "react";
import { RxCross1 } from "react-icons/rx";
import { TbCameraPlus } from "react-icons/tb";
import { useState } from "react";
import ANSubSecSelectSubCategory from "./ANSubSecSelectSubCategory";
import { useDispatch, useSelector } from "react-redux";
import { clearFile, uploadFile } from "../features/Files/FilesSlice";
import { useEffect } from "react";
import { addHomeSubSection } from "../features/Home/homeSlice";

const AddNewSubSection = ({ handleAdding, homeId }) => {
  console.log(homeId);
  const dispatch = useDispatch();
  const [image, setImage] = useState(null);
  const { isLoading, uploadUrl } = useSelector((store) => store.file);

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [isSubCategory, setSubCategory] = useState(false);
  const [subCategoryId, setSubCategoryId] = useState(null);
  const [subCategoryName, setSubCategoryName] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  // const [selectSubCategory, setSelectSubCategory] = useState(null);

  // this is to display the sub category chosen
  const handleSelectSubCategory = (id, name) => {
    setSubCategoryId(id);
    setSubCategoryName(name);
    handleSubCategory();
  };

  const handleSubCategory = () => {
    setSubCategory(!isSubCategory);
  };

  const handleTitle = (e) => {
    const value = e.target.value;
    setTitle(value);
  };
  const handleDescription = (e) => {
    const desc = e.target.value;
    setDescription(desc);
  };

  const handleImage = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage({
        ...image,
        file: file,
        filepreview: URL.createObjectURL(file),
      });
    }
  };

  const handleUpload = () => {
    const fileData = new FormData();
    if (image?.file) {
      fileData.append("file", image.file, image?.file?.name);
      dispatch(uploadFile(fileData));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (title === "" || image === null) {
      alert("Please fill out all the fields");
    } else if (imageUrl === "") {
      alert("Please click on upload!");
    } else if (subCategoryId === null) {
      alert("Please select Sub - Category");
    } else {
      dispatch(
        addHomeSubSection({
          homeId: homeId,
          data: {
            title: title,
            thumbnailUrl: imageUrl,
            description: description,
            subCategoryId: subCategoryId,
          },
        })
      );
      dispatch(clearFile());
      handleAdding();
    }
  };

  useEffect(() => {
    if (uploadUrl) {
      setImageUrl(uploadUrl);
    }
  }, [uploadUrl]);

  useEffect(() => {
    dispatch(clearFile());
  }, []);

  return (
    <div className="fixed flex items-center justify-center top-0 bg-[#00000083] z-40 w-screen h-screen right-0">
      {/* select sub section  */}
      {isSubCategory && (
        <ANSubSecSelectSubCategory
          handleAdding={handleSubCategory}
          handleCategory={handleSelectSubCategory}
        />
      )}
      <form
        className="relative flex flex-col h-[73vh] w-[28vw]  bg-white rounded-xl"
        onSubmit={handleSubmit}>
        <div className="absolute right-0 -top-11 bg-white rounded-full text-3xl p-1 ">
          <RxCross1
            className="cursor-pointer p-1 font-bold text-black"
            onClick={handleAdding}
          />
        </div>

        {/* div for add new sub-section  */}
        <div className="flex h-[12vh] justify-center items-center text-2xl font-bold capitalize ">
          Add new sub section
        </div>

        {/* div for the title of the add new sub-section  */}
        <div className="relative flex border h-[7vh] mx-5 my-2 rounded-lg border-gray-500 p-2">
          <span className="flex absolute justify-center items-center border-[#989696] w-12 -top-3 left-6 bg-white text-sm">
            Title
          </span>
          <input
            type="text"
            placeholder="Enter the Title of Sub-Section"
            className="w-full outline-none p-1 px-3 "
            value={title}
            onChange={handleTitle}
          />
        </div>
        <div className="flex justify-center items-center h-[25vh] my-2 ">
          <label>
            <input
              type="file"
              onChange={handleImage}
              style={{ display: "none" }}
            />
            <div>
              {image ? (
                <img
                  src={image?.filepreview}
                  alt=""
                  className="h-24 w-24 object-cover bg-gray-50 text-xs cursor-pointer mb-2 m-auto"
                />
              ) : (
                <span>
                  <TbCameraPlus className="text-8xl mb-2 text-[#848181] cursor-pointer m-auto" />
                </span>
              )}
            </div>
            <div className="flex items-center justify-center gap-3">
              <div
                className="flex items-center justify-center border px-3 h-8 bg-[#AFAFAF] text-white cursor-pointer"
                type="button">
                Change
              </div>
              <button
                className="border px-3 h-8 bg-[#EEBF00] text-white"
                type="button"
                onClick={handleUpload}>
                {isLoading ? (
                  <span className="text-sm">Uploading...</span>
                ) : (
                  "Upload"
                )}
              </button>
            </div>
          </label>
        </div>
        <div className="relative flex border h-[10vh] mx-5 my-2 mt-3 rounded-lg border-gray-500 p-2">
          <span className="flex absolute justify-center items-center border-[#989696] w-24 -top-3 left-6 bg-white text-sm">
            Description
          </span>
          <input
            type="text"
            placeholder="Enter the Description of Sub-Section"
            className="w-full outline-none p-1 px-3"
            value={description}
            onChange={handleDescription}
          />
        </div>

        <div
          className="flex items-center justify-center border h-[7vh] mx-5 mb-5 mt-3 border-[#989696] rounded-lg text-[#7f7c7c] text-lg cursor-pointer"
          onClick={setSubCategory}>
          {subCategoryId ? (
            <span className="text-black">
              {/* {subCategoryId} */}
              {subCategoryName}
            </span>
          ) : (
            "--Select Sub-Category--"
          )}
        </div>
        <div className="flex h-[12%] text-2xl mt-4 overflow-hidden bottom-0 rounded-b-xl">
          <button
            className="w-[50%] bg-[#c5c3c3]"
            type="button"
            onClick={handleAdding}>
            Cancel
          </button>
          <button className="w-[50%] bg-[#FF57B6] text-white" type="submit">
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddNewSubSection;
