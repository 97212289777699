import React, { useEffect, useState } from "react";
import ManageAddress from "../ManageAddress";
import { useDispatch, useSelector } from "react-redux";
import { fetchAddresses } from "../../features/address/addressSlice";

const Addresses = ({ handleDataChange, userId, bookingState }) => {
  const dispatch = useDispatch();

  const [isAdding, setAdding] = useState(false);

  const addressesList = useSelector((store) => store.address?.addresses);
  console.log(addressesList);

  const handleAdding = () => {
    setAdding((prev) => !prev);
  };

  const handleSelect = (id, city) => {
    console.log(handleDataChange);
    if (handleDataChange) {
      handleDataChange({
        ...bookingState,
        bookingAddressId: id,
        cityName: city,
      });
    }
  };

  useEffect(() => {
    dispatch(fetchAddresses(userId));
  }, []);

  return (
    <div className="flex flex-col rounded-lg h-full gap-[1rem] w-full justify-between">
      {isAdding && (
        <ManageAddress
          userId={userId}
          purpose={"Add New Address"}
          handleEditing={handleAdding}
        />
      )}
      <div className="flex items-center text-xl font-semibold w-full h-[3rem] px-2 ">
        Saved Addresses
      </div>
      <div className="flex-1">
        {addressesList?.map((elem, index) => {
          return (
            <div
              className={
                "px-[2rem] py-[1rem] mt-2 rounded-xl mx-4 cursor-pointer hover:scale-x-105 transition-all ease-in-out duration-150 " +
                (bookingState?.bookingAddressId === elem?.id
                  ? " bg-black text-white"
                  : " bg-dark-gray")
              }
              onClick={() => handleSelect(elem?.id, elem?.city)}
              key={index}>
              <div className="font-semibold pb-[0.2rem]">HOME</div>
              <div className="text-sm ">{elem?.city}</div>
              <div className="text-sm ">Bihar-221003</div>
            </div>
          );
        })}
      </div>
      <button
        className="uppercase p-2 text-sm rounded-t-lg mx-auto bg-primary-70 text-white"
        onClick={handleAdding}>
        Add new address
      </button>
    </div>
  );
};

export default Addresses;
