import React from "react";

import Services from "../../Images/Icons/grommet-icons_services.svg";
import Guide from "../../Images/Icons/ri_guide-line.svg";
import Offer from "../../Images/Icons/bxs_offer.svg";
import FAQ from "../../Images/Icons/mdi_faq.svg";
import Partner from "../../Images/Icons/Vector.svg";
import Payment from "../../Images/Icons/fluent_payment-24-regular.svg";
import { useNavigate } from "react-router-dom";

const Help = () => {
	const navigate = useNavigate();
	const handleFAQNavigate = (path) => {
		navigate(`${path}`);
	};
	return (
		<div className="">
			<h1 className="text-2xl font-semibold text-center w-full mt-[2rem] max-[1100px]:mt-[1rem]">
				How can we help you?
			</h1>
			<div className="px-[23.6vw] pt-[4vw] max-[1300px]:px-[18vw] max-[1100px]:pt-[2rem] max-[900px]:px-[14vw] max-[750px]:px-[10vw]">
				<div className="grid grid-cols-3 gap-x-[7.5vw] gap-y-[4.375vw]  ">
					<div
						className="bg-white w-[11.75rem] h-[10.5rem] rounded-lg flex flex-col gap-6 items-center justify-center  pt-[3.688rem] pb-[2rem]"
						style={{ boxShadow: "0 4px 4px 0 #00000040" }}>
						<img src={Services} alt="" />
						<h1>Service</h1>
					</div>
					<div
						className="bg-white w-[11.75rem] h-[10.5rem] rounded-lg flex flex-col gap-6 items-center justify-center  pt-[3.688rem] pb-[2rem]"
						style={{ boxShadow: "0 4px 4px 0 #00000040" }}>
						<img src={Guide} alt="" />
						<h1>Guide</h1>
					</div>
					<div
						className="bg-white w-[11.75rem] h-[10.5rem] rounded-lg flex flex-col gap-6 items-center justify-center  pt-[3.688rem] pb-[2rem]"
						style={{ boxShadow: "0 4px 4px 0 #00000040" }}>
						<img src={Offer} alt="" />
						<h1>Offers</h1>
					</div>
					<div
						className="bg-white w-[11.75rem] h-[10.5rem] rounded-lg flex flex-col gap-6 items-center justify-center  pt-[3.688rem] pb-[2rem] cursor-pointer"
						style={{ boxShadow: "0 4px 4px 0 #00000040" }}
						onClick={() => handleFAQNavigate("/help/FAQs")}>
						<img src={FAQ} alt="" />
						<h1>FAQs</h1>
					</div>
					<div
						className="bg-white w-[11.75rem] h-[10.5rem] rounded-lg flex flex-col gap-6 items-center justify-center  pt-[3.688rem] pb-[2rem]"
						style={{ boxShadow: "0 4px 4px 0 #00000040" }}>
						<img src={Partner} alt="" />
						<h1>Partner</h1>
					</div>
					<div
						className="bg-white w-[11.75rem] h-[10.5rem] rounded-lg flex flex-col gap-6 items-center justify-center  pt-[3.688rem] pb-[2rem] mb-[10rem]"
						style={{ boxShadow: "0 4px 4px 0 #00000040" }}>
						<img src={Payment} alt="" />
						<h1>Payment</h1>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Help;
