import React, { useState, useEffect } from "react";

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { loginUser } from "../features/user/UserSlice";
import { useNavigate } from "react-router-dom";
import signin_banner from "../Images/home_img.jpg";
import talency_logo from "../Images/talency_logo.png";
import { BiSolidHide, BiSolidShow } from "react-icons/bi";

const initialState = {
  username: "",
  password: "",
  rememberMe: true,
};

const Login = () => {
  const [values, setValues] = useState(initialState);
  const { user, isLoading } = useSelector((store) => store.user);
  const [isPasswordShow, setPasswordShow] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setValues({ ...values, [name]: value });
  };

  console.log(values);
  const onSubmit = (e) => {
    e.preventDefault();
    const { username, password, rememberMe } = values;
    if (!username || !password) {
      alert("Please fill out all fields");
      return;
    }
    dispatch(
      loginUser({ emailOrMobile: username, password: password, rememberMe })
    );
  };

  const toggleMember = () => {
    setTimeout(() => {
      navigate("/register");
    }, 10);
  };

  const forgotPassword = () => {
    setTimeout(() => {
      navigate("/forgotpassword");
    }, 10);
  };

  useEffect(() => {
    if (user) {
      setTimeout(() => {
        navigate("/");
      }, 10);
    }
  }, [user, navigate]);

  // Regex code for

  return (
    <div className="login-container flex flex-col lg:flex-row h-screen">
      <div className="image-section relative w-full lg:w-1/2">
        <img
          src={signin_banner}
          alt="Login"
          className="object-cover h-full w-full"
        />
        {/* <div className="absolute top-0 left-0 right-0 bottom-0 flex flex-col items-center justify-center text-center">
          <h2 className="text-4xl text-white font-bold mb-4">
            Welcome to ClapUrban
          </h2>
          <p className="text-medium text-white">
            simply dummy text of the printing and typesetting industry
          </p>
        </div> */}
      </div>

      <div className="w-full lg:w-1/2">
      <div className="mt-20">
          <div
            to={"/"}
            className="flex w-full items-center justify-center  gap-2">
            <img
              src="https://clapurban.blr1.digitaloceanspaces.com/1713083984695_EMPLUX-removebg-preview (1).png"
              alt=""
              className="w-20"
            />
            <h1 className=" p-1  text-4xl font-bold uppercase text-primary-600  ">
              EMPLUX
            </h1>
          </div>
        </div>

        <div className="w-full p-6 mx-auto lg:max-w-xl mt-10">
          <div className="form-section">
            <form onSubmit={onSubmit}>
              {/* Login UI starts here */}
              <div className="mb-2 mt-5">
                {/* Email field */}
                <input
                  type="text"
                  name="username"
                  placeholder="Enter your Email"
                  onChange={handleChange}
                  className="block w-full px-4 py-2 mt-2 text-neutral-800 bg-stone-100 border rounded-md focus:border-neutral-800 focus:ring-indigo-600 focus:outline-none focus:ring focus:ring-opacity-40"
                />
              </div>
              {/* Password field */}
              <div className="mb-2 mt-5">
                {/* <input
                  type="password"
                  name="password"
                  placeholder="Password"
                  onChange={handleChange}
                  className="block w-full px-4 py-2 mt-2 text-neutral-800 bg-stone-100 border rounded-md focus:border-neutral-800 focus:ring-indigo-600 focus:outline-none focus:ring focus:ring-opacity-40"
                /> */}
                <div className="relative ">
                  <div className="absolute right-3 top-1/2 transform -translate-y-1/2 h-max w-max">
                    {isPasswordShow ? (
                      <BiSolidHide
                        className="text-xl"
                        onClick={() => setPasswordShow(false)}
                      />
                    ) : (
                      <BiSolidShow
                        className="text-xl"
                        onClick={() => setPasswordShow(true)}
                      />
                    )}
                  </div>
                  <input
                    type={isPasswordShow ? "text" : "password"}
                    name="password"
                    placeholder="Password"
                    maxLength={30}
                    onChange={handleChange}
                    className="block w-full px-4 py-2 mt-2 text-neutral-800 bg-stone-100 border rounded-md focus:border-neutral-800 focus:ring-indigo-600 focus:outline-none focus:ring focus:ring-opacity-40"
                  />
                </div>

                <div className="flex justify-between">
                  <p className="mt-3 text-xs font-light text-start text-gray-700">
                    <label className="flex items-center">
                      <input
                        type="checkbox"
                        className="form-checkbox h-4 w-4 text-gray-600 transition duration-150 ease-in-out"
                        //onChange={handleRememberMe}
                      />
                      <span className="ml-2 text-sm font-medium text-gray-700">
                        Remember me
                      </span>
                    </label>
                  </p>
                  <p className="mt-3 text-xs font-light text-start text-gray-700">
                    <span
                      className="ml-2 text-sm font-medium text-gray-700 cursor-pointer hover:underline"
                      onClick={forgotPassword}
                    >
                      Forgot Password
                    </span>
                  </p>
                </div>
              </div>

              <div className="mt-6">
                <button
                  disabled={isLoading}
                  className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-neutral-800 rounded-md hover:bg-neutral-600 focus:outline-none focus:bg-purple-600"
                >
                  {isLoading ? "Loading..." : "Sign In"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
