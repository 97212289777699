import React from "react";
import profilePic from "../../Images/nav_img.jpg";
import { FaRegEdit } from "react-icons/fa";

const TeamDetail = () => {
	return (
		<div className="flex flex-col  px-[20rem] py-[4rem] h-[36rem] gap-[2rem]">
			<div
				className="flex  w-full h-full rounded-lg"
				style={{ boxShadow: "0px 0px 12px #000000" }}>
				<div className="flex flex-col items-center  py-[2rem] bg-[#1d1d1d] h-full w-1/3 rounded-l-lg gap-[1rem]">
					<img
						src={profilePic}
						className="rounded-full h-[10rem] w-[10rem] shadow-sm shadow-black mb-[2rem]"
					/>
					<div className="text-xl text-white">Niyam Rai</div>
					<div className="text-white">Front - End Web Developer</div>
					<div className="flex justify-center items-center gap-[1rem] rounded-xl text-lg bg-white border w-[12rem] h-[3rem] mt-[3rem]">
						<FaRegEdit />
						Edit
					</div>
				</div>
				<div className="flex flex-col h-full w-2/3 bg-[#e3e3e3] rounded-r-lg px-[3rem] py-[1rem] gap-[2rem]">
					<div className="flex items-center text-xl font-semibold border-b border-black w-full h-[3rem] ">
						Information
					</div>
					<div className="flex ">
						<div className="flex flex-col gap-[0.2rem] w-2/3">
							<div className="font-bold text-lg">Email</div>
							<div className="text-[#4f4f4f]">ayushniyam@gmail.com</div>
						</div>
						<div className="flex flex-col gap-[0.2rem] ">
							<div className="font-bold text-lg">Mobile</div>
							<div className="text-[#4f4f4f]">+917272909873</div>
						</div>
					</div>
					<div className="flex ">
						<div className="flex flex-col gap-[0.2rem] w-2/3">
							<div className="font-bold text-lg">Gender</div>
							<div className="text-[#4f4f4f]">Male</div>
						</div>
						<div className="flex flex-col gap-[0.2rem]">
							<div className="font-bold text-lg">Age</div>
							<div className="text-[#4f4f4f]">22</div>
						</div>
					</div>
					<div className="flex items-center text-xl font-semibold border-b border-black w-full h-[3rem] ">
						Extras
					</div>
					<div className="flex ">
						<div className="flex flex-col gap-[0.2rem] w-2/3">
							<div className="font-bold text-lg">Alternate Mobile Number</div>
							<div className="text-[#4f4f4f]">+919336674149</div>
						</div>
						<div className="flex flex-col gap-[0.2rem]">
							<div className="font-bold text-lg">Location</div>
							<div className="text-[#4f4f4f]">Bangalore</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TeamDetail;
