import React, { useState } from "react";
import { RxCross1 } from "react-icons/rx";
import UploadFileTemp from "./UploadFileTemp";
import { useDispatch, useSelector } from "react-redux";
import { postAadhar } from "../features/Partner/PartnerSlice";

const initialState = {
  aadharNo: "",
  aadharFrontImgUrl: "",
  aadharBackImgUrl: "",
};

const ManagePartnerAadhaar = ({ handleManaging, edit, partnerId }) => {
  const dispatch = useDispatch();
  const { isLoadingPartner } = useSelector((store) => store.partner);

  const [aadharDetail, setAadharDetail] = useState(edit || initialState);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      aadharDetail?.aadharNo == null ||
      aadharDetail?.aadharNo?.trim() === ""
    ) {
      alert("Please Fill Aadhaar Number!");
      return;
    } else if (
      aadharDetail?.aadharBackImgUrl == null ||
      aadharDetail?.aadharBackImgUrl?.trim() === "" ||
      aadharDetail?.aadharFrontImgUrl == null ||
      aadharDetail?.aadharFrontImgUrl?.trim() === ""
    ) {
      alert("Please upload front and back image!");
      return;
    } else {
      const resp = await dispatch(
        postAadhar({ partnerId: partnerId, data: aadharDetail })
      );
      if (resp?.type?.indexOf("fulfilled")) {
        handleManaging();
      }
    }
  };
  const handleUpload = (name, url) => {
    console.log(name, url);
    setAadharDetail({ ...aadharDetail, [name]: url });
  };
  // data change
  const handleChange = (e) => {
    const { name, value } = e?.target;
    if (name === "aadharNo" && value?.length > 12) {
      return;
    }
    setAadharDetail({ ...aadharDetail, [name]: value });
  };
  console.log(aadharDetail);
  return (
    <div className="fixed flex items-center justify-center top-0 bg-[#00000083] z-40 w-screen right-0 h-screen">
      <form
        className="flex flex-col relative  h-[450px] w-[450px] rounded-xl bg-white px-4"
        onSubmit={handleSubmit}>
        <div className="absolute p-1 -top-7 -right-1 m-1 text-3xl font-bold bg-white rounded-3xl -my-4">
          <RxCross1
            className="cursor-pointer p-1 font-bold"
            onClick={handleManaging}
          />
        </div>
        <div className="flex justify-center items-center h-[75px] text-3xl font-mono font-bold">
          {edit ? "Edit Aadhaar" : "Add Aadhaar"}
        </div>

        <div className="flex-1 overflow-y-auto no-scrollbar py-5">
          <span className="relative flex flex-col gap-[0.2rem]">
            <div className="absolute -top-3 left-4 px-3 text-sm bg-white">
              Aadhar No.
            </div>
            <input
              type="aadharNo"
              name="aadharNo"
              min={12}
              max={12}
              required
              value={aadharDetail?.aadharNo}
              onChange={handleChange}
              className="h-[2rem] py-[1.3rem] pl-[0.8rem] pr-[2rem] w-full text-sm bg-white border border-black rounded-lg"
            />
          </span>
          <UploadFileTemp
            name={"aadharFrontImgUrl"}
            handleUploaded={handleUpload}
            data={aadharDetail}
            text={"Aadhar Front"}
          />
          <UploadFileTemp
            name={"aadharBackImgUrl"}
            handleUploaded={handleUpload}
            data={aadharDetail}
            text={"Aadhar Back"}
          />
        </div>
        <div className="flex flex-row absolute bottom-0 w-full h-[55px] rounded-b-xl overflow-hidden left-0">
          <button
            type="button"
            className="flex w-1/2 justify-center items-center text-2xl bg-gray-300 "
            onClick={handleManaging}>
            Cancel
          </button>
          <button
            type="submit"
            className="flex w-1/2 justify-center items-center text-2xl bg-primary-max border-primary-600 text-white"
            disabled={isLoadingPartner}>
            {isLoadingPartner ? "Loading..." : "Save"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default ManagePartnerAadhaar;
