import React, { useEffect } from "react";
import { BsFillTrash2Fill } from "react-icons/bs";
import { AiFillCloseCircle } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteCart,
  getCart,
  setTotalAmount,
} from "../../features/Cart/CartSlice";
import { useState } from "react";

const Cart = ({ userId }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { subCatgId } = useParams();

  const { issuesList } = useSelector((state) => state.issue);
  const { totalAmount, cartItems } = useSelector((state) => state.cart);
  const [isEmpty, setEmpty] = useState(cartItems?.length > 0 ? false : true);

  const handleRemove = (cartId) => {
    dispatch(deleteCart({ cartId: cartId, userId: userId }));

    // setTimeout(() => {
    //   dispatch(getCart());
    // }, 3000);
  };

  console.log(cartItems);

  const checkEmptyCart = () => {
    if (cartItems?.length === 0) {
      return true;
    } else {
      const target = cartItems?.slice(0, 1)[0]?.issueId;
      const isTargetPresent = issuesList?.some((obj) => obj.id === target);
      return !isTargetPresent;
    }
  };

  useEffect(() => {
    setEmpty(checkEmptyCart());
  }, [cartItems]);

  useEffect(() => {
    if (userId) {
      dispatch(getCart(userId));
    }
  }, []);
  return (
    <div className="relative  min-h-max min-w-[40%] border-t   shadow-lg  ">
      {/* Heading  */}
      <div className="px-4 py-2 text-xl my-2 font-semibold font-sans justify-between flex">
        <span>Cart</span>
        <span>{"Total Amount: " + "₹" + totalAmount}</span>
      </div>

      {/* Items */}
      {!isEmpty ? (
        cartItems?.map((element) => {
          const {
            id,
            issueName,
            issueDuration,
            issuePrice,
            issueDescription,
            active,
          } = element;
          console.log(element);
          if (active) {
            return (
              <div className="relative flex items-center  border h-14 shadow-md  mb-2 mx-4 ">
                <span
                  className=" absolute  top-1 left-1 p-1 hover:bg-gray-100 cursor-pointer"
                  onClick={() => handleRemove(id)}>
                  <AiFillCloseCircle className="text-xl opacity-60" />
                </span>
                <span className="absolute left-20 ">{issueName}</span>
                <span className="absolute right-10">Rs. {issuePrice}</span>
              </div>
            );
          }
        })
      ) : (
        <div className="h-1/2 p-2">
          <div className="flex flex-col items-center justify-center border-dashed border-2 text-gray-400 p-4 gap-3">
            <BsFillTrash2Fill className="text-3xl" />
            <span>Add Services</span>
          </div>
        </div>
      )}

      {/* View details Button  */}
    </div>
  );
};

export default Cart;
