import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { deleteFAQ } from "../features/FAQ/FAQslice";

const DeletePopup = ({ handleDelete, deleteId }) => {
	const dispatch = useDispatch();
	const handleYes = () => {
		dispatch(deleteFAQ(deleteId));
		handleDelete(deleteId);
	};
	return (
		<div className="fixed flex items-center justify-center top-0 bg-[#000000a9] z-40 w-screen right-0 h-screen">
			<div className="flex flex-col items-center justify-center relative  w-[35rem] rounded-xl bg-white py-[1.5rem] gap-[2rem]">
				<div className="text-2xl font-bold">
					Are you sure you want to delete?
				</div>
				<div className="flex gap-[4rem]">
					<div
						className="bg-[#ff57b6] text-white py-[0.2rem] px-[1rem] text-lg rounded-lg cursor-pointer"
						onClick={handleYes}>
						Yes
					</div>
					<div
						className="border-[#ff57b6] border text-[#cf4192] py-[0.2rem] px-[1rem] text-lg rounded-lg cursor-pointer"
						onClick={() => handleDelete(deleteId)}>
						No
					</div>
				</div>
			</div>
		</div>
	);
};

export default DeletePopup;
