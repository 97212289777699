import React, { useEffect, useState } from "react";
import { BsSearch } from "react-icons/bs";
import { IoIosArrowDown } from "react-icons/io";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { BsPencilSquare } from "react-icons/bs";
import { MdDelete } from "react-icons/md";
import AddNewFAQ from "../../components/AddNewFAQ";
import DeletePopup from "../../components/DeletePopup";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getFAQ } from "../../features/FAQ/FAQslice";
import EditFAQ from "./EditFAQ";

const ManageFAQs = () => {
	const dispatch = useDispatch();
	const { FAQList } = useSelector((store) => store.faq);

	const [isShown, setShown] = useState(null);
	const handleChange = (index) => {
		if (index === isShown) {
			setShown(null);
		} else setShown(index);
	};

	const [isDot, setDots] = useState(null);
	const handleThreeDots = (index) => {
		if (index === isDot) {
			setDots(null);
		} else setDots(index);
	};

	const [isAddNew, setAddNew] = useState(false);
	const handleAddNewPopup = () => {
		setAddNew((s) => !s);
	};
	// const [isEditNew, setEditNew] = useState(false);
	// const handleEditPopupRemove = () => {
	// 	setAddNew((s) => !s);
	// };

	const [isDelete, setDelete] = useState(false);
	const handleDeletePopup = (index) => {
		if (index === isDelete) {
			setDelete(null);
		} else setDelete(index);
	};
	const [isEdit, setEdit] = useState(false);
	const handleEditPopup = (index) => {
		if (index === isEdit) {
			setEdit(null);
		} else setEdit(index);
	};

	useEffect(() => {
		dispatch(getFAQ());
	}, []);

	return (
		<div className="">
			{isAddNew && <AddNewFAQ handleAddNew={handleAddNewPopup} />}
			{isDelete && (
				<DeletePopup handleDelete={handleDeletePopup} deleteId={isDelete} />
			)}
			{isEdit && (
					<EditFAQ handleClose={() => setEdit(null)} selectedId={isEdit} FAQList={FAQList}
					selectedFAQ={FAQList.find((faq) => faq.id === isEdit)} />
			)}
			<div className="flex flex-row px-6 gap-5 h-20">
				<div className="flex justify-start pl-[8rem] items-center w-2/3 ">
					<div className="flex items-center m-3 w-1/3 h-11 rounded-lg  px-4 bg-sky-100 ">
						<BsSearch className=" font-extrabold" />
						<input
							type="Search"
							placeholder="Search..."
							className="pl-3 pr-1/2 w-full bg-sky-100 outline-none"
						/>
					</div>
				</div>

				{/* add new  */}
				<div className="relative flex w-1/3 pr-[8rem] h-20 m-2 justify-end items-center">
					<div
						className="flex justify-center cursor-pointer items-center w-48 h-12 text-lg bg-primary-600 font-semibold rounded hover:scale-110 hover:transition hover:delay-75 text-white"
						onClick={handleAddNewPopup}>
						Add new
					</div>
				</div>
			</div>

			<div className="flex flex-col px-[10rem]  mt-[1rem] mb-[16rem]">
				<div className="flex text-2xl border-b border-[#8b8b8b]  py-[1rem] font-medium ">
					Frequently Asked Questions
				</div>
				{FAQList?.map((elem, index) => {
					return (
						<div className="flex flex-col mt-[1rem]">
							<div
								className="flex  items-center border-2 px-3 rounded-md  h-[3rem] bg-sky-950 text-sky-100"
								onClick={() => handleChange(index)}>
								<div className="w-3/4  px-3 text-md cursor-pointer">
									Q. {elem.question}
								</div>
								<div
									className={`handleChange ${
										isShown === index
											? "flex w-1/4 mr-[1rem] rotate-180 cursor-pointer"
											: "flex items-start justify-end w-1/4 mr-[1rem]  cursor-pointer"
									}`}>
									<IoIosArrowDown className="text-2xl" />
								</div>
							</div>
							{isShown === index && (
								<div className="relative flex w-full mt-[0.2rem] text-sm pl-[1.7rem] bg-sky-100 rounded-md py-[1rem]">
									<div className="w-4/5">Ans. {elem?.ans}</div>
									<div
										className="flex justify-end items-center pr-[1.5rem] text-3xl w-1/5 cursor-pointer"
										onClick={() => handleThreeDots(index)}>
										<BiDotsVerticalRounded />
									</div>
									<div
										className={`handleThreeDots ${
											isDot === index
												? "absolute px-[1rem] py-[0.5rem] flex flex-col items-start justify-center top-[3rem] -right-[0rem]  gap-[0.5rem] bg-white text-black z-30 cursor-pointer shadow-md shadow-black rounded-md"
												: "hidden cursor-pointer"
										}`}>
										<div
										onClick={() => handleEditPopup(elem?.id)}
										className="flex items-center gap-[0.8rem] font-medium pl-[0.15rem]">
											<BsPencilSquare />
											Edit
										</div>
										<div
											className="flex items-center gap-[0.5rem] text-[#e14747] font-medium"
											onClick={() => handleDeletePopup(elem?.id)}>
											<MdDelete className="text-lg" />
											Delete
										</div>
									</div>
								</div>
							)}
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default ManageFAQs;
