import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BsThreeDotsVertical } from "react-icons/bs";
import PaginationBar from "../../components/PaginationBar";
import { getAllBooking } from "../../features/Bookings/BookingSlice";
import BookingList from "../../components/BookingList";
import { formatDate, handleCopy } from "../../utils/utilFunctions";
import { FaCopy } from "react-icons/fa6";
import AssignPartner from "../../components/AssignPartner";
import { Link } from "react-router-dom";
import { ObjectLoader } from "../../components/EmpluxLoaders/Loader";

const ManageBookings = () => {
  const dispatch = useDispatch();
  const [isActions, setActions] = useState(null);
  const [isAssigningPartner, setAssigningPartner] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);

  const { totalPages, bookings, isLoadingBooking } = useSelector(
    (state) => state.bookings
  );

  // states

  // selector

  const handleAssigningPartner = (id) => {
    if (isAssigningPartner) {
      setAssigningPartner(null);
    } else setAssigningPartner(id);
  };
  const handleActionClick = (id) => {
    if (id === isActions) {
      setActions(null);
    } else setActions(id);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    // You can add logic here to fetch data for the new page
    const queryParams = {
      pageNo: 5,
      pageSize: 10,
    };
    dispatch(getAllBooking(queryParams));
  };
  
  useEffect(() => {
    dispatch(getAllBooking());
    window.scrollTo(0, 0);
  }, []);

  console.log(bookings);

  return (
    <div className="relative w-full h-full bg-white mb-10 min-h-screen">
      {/* popus */}
      {isAssigningPartner && (
        <AssignPartner
          handlePartner={handleAssigningPartner}
          bookingId={isAssigningPartner}
        />
      )}
      {/* Add Catagory  */}

      {/* Search and add */}
      <div className="w-full px-4  relative flex justify-start items-center pb-4 pt-8 m-auto ">
        <input
          type="text"
          className="p-2 my-4 mx-2 rounded-md outline-none bg-[#f3f9c4]"
          placeholder="Search "
        />
        {/* <button
          className="text-white p-2 absolute right-6  rounded-sm bg-green-500 font-semibold"
          onClick={handleAddCatagoryClick}>
          Add New
        </button> */}
      </div>
      {/* data */}
      <div className=" w-full px-4 m-auto min-h-max max-[1300px]:text-xs max-[1000px]:text-[8px] max-[1450px]:text-sm">
        {/* Head of the table */}
        <div className="h-10 border-x border-t  shadow-md bg-primary-70 text-white  flex items-center  mb-3 overflow-hidden rounded-md">
          <span className="w-[12%] pl-5 h-full text-left capitalize py-2 overflow-hidden">
            Booking ID
          </span>
          <span className="w-[12%] h-full text-left capitalize py-2">
            User Name
          </span>
          <span className="w-1/12 h-full text-left capitalize py-2">
            User ID
          </span>
          <span className="flex-1 h-full text-left capitalize py-2">
            Address
          </span>

          <span className="w-1/12 h-full text-left capitalize py-2">
            Mobile
          </span>
          <span className="w-1/12 h-full text-center capitalize py-2">
            Status
          </span>
          <span className="w-1/12 h-full text-center capitalize py-2">
            Slot
          </span>
          <span className="w-1/12 h-full text-center capitalize py-2">
            Partner ID
          </span>
          <span className="w-[13%] h-full text-center capitalize py-2">
            Sub-Category
          </span>
          <span className="w-[3.5%] h-full text-center py-2 "></span>
        </div>
        {/* Catagories card*/}
        {!isLoadingBooking ? (
          bookings?.map((elem, index) => {
            const slotDate = new Date(
              elem?.confirmBooking?.slice(0, 1)[0]?.bookingSlot
            );

            const formattedTime = formatDate(slotDate);

            return (
              <div
                className={
                  "h-16 w-full hover:border-gray-400 flex items-center border-x  rounded-md relative  tracking-wider text-sm  " +
                  (index % 2 !== 0 && " bg-gray-200")
                }
                key={index}>
                <Link
                  to={`/booking/detail/${
                    elem?.confirmBooking?.slice(0, 1)[0]?.id
                  }`}
                  className="w-[12%] pl-5   text-left capitalize  py-2 overflow-hidden font-semibold">
                  {elem?.confirmBooking?.slice(0, 1)[0]?.orderId}
                </Link>
                <span className="w-[12%]   text-left capitalize py-2 ">
                  {elem?.confirmBooking?.slice(0, 1)[0]?.user?.firstName ||
                    "--"}
                </span>
                <span className="w-1/12   text-left capitalize py-2 overflow-hidden">
                  {elem?.confirmBooking?.slice(0, 1)[0]?.user?.id || "--"}
                </span>
                <span className="flex-1  text-left  py-2 max-[1300px]:text-[6px] max-[1000px]:text-[8px] max-[1450px]:text-xs  text-sm  overflow-hidden flex gap-1 pr-2 items-center ">
                  <div className="overflow-ellipsis whitespace-nowrap overflow-hidden inline ">
                    {elem?.confirmBooking?.slice(0, 1)[0]?.addresses?.address ||
                      "--"}
                  </div>
                  {elem?.confirmBooking?.slice(0, 1)[0]?.addresses?.address && (
                    <FaCopy
                      className="cursor-pointer min-h-3 min-w-3 w-1/6"
                      onClick={() =>
                        handleCopy(
                          elem?.confirmBooking?.slice(0, 1)[0]?.addresses
                            ?.address
                        )
                      }
                    />
                  )}
                </span>
                <span className="w-1/12   text-left  py-2 max-[1300px]:text-[6px] max-[1000px]:text-[8px] max-[1450px]:text-xs  text-sm">
                  {elem?.confirmBooking?.slice(0, 1)[0]?.user?.mobile}
                </span>
                <span className="w-1/12   text-center capitalize py-2 ">
                  {elem?.confirmBooking?.slice(0, 1)[0]?.status}
                </span>

                <span className="w-1/12 text-center capitalize py-2 font-semibold  ">
                  {formattedTime?.slice(0, 9)}
                  <br />
                  {formattedTime?.slice(9)}
                </span>
                <span className="w-1/12 text-center capitalize py-2 font-semibold  ">
                  {elem?.confirmBooking?.slice(0, 1)[0]?.partner?.id || "--"}
                </span>
                <span className="w-[13%] text-center capitalize py-2  text-xs font-semibold">
                  {elem?.confirmBooking?.slice(0, 1)[0]?.subCategoryName ||
                    "--"}
                </span>
                <span
                  className="w-[3.5%]  flex justify-center py-2  cursor-pointer relative z-10"
                  onClick={() => handleActionClick(index)}>
                  {!elem?.confirmBooking?.slice(0, 1)[0]?.partner?.id && (
                    <div className="absolute right-2 top-0 z-10 ">
                      <div className="absolute h-2 w-2 animate-ping rounded-full bg-blue-300 -z-10 "></div>
                      <div className="z-10 h-2 w-2 bg-blue-500 rounded-full"></div>
                    </div>
                  )}
                  <BsThreeDotsVertical className="text-lg" />
                </span>

                {/* Actions */}
                {isActions === index && (
                  <div className="w-[150px]  bg-white absolute right-0 top-[100%]  z-30   rounded-b-md shadow-2xl flex flex-col  justify-center text-[14px] overflow-hidden pt-1">
                    <Link
                      to={`/booking/detail/${
                        elem?.confirmBooking?.slice(0, 1)[0]?.id
                      }`}
                      className="flex gap-2 hover:bg-gray-50 p-2 rounded-md font-mono text-gray-600 items-center cursor-pointer">
                      <span>{/* <AiFillEdit /> */}</span>
                      <span>Details</span>
                    </Link>
                    {!elem?.confirmBooking?.slice(0, 1)[0]?.partner?.id && (
                      <div
                        className="flex gap-2 hover:bg-gray-50 p-2 rounded-md font-mono text-gray-600 items-center cursor-pointer relative"
                        onClick={() =>
                          handleAssigningPartner(
                            elem?.confirmBooking?.slice(0, 1)[0]?.id
                          )
                        }>
                        <span>{/* <AiTwotoneEyeInvisible /> */}</span>
                        <span>Assign</span>

                        <div className="absolute left-1 top-2 ">
                          {/* <div className="absolute h-1 w-1 animate-ping rounded-full bg-blue-300 -z-10 "></div> */}
                          <div className="z-10 h-1 w-1 bg-blue-500 rounded-full animate-ping"></div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            );
          })
        ) : (
          <div className="w-full h-[80vh]">
            <ObjectLoader />
          </div>
        )}

        {/* Add New */}
        {/* <div
          className="w-full mt-4 border-2 border-dashed flex  items-center justify-center h-16 gap-4 text-2xl font-semibold text-gray-400 hover:bg-gray-50 cursor-pointer"
          onClick={handleAddCatagoryClick}>
          <span>
            <IoAdd />
          </span>
          <span>Add More Catagories</span>
        </div> */}
      </div>
      <PaginationBar
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
    </div>
  );
};

export default ManageBookings;
