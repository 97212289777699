import React from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

const PaginationBar = ({ currentPage, totalPages, onPageChange }) => {
  console.log(currentPage);
  const handlePreviousClick = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNextClick = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  return (
    <div className="  w-full">
      <div className="flex justify-center items-center space-x-2 mt-4 ">
        <button
          onClick={handlePreviousClick || (() => alert("Error!"))}
          className={`${
            currentPage === 1
              ? "bg-gray-300 cursor-not-allowed"
              : "bg-blue-500 hover:bg-blue-600 cursor-pointer"
          } text-white px-3 py-1 rounded-md`}
          disabled={currentPage === 1}>
          <FaChevronLeft />
        </button>
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index}
            onClick={() => onPageChange(index + 1)}
            className={`${
              currentPage === index + 1
                ? "bg-blue-500 text-white"
                : "bg-gray-300 hover:bg-blue-500 text-blue-800 hover:text-white"
            } px-3 py-1 rounded-md`}>
            {index + 1}
          </button>
        ))}
        <button
          onClick={handleNextClick}
          className={`${
            currentPage === totalPages
              ? "bg-gray-300 cursor-not-allowed"
              : "bg-blue-500 hover:bg-blue-600 cursor-pointer"
          } text-white px-3 py-1 rounded-md`}
          disabled={currentPage === totalPages}>
          <FaChevronRight />
        </button>
      </div>
    </div>
  );
};

export default PaginationBar;
