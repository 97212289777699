import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import customFetch from "../../utils/axios";
import { getUserFromLocalStorage } from "../../utils/localStorage";
import { logoutUser } from "../user/UserSlice";

const initialState = {
  isLoading: false,
  status: null,
  error: null,
  uploadedFile: null,
  userData: {},
  allUsers: [],
  pageSize: 10,
  totalPages: 1,
  currentPage: 1,
};

//GET API
export const fetchUser = createAsyncThunk(
  "userdata/fetchUser",
  async (queryParams, thunkAPI) => {
    console.log(queryParams);
    const { accessToken } = getUserFromLocalStorage();
    try {
      const resp = await customFetch.get("api/profile", {
        params: { pageNo: queryParams?.page, pageSize: queryParams?.size },
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      });

      return resp?.data;
    } catch (error) {
      if (error) {
        alert("An error occured!");
      }
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

//POST API
export const fetchUserFilter = createAsyncThunk(
  "userdata/fetchUserFilter",
  async (query, thunkAPI) => {
    const { accessToken } = getUserFromLocalStorage();
    try {
      const resp = await customFetch.post("api/profile/filter", query, {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      });
      console.log(resp);
      return resp?.data;
    } catch (error) {
      if (error) {
        alert("An error occured!");
      }
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const fetchProfile = createAsyncThunk(
  "userprofile/fetchUserProfile",
  async (userId, thunkAPI) => {
    let { accessToken } = getUserFromLocalStorage();

    try {
      const response = await customFetch.get(`api/profile/${userId}`, {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      });

      if (response) {
        return response.data;
      }
    } catch (error) {
      if (error.response.status === 401) {
        alert("Error Occured");
        thunkAPI.dispatch(logoutUser());
        return thunkAPI.rejectWithValue("Unauthorization! Logging out... ");
      }
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const editProfile = createAsyncThunk(
  "userprofile/editProfile",
  async (profileData, thunkAPI) => {
    let { userId, accessToken } = getUserFromLocalStorage();
    try {
      const resp = await customFetch.put(`api/profile/${userId}`, profileData, {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      });
      if (resp.status === 200) {
        alert("Profile successfully updated");
        return {
          data: resp.data,
          status: resp.status,
          statusText: resp.statusText,
          headers: resp.headers,
          config: resp.config,
        };
      }
    } catch (error) {
      if (error.response.status === "401") {
        alert("Access denied");
      }
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const setPassword = createAsyncThunk(
  "profile/setpassword",
  async (data, thunkAPI) => {
    const { accessToken } = getUserFromLocalStorage();
    try {
      const resp = await customFetch.post("api/profile/password/change", data, {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      });
      console.log(resp);
      return resp.data;
    } catch (error) {
      if (error.response) {
        alert("Incorrect Password Entered!");
      }
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// Import the function to get the access token from localStorage

export const uploadFile = createAsyncThunk(
  "profile/uploadfile",
  async (file, thunkAPI) => {
    // Get the access token from localStorage
    const { accessToken } = getUserFromLocalStorage();

    try {
      // const formData = new FormData();
      // formData.append("file", file);
      // console.log(formData);
      const resp = await customFetch.post("api/file/upload", file, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (resp) {
        // Successful upload, you can handle the response here if needed.
        console.log(resp.data);
        return resp.data;
      } else {
        // Handle other status codes or errors
        return thunkAPI.rejectWithValue(resp.data);
      }
    } catch (error) {
      if (error.response) {
        alert("Invalid Credentials!");
      }
      // Handle network errors, timeouts, etc.
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const updateImage = createAsyncThunk(
  "profile/updateimage",
  async (data, thunkAPI) => {
    console.log(data);
    const { userId, accessToken } = getUserFromLocalStorage();
    try {
      const resp = await customFetch.patch(
        `api/profile/update-url/${userId}`,
        data,
        {
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return resp.data;
    } catch (error) {
      if (error.response) alert("Invalid credentials!");
      return thunkAPI.rejectWithValue(error.response.msg);
    }
  }
);
const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    clearFile: (state) => {
      state.uploadedFile = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProfile.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchProfile.fulfilled, (state, { payload }) => {
        const data = payload.content[0];
        console.log(data);
        state.userData = data;
        state.status = null;
        state.isLoading = false;
      })
      .addCase(fetchProfile.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(editProfile.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editProfile.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        console.log(payload);
        state.status = payload.status;
      })
      .addCase(editProfile.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(setPassword.fulfilled, (state, { payload }) => {
        alert("Password successfully updated!");

        state.status = 200;
      }) // saurabh
      .addCase(uploadFile.pending, (state) => {
        state.status = "loading";
      })
      .addCase(uploadFile.fulfilled, (state, action) => {
        state.status = "succeeded";
        alert("Upload Successful");
        state.uploadedFile = action.payload;
      })
      .addCase(uploadFile.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(updateImage.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateImage.fulfilled, (state, action) => {
        state.status = "succeeded";
        alert("update Successful");
      })
      .addCase(updateImage.rejected, (state, action) => {
        state.status = "failed";
      })
      .addCase(fetchUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchUser.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.pageSize = payload?.pageSize || 10;
        state.totalPages = payload?.totalPages || 1;
        state.currentPage = payload?.currentPage || 1;
        state.allUsers = payload?.content;
      })
      .addCase(fetchUser.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(fetchUserFilter.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchUserFilter.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.pageSize = payload?.pageSize || 10;
        state.totalPages = payload?.totalPages || 1;
        state.currentPage = payload?.currentPage || 1;
        state.allUsers = payload;
      })
      .addCase(fetchUserFilter.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export default profileSlice.reducer;
export const { clearFile } = profileSlice.actions;
