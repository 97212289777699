import React, { useState } from "react";
import { AiOutlineClose, AiOutlineArrowRight } from "react-icons/ai";

const SlotOption = ({ handleDataChange, bookingState }) => {
  const thirdDate = new Date();
  const hourNow = thirdDate.getHours();
  console.log(hourNow);
  thirdDate.setDate(thirdDate.getDate() + 2);

  const [selectedDay, setSelectedDay] = useState(null);
  const [selectedHour, setSelectedHour] = useState(null);
  const handleDaySelect = (day) => {
    if (day !== selectedDay) {
      setSelectedHour(null);
      handleDataChange({ ...bookingState, bookingSlot: null });
    }
    setSelectedDay(day);
  };
  const handleTimeSelect = (time) => {
    if (hourNow + 1 >= time && selectedDay === 1) return;
    setSelectedHour(time);
    const date = new Date();
    date.setDate(date.getDate() + selectedDay);
    date.setHours(time);
    handleDataChange({ ...bookingState, bookingSlot: date.toISOString() });
  };
  // console.log(currentDateTime.toString().slice(0, 10));
  return (
    <div className="py-4 w-11/12 flex flex-col gap-2 items-center">
      <div className="flex  items-center w-full  ">
        <h1 className=" text-xl capitalize font-semibold">Select Slot</h1>
      </div>
      {/* slots */}
      <div
        className={` flex   items-center justify-center gap-8  font-mono ${
          selectedDay ? " w-full" : " flex-col w-2/3"
        }`}>
        <div
          className={`uppercase   py-2 px-4  font-semibold rounded-xl w-full text-center bg-gradient-to-bl border border-black cursor-pointer min-w-max  ${
            selectedDay === 1 ? " bg-black text-white" : ""
          }`}
          onClick={() => handleDaySelect(1)}>
          Today
        </div>
        <div
          className={`uppercase   py-2 px-4  font-semibold rounded-xl w-full text-center bg-gradient-to-bl border border-black cursor-pointer min-w-max  ${
            selectedDay === 2 ? " bg-black text-white" : ""
          }`}
          onClick={() => handleDaySelect(2)}>
          Tommorow
        </div>
        <div
          className={`uppercase   py-2 px-4  font-semibold rounded-xl w-full text-center bg-gradient-to-bl border border-black cursor-pointer min-w-max  ${
            selectedDay === 3 ? " bg-black text-white" : ""
          }`}
          onClick={() => handleDaySelect(3)}>
          {thirdDate?.toDateString()?.slice(0, -4)}
        </div>
      </div>

      {selectedDay > 0 && (
        <div className="w-11/12 flex flex-col gap-4 expand">
          <div className="text-sm font-semibold  text-gray-400">Morning</div>
          <div className="grid gap-8 grid-cols-3">
            <span
              className={
                "p-2 border rounded-md min-w-max  text-center " +
                (hourNow + 1 >= 9 && selectedDay === 1
                  ? " bg-gray-200 border-gray-400 text-gray-400 cursor-not-allowed"
                  : selectedHour === 9
                  ? " border-black cursor-pointer bg-black text-white"
                  : " border-black cursor-pointer")
              }
              onClick={() => handleTimeSelect(9)}>
              09:00-10:00
            </span>
            <span
              className={
                "p-2 border rounded-md min-w-max  text-center " +
                (hourNow + 1 >= 10 && selectedDay === 1
                  ? " bg-gray-200 border-gray-400 text-gray-400 cursor-not-allowed"
                  : selectedHour === 10
                  ? " border-black cursor-pointer bg-black text-white"
                  : " border-black cursor-pointer")
              }
              onClick={() => handleTimeSelect(10)}>
              10:00-11:00
            </span>
            <span
              className={
                "p-2 border rounded-md min-w-max  text-center " +
                (hourNow + 1 >= 11 && selectedDay === 1
                  ? " bg-gray-200 border-gray-400 text-gray-400 cursor-not-allowed"
                  : selectedHour === 11
                  ? " border-black cursor-pointer bg-black text-white"
                  : " border-black cursor-pointer")
              }
              onClick={() => handleTimeSelect(11)}>
              11:00-12:00
            </span>
          </div>

          <div className="text-sm font-semibold  text-gray-400 mt-1">
            Afternoon
          </div>
          <div className="grid gap-8 grid-cols-3">
            <span
              className={
                "p-2 border rounded-md min-w-max  text-center " +
                (hourNow + 1 >= 12 && selectedDay === 1
                  ? " bg-gray-200 border-gray-400 text-gray-400 cursor-not-allowed"
                  : selectedHour === 12
                  ? " border-black cursor-pointer bg-black text-white"
                  : " border-black cursor-pointer")
              }
              onClick={() => handleTimeSelect(12)}>
              12:00-01:00
            </span>
            <span
              className={
                "p-2 border rounded-md min-w-max  text-center " +
                (hourNow + 1 >= 13 && selectedDay === 1
                  ? " bg-gray-200 border-gray-400 text-gray-400 cursor-not-allowed"
                  : selectedHour === 13
                  ? " border-black cursor-pointer bg-black text-white"
                  : " border-black cursor-pointer")
              }
              onClick={() => handleTimeSelect(13)}>
              01:00-02:00
            </span>
            <span
              className={
                "p-2 border rounded-md min-w-max  text-center " +
                (hourNow + 1 >= 14 && selectedDay === 1
                  ? " bg-gray-200 border-gray-400 text-gray-400 cursor-not-allowed"
                  : selectedHour === 14
                  ? " border-black cursor-pointer bg-black text-white"
                  : " border-black cursor-pointer")
              }
              onClick={() => handleTimeSelect(14)}>
              02:00-03:00
            </span>
          </div>

          <div className="text-sm font-semibold  text-gray-400 mt-1">
            Evening
          </div>
          <div className="grid gap-8 grid-cols-3">
            <span
              className={
                "p-2 border rounded-md min-w-max  text-center " +
                (hourNow + 1 >= 15 && selectedDay === 1
                  ? " bg-gray-200 border-gray-400 text-gray-400 cursor-not-allowed"
                  : selectedHour === 15
                  ? " border-black cursor-pointer bg-black text-white"
                  : " border-black cursor-pointer")
              }
              onClick={() => handleTimeSelect(15)}>
              03:00-04:00
            </span>
            <span
              className={
                "p-2 border rounded-md min-w-max  text-center " +
                (hourNow + 1 >= 16 && selectedDay === 1
                  ? " bg-gray-200 border-gray-400 text-gray-400 cursor-not-allowed"
                  : selectedHour === 16
                  ? " border-black cursor-pointer bg-black text-white"
                  : " border-black cursor-pointer")
              }
              onClick={() => handleTimeSelect(16)}>
              04:00-05:00
            </span>
            <span
              className={
                "p-2 border rounded-md min-w-max  text-center " +
                (hourNow + 1 >= 17 && selectedDay === 1
                  ? " bg-gray-200 border-gray-400 text-gray-400 cursor-not-allowed"
                  : selectedHour === 17
                  ? " border-black cursor-pointer bg-black text-white"
                  : " border-black cursor-pointer")
              }
              onClick={() => handleTimeSelect(17)}>
              05:00-06:00
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default SlotOption;
