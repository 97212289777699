import React, { useEffect, useRef, useState } from "react";
import { clearFile, uploadFile } from "../features/Files/FilesSlice";
import { useDispatch, useSelector } from "react-redux";
import { IoAdd } from "react-icons/io5";

const UploadFileTemp = ({ handleUploaded, name, data, text }) => {
  const dispatch = useDispatch();

  const [image, setImage] = useState(null);
  const [uploading, setUploading] = useState(null);
  const inputRef0 = useRef(null);

  const { isLoading, uploadUrl } = useSelector((store) => store?.file);

  const handleImgClick = () => {
    inputRef0.current.click();
  };
  const handleImageChange = async (e) => {
    const getImage = e.target.files[0];

    if (!getImage) {
      return;
    }

    setImage({
      ...image,
      file: getImage,
      filepreview: URL.createObjectURL(getImage),
    });
  };

  const handleUpload = () => {
    const fileData = new FormData();
    setUploading(name);

    if (image?.file) {
      fileData.append("file", image.file, image?.file?.name);
      dispatch(uploadFile(fileData));
    } else {
      alert("Nothing to upload!");
    }
  };

  useEffect(() => {
    if (uploading === name && uploadUrl) {
      handleUploaded(name, uploadUrl);
    }
    setUploading(null);
    dispatch(clearFile());
  }, [uploadUrl]);

  return (
    <div className="w-[200px] mx-auto my-10 relative flex flex-col items-center justify-center gap-2 shadow p-2 pt-3 border-t">
      {image || data?.[name] ? (
        <img
          src={image?.filepreview || data?.[name]}
          alt="Catagory Icon"
          className="h-24 w-24 object-cover bg-gray-50 text-xs cursor-pointer"
        />
      ) : (
        <div
          className="h-24 w-24  border-2 border-dashed text-gray-300 font-semibold font-mono text-xl cursor-pointer flex flex-col items-center justify-center"
          onClick={() => handleImgClick()}>
          <IoAdd className="text-3xl" />
          <span>Image</span>
        </div>
      )}
      <span className="flex w-full gap-1">
        <button
          type="button"
          className="bg-gray-100 w-1/2 p-1"
          onClick={() => handleImgClick()}
          disabled={isLoading}>
          Change
        </button>
        <button
          type="button"
          className="bg-[#eebf00] text-white w-1/2 p-1 "
          onClick={handleUpload}
          disabled={isLoading}>
          {isLoading && uploading === name ? (
            <span className="text-sm">Uploading...</span>
          ) : (
            "Upload"
          )}
        </button>
      </span>
      <input
        type="file"
        className="hidden  p-4"
        placeholder="showCategory"
        ref={inputRef0}
        onChange={handleImageChange}
      />
      <span className="absolute -top-2 bg-white px-1 text-gray-400 text-xs left-2 capitalize">
        {text}
      </span>
    </div>
  );
};

export default UploadFileTemp;
