import React from "react";
import { useState } from "react";
import { RxCross1 } from "react-icons/rx";

const GetCode = ({ question, handleCode }) => {
  const [isCorrect, setCorrect] = useState("");

  const handleChange = (e) => {
    const value = e?.target?.value;
    if (value?.toString() === "claPurbaN") {
      setCorrect("correct");
    }
  };
  return (
    <div className="fixed w-screen h-screen flex items-center justify-center z-50 bg-[#0000002f] top-0 ">
      <div className="bg-white rounded-xl flex flex-col items-center justify-between py-8 px-12 w-[20vw] min-w-max  min-h-max gap-10 tracking-wide popup relative">
        <div className="text-xl font-ligh ">{question}</div>
        <div className="flex justify-between ">
          <input
            type="text"
            placeholder="XXXXXX"
            onChange={handleChange}
            className="w-2/3 px-4 border "
          />
          <button
            className="bg-black text-white p-4 rounded-md"
            onClick={() => handleCode(isCorrect)}>
            SUBMIT
          </button>
        </div>

        {/* close */}
        <RxCross1
          className="absolute right-0 -top-10 p-1 bg-white rounded-full cursor-pointer text-3xl"
          onClick={() => handleCode("close")}
        />
      </div>
    </div>
  );
};

export default GetCode;
