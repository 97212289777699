import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import customFetch from "../../utils/axios";
import { getUserFromLocalStorage } from "../../utils/localStorage";
import { data } from "autoprefixer";

const initialState = {
  isLoadingIssue: false,
  issuesList: [],
  subCategoryDetails: {},
};

// Get API
export const fetchIssue = createAsyncThunk(
  "issue/fetchIssue",
  async (subCategoryId, thunkAPI) => {
    const { accessToken } = getUserFromLocalStorage();
    console.log(subCategoryId);
    try {
      const resp = await customFetch.get(
        `api/issue/sub-category/${subCategoryId}`,
        {
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        }
      );
      console.log(resp.data);
      return resp.data;
    } catch (error) {
      if (error) {
        alert("Error Occured!");
      }
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Post API
export const postIssue = createAsyncThunk(
  "issue/postIssue",
  async ({ subCategoryId, data }, thunkAPI) => {
    const { accessToken } = getUserFromLocalStorage();
    try {
      const resp = await customFetch.post(`api/issue/${subCategoryId}`, data, {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      });
      return resp.data;
    } catch (error) {
      if (error) {
        alert("Error Occured!");
      }
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// PUT API
export const updateIssue = createAsyncThunk(
  "issue/updatesuncategory",
  async ({ issueId, data }, thunkAPI) => {
    console.log(data);
    const { accessToken } = getUserFromLocalStorage();
    try {
      const resp = await customFetch.put(`api/issue/${issueId}`, data, {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      });
      return resp.data;
    } catch (error) {
      if (error) {
        alert("Error Occured While Updating issue!");
      }
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
const IssuesSlice = createSlice({
  name: "issue",
  initialState,
  reducers: {
    setLoadingIssue: (state) => {
      state.isLoadingIssue = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchIssue.pending, (state) => {
        state.isLoadingIssue = true;
      })
      .addCase(fetchIssue.fulfilled, (state, { payload }) => {
        state.isLoadingIssue = false;
        state.subCategoryDetails = Object.fromEntries(
          Object.entries(payload?.content?.slice(0, 1)[0])?.filter(
            ([key]) => key !== "issues"
          )
        );
        state.issuesList = payload?.content?.slice(0, 1)[0]?.issues;
      })
      .addCase(fetchIssue.rejected, (state) => {
        state.isLoadingIssue = false;
      })
      .addCase(postIssue.pending, (state) => {
        state.isLoadingIssue = true;
      })
      .addCase(postIssue.fulfilled, (state, { payload }) => {
        alert("Issue Added Successfully!");
        state.isLoadingIssue = false;
      })
      .addCase(postIssue.rejected, (state) => {
        state.isLoadingIssue = false;
      })
      .addCase(updateIssue.pending, (state) => {
        state.isLoadingIssue = true;
      })
      .addCase(updateIssue.fulfilled, (state, { payload }) => {
        alert("Issue Updated Successfully!");
        state.isLoadingIssue = false;
      })
      .addCase(updateIssue.rejected, (state) => {
        state.isLoadingIssue = false;
      });
  },
});
export default IssuesSlice.reducer;
export const { setLoadingIssue } = IssuesSlice.actions;
