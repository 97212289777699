import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import customFetch from "../../utils/axios";
import { getUserFromLocalStorage } from "../../utils/localStorage";

const initialState = {
  homeData: [],
  isLoadingHome: false,
  homeSubSection: [],
};

//GET API
export const fetchHome = createAsyncThunk(
  "home/fetchHome",
  async (thunkAPI) => {
    const { accessToken } = getUserFromLocalStorage();
    try {
      const resp = await customFetch.get("api/home", {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      });

      console.log(resp);
      return resp?.data;
    } catch (error) {
      if (error) {
        console.log(error);
      }
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

//GET API for home sub section
export const fetchSubHome = createAsyncThunk(
  "home/fetchSubHome",
  async (homeId, thunkAPI) => {
    const { accessToken } = getUserFromLocalStorage();
    try {
      const resp = await customFetch.get(`api/home/sub-section/${homeId}`, {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      });
      console.log(resp);
      return resp?.data;
    } catch (error) {
      if (error) {
        alert("Error fetching data");
      }
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// POST API
export const addHome = createAsyncThunk(
  "home/addHome",
  async (data, thunkAPI) => {
    const { accessToken } = getUserFromLocalStorage();
    console.log(accessToken);
    try {
      const resp = await customFetch.post("api/home", data, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      console.log(resp);
      return resp?.data;
    } catch (error) {
      if (error) {
        alert("Error Occured!");
      }
      console.log(error);
      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);
// put API
export const editHome = createAsyncThunk(
  "home/editHome",
  async ({ homeId, data }, thunkAPI) => {
    console.log(data);
    const { accessToken } = getUserFromLocalStorage();
    try {
      const resp = await customFetch.post(`api/home/${homeId}`, data, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      console.log(resp);
      thunkAPI.dispatch(fetchHome());
      return resp?.data;
    } catch (error) {
      if (error) {
        alert("Error Occured!");
      }
      console.log(error);
      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

// POST API for home sub section
export const addHomeSubSection = createAsyncThunk(
  "home/addHomeSubSection",
  async ({ homeId, data }, thunkAPI) => {
    console.log(data);
    const { accessToken } = getUserFromLocalStorage();
    try {
      const resp = await customFetch.post(
        `api/home/sub-section/${homeId}`,
        data,
        {
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return resp?.data;
    } catch (error) {
      if (error) {
        alert("Error Occured!");
      }
      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

//PUT Api for home
export const editHomeSection = createAsyncThunk(
  "home/editHomeSection",
  async ({ id, data }, thunkAPI) => {
    console.log(data);
    const { accessToken } = getUserFromLocalStorage();
    try {
      const resp = await customFetch.put(`api/home/${id}`, data, {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      });
      thunkAPI.dispatch(fetchHome());
      return resp?.data;
    } catch (error) {
      if (error) {
        alert("Some error occurred in editing the name...!");
      }
      return thunkAPI.rejectWithValue(error?.response?.message);
    }
  }
);

//PUT Api for home
export const editHomeSubSection = createAsyncThunk(
  "home/editHomeSubSection",
  async ({ subSectionId, homeId, data }, thunkAPI) => {
    console.log(data);
    const { accessToken } = getUserFromLocalStorage();
    try {
      const resp = await customFetch.put(
        `/api/home/sub-section/${subSectionId}`,
        data,
        {
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        }
      );
      thunkAPI.dispatch(fetchSubHome(homeId));
      return resp?.data;
    } catch (error) {
      if (error) {
        alert("Some error occurred in editing the name...!");
      }
      return thunkAPI.rejectWithValue(error?.response?.message);
    }
  }
);

//hide home
export const hideHomeSection = createAsyncThunk(
  "home/hideHomeSection",
  async (id, thunkAPI) => {
    const { accessToken } = getUserFromLocalStorage();
    try {
      const resp = await customFetch.get(`/api/home/incomplete/${id}`, {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      });
      thunkAPI.dispatch(fetchHome());
      return resp?.data;
    } catch (error) {
      if (error) {
        alert("Some error occurred in editing the name...!");
      }
      return thunkAPI.rejectWithValue(error?.response?.message);
    }
  }
);

//show home
export const showHomeSection = createAsyncThunk(
  "home/showHomeSection",
  async (id, thunkAPI) => {
    const { accessToken } = getUserFromLocalStorage();
    try {
      const resp = await customFetch.get(`/api/home/complete/${id}`, {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      });
      thunkAPI.dispatch(fetchHome());
      return resp?.data;
    } catch (error) {
      if (error) {
        alert("Some error occurred in editing the name...!");
      }
      return thunkAPI.rejectWithValue(error?.response?.message);
    }
  }
);

const homeSlice = createSlice({
  name: "home",
  initialState,

  extraReducers: (builder) => {
    builder

      .addCase(fetchHome.pending, (state) => {
        state.isLoadingHome = true;
      })

      .addCase(fetchHome.fulfilled, (state, { payload }) => {
        state.isLoadingHome = false;
        state.homeData = payload;
      })

      .addCase(fetchHome.rejected, (state) => {
        state.isLoadingHome = false;
        alert("Unauthorized Data");
      })

      .addCase(fetchSubHome.pending, (state) => {
        state.isLoadingHome = true;
      })

      .addCase(fetchSubHome.fulfilled, (state, { payload }) => {
        state.isLoadingHome = false;
        console.log(payload);
        state.homeSubSection = payload.content[0]?.homeSubSections;
      })

      .addCase(fetchSubHome.rejected, (state) => {
        state.isLoadingHome = false;
      })

      .addCase(addHome.pending, (state) => {
        state.isLoadingHome = true;
      })

      .addCase(addHome.fulfilled, (state, { payload }) => {
        state.isLoadingHome = false;
        // state.homeSubSection = payload.content[0]?.homeSubSections;
        console.log(payload);
        alert("Section added successfully");
      })

      .addCase(addHome.rejected, (state) => {
        state.isLoadingHome = false;
      })

      .addCase(addHomeSubSection.pending, (state) => {
        state.isLoadingHome = true;
      })
      .addCase(addHomeSubSection.fulfilled, (state, { payload }) => {
        state.isLoadingHome = false;

        alert("Section added successfully");
      })
      .addCase(addHomeSubSection.rejected, (state) => {
        state.isLoadingHome = false;
      })
      .addCase(editHomeSection.fulfilled, (state) => {
        state.isLoadingHome = false;
        alert("Edited Succesfully!");
      })
      .addCase(editHomeSection.pending, (state) => {
        state.isLoadingHome = true;
      })
      .addCase(editHomeSection.rejected, (state) => {
        state.isLoadingHome = false;
        alert("Error Occured while editing!");
      })
      .addCase(editHomeSubSection.fulfilled, (state) => {
        state.isLoadingHome = false;
        alert("Edited Succesfully!");
      })
      .addCase(editHomeSubSection.pending, (state) => {
        state.isLoadingHome = true;
      })
      .addCase(editHomeSubSection.rejected, (state) => {
        state.isLoadingHome = false;
        alert("Error Occured while editing!");
      })
      .addCase(hideHomeSection.fulfilled, (state) => {
        state.isLoadingHome = false;
      })
      .addCase(hideHomeSection.pending, (state) => {
        state.isLoadingHome = true;
      })
      .addCase(hideHomeSection.rejected, (state) => {
        state.isLoadingHome = false;
        alert("Error!!");
      })
      .addCase(showHomeSection.fulfilled, (state) => {
        state.isLoadingHome = false;
      })
      .addCase(showHomeSection.pending, (state) => {
        state.isLoadingHome = true;
      })
      .addCase(showHomeSection.rejected, (state) => {
        state.isLoadingHome = false;
        alert("Error!!");
      });
  },
});

export default homeSlice.reducer;
