import React from "react";
import Navigation from "../../components/Navigation";
import { Outlet } from "react-router-dom";
import Footer from "../../components/Footer";
import Sidebar from "../../components/Sidebar";
import Home from "./Home";
import { Toaster } from "sonner";
import { useAxiosInterceptors } from "../../utils/axios";

const SharedLayout = () => {
  useAxiosInterceptors();
  return (
    <div>
      <Toaster position="top-center" reverseOrder={false} richColors />
      <div>
        <div className="flex bg-pale">
          <Sidebar />
          <div className="w-full ">
            {/* <Navigation /> */}
            <Outlet />
          </div>
        </div>
        {/* <Footer /> */}
      </div>
    </div>
  );
};

export default SharedLayout;
