import React, { useState } from "react";
import { IoIosArrowDown } from "react-icons/io";

import { BsSearch } from "react-icons/bs";

import AddNew from "../../components/AddNew";
import FSDropdown from "../../components/FSDropdown";
import BannerDropdown from "../../components/BannerDropdown";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchHome } from "../../features/Home/homeSlice";

const HomeLayout = () => {
  const { homeData } = useSelector((store) => store.home);
  const [completedVisible, setCompletedVisible] = useState(false);
  const [completedVisibleBanner, setCompletedVisibleBanner] = useState(false);
  const dispatch = useDispatch();


  // homeData.ids?.forEach(id => {
  //   if (id.completed) {
  //     console.log("ID is completed:", id);
  //     setCompletedVisible(true);
  //     // setCompletedVisibleBanner(false);
  //   }
  //   else{
  //     setCompletedVisible(false);
  //     // setCompletedVisibleBanner(false);
  //   }
  // });
  // useEffect(() => {
  //   if (homeData?.ids && Array.isArray(homeData.ids)) {
  //     const hasCompletedId = homeData.ids.some((id) => id.completed);
  //     console.log("At least one ID is completed:", homeData.ids.filter((id) => id.completed));
  //     setCompletedVisible(hasCompletedId);
  //   } else {
  //     setCompletedVisible(false);
  //   }
  // },[homeData.ids]);
  useEffect(() => {
    if (homeData) {
      const hasCompleted = homeData.some((item) => item.completed && !item.bannerImgUrl);
      const hasCompletedBanner = homeData.some((item) => item.completed && item.bannerImgUrl);

      setCompletedVisible(hasCompleted);
      setCompletedVisibleBanner(hasCompletedBanner);
    }
  }, [homeData]);

  const [isShown, setShown] = useState(false);
  const handleChange = (index) => {
    if (isShown === index) {
      setShown(null);
    } else {
      setShown(index);
    }

  };

  const [isnew, setNew] = useState(false);
  const handleNew = () => {
    setNew(!isnew);
  };

  useEffect(() => {
    dispatch(fetchHome());
    window.scrollTo(0, 0);
  }, []);
  return (
    // maindiv

    <div className="px-4">
      {/* // popup --> */}
      {isnew && <AddNew handleAdding={handleNew} />}

      <div className="flex gap-5 h-20 mt-5">
        <div className="flex justify-start items-center w-2/3 ">
          {/* Search Tab */}
          <div className="flex  w-1/3 h-11 rounded-lg  px-4 bg-sky-100">
            <BsSearch className="my-4 font-extrabold" />
            <input
              type="Search"
              placeholder="Search..."
              className="pl-3 pr-1/2 w-full bg-sky-100 outline-none"
            />
          </div>
        </div>

        {/* add new  */}
        <div className="relative flex w-1/3 h-20 m-2 justify-end items-center">
          <div
            className="flex justify-center cursor-pointer items-center w-48 h-10 text-lg font-mono bg-secondary font-bold rounded hover:scale-105 text-white bg-primary-600"
            onClick={handleNew}>
            Add new
          </div>
        </div>
      </div>

      {/* div containing featured service, etc. dropdowns  */}

      <div className="flex flex-col  py-4 my-4 ">
        {homeData?.map((elem, index) => {
          return (
            <div>
              {elem?.bannerImgUrl === null ||
              elem?.bannerImgUrl?.trim() === "" ? (
                <div key={index}>
                  <div
                    // className={`flex flex-row border-2 px-3 py-2 rounded-md ${completedVisible ? "bg-sky-950" : " bg-gray-600"}  text-sky-100`}
                    className={`flex flex-row border-2 px-3 py-2 rounded-md ${
                      elem.completed
                        ? elem.bannerImgUrl
                          ? completedVisibleBanner
                            ? "bg-sky-950"
                            : "bg-gray-600"
                          : completedVisible
                          ? "bg-sky-950"
                          : "bg-gray-600"
                        : "bg-gray-600"
                    } text-sky-100`}
                    onClick={() => handleChange(index)}>
                    <div className="w-3/4 px-3 text-xl font-mono font-extrabold cursor-pointer">
                      {elem?.title}
                    </div>
                    <div
                      className={` ${
                        isShown === index
                          ? "flex w-1/4  rotate-180 cursor-pointer"
                          : "flex items-start justify-end w-1/4 cursor-pointer"
                      }`}>
                      <IoIosArrowDown className="text-3xl" />
                    </div>
                  </div>
                  <div>
                    {isShown === index && (
                      <FSDropdown
                        homeId={elem?.id}
                        isVisible={elem?.completed}
                      />
                    )}
                  </div>
                </div>
              ) : (
                <div key={index}>
                  <div
                  key={index}
                    // className={`flex flex-row border-2 px-3 py-2 rounded-md ${completedVisibleBanner ? "bg-sky-950" : " bg-gray-600"}  text-sky-100`}
                    className={`flex flex-row border-2 px-3 py-2 rounded-md ${
                      elem.completed
                        ? elem.bannerImgUrl
                          ? completedVisibleBanner
                            ? "bg-sky-950"
                            : "bg-gray-600"
                          : completedVisible
                          ? "bg-sky-950"
                          : "bg-gray-600"
                        : "bg-gray-600"
                    } text-sky-100`}
                    onClick={() => handleChange(index)}>
                    <div className="w-3/4  px-3 text-xl font-mono font-extrabold cursor-pointer">
                      {elem?.title}
                    </div>
                    <div
                      className={`handleBannerDrop ${
                        isShown === index
                          ? "flex w-1/4 rotate-180 cursor-pointer"
                          : "flex items-start justify-end w-1/4  cursor-pointer"
                      }`}>
                      <IoIosArrowDown className="text-3xl" />
                    </div>
                  </div>
                  <div>
                    {isShown === index && (
                      <BannerDropdown
                        image={elem?.bannerImgUrl}
                        homeData={elem}
                        isVisible={elem?.completed}
                        homeId={elem?.id}
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default HomeLayout;
