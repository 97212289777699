export const addUserToLocalStorage = (user) => {
  localStorage.setItem("user", JSON.stringify(user));
};

export const removeUserFromLocalStorage = () => {
  localStorage.removeItem("user");
};

export const getUserFromLocalStorage = () => {
  const result = localStorage.getItem("user");
  const user = result ? JSON.parse(result) : null;

  return user;
};

export const addPartnerToLocalStorage = (user) => {
  localStorage.setItem("partner", JSON.stringify(user));
};

export const removePartnerFromLocalStorage = () => {
  localStorage.removeItem("partner");
};

export const getPartnerFromLocalStorage = () => {
  const result = localStorage.getItem("partner");
  const partner = result ? JSON.parse(result) : null;
  return partner;
};

// export const getCompanyFromLocalStorage = () => {
//   const result = localStorage.getItem("partner");
//   const partner = result ? JSON.parse(result) : null;
//   return partner;
// }