import React, { useRef, useState } from "react";
import { RxCross1 } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import {
  clearFile,
  setLoading,
  uploadFile,
} from "../features/Files/FilesSlice";
import { IoAdd } from "react-icons/io5";
import { useEffect } from "react";
import {
  getCatagories,
  postCatagory,
  setLoadingCatg,
} from "../features/Catagory/CatagorySlice";
import {
  fetchSubCategory,
  postSubCategory,
  setLoadingSubCatg,
} from "../features/SubCategory/SubCategorySlice";
import {
  fetchIssue,
  postIssue,
  setLoadingIssue,
  updateIssue,
} from "../features/Issue/IssuesSlice";
import UploadFileTemp from "./UploadFileTemp";

const EditIssue = ({ handleAdding, subCatagoryId, handleEditing, edit }) => {
  //   const initialState = {
  //     issueName: "",
  //     issueReview: "3000",
  //     issueRating: "3.6",
  //     issueDuration: "",
  //     issuePrice: 0,
  //     issueIconUrl: "",
  //     issueDescription: "",
  //   };

  const { isLoading, uploadUrl } = useSelector((state) => state.file);

  const { isLoadingSubCatg } = useSelector((state) => state.subcategory);
  const dispatch = useDispatch();
  const [issue, setIssue] = useState(edit);

  console.log(issue);

  const [image, setImage] = useState(null);
  const inputRef = useRef(null);
  const handleImgClick = () => {
    inputRef.current.click();
  };

  const handleImageChange = async (e) => {
    const getImage = e.target.files[0];
    if (!getImage) {
      return;
    }
    setImage({
      ...image,
      file: getImage,
      filepreview: URL.createObjectURL(getImage),
    });
  };

  // To capitalize String
  const capitalizeFirstLetter = (inputString) => {
    if (inputString.length === 0) {
      return inputString;
    }

    return inputString.charAt(0).toUpperCase() + inputString.slice(1);
  };
  const handleChange = (e) => {
    const name = e.target.name;
    const value = capitalizeFirstLetter(e.target.value);
    setIssue({ ...issue, [name]: value });
  };

  const handleUpload = (name, url) => {
    if (url != null && url?.trim() != "") {
      setIssue({ ...issue, [name]: url });
    }
  };
  const clearState = () => {
    dispatch(clearFile());
    handleEditing();
  };

  const handleSubmit = () => {
    const {
      id,
      issueName,
      issueReview,
      issueRating,
      issueDuration,
      issuePrice,
      issueIconUrl,
      appIssueIconUrl,
    } = issue;
    if (issueName === "" || issueDuration === "" || issuePrice === "") {
      alert("Please Name of Sub Category");
      return;
    }
    dispatch(
      updateIssue({
        issueId: id,
        data: {
          issueName: issueName,
          issueReview: issueReview,
          issueRating: issueRating,
          issueDuration: issueDuration,
          issuePrice: issuePrice,
          issueIconUrl: issueIconUrl,
          appIssueIconUrl: appIssueIconUrl,
        },
      })
    );

    setTimeout(() => {
      dispatch(fetchIssue(subCatagoryId));
      clearState();
    }, 2000);
  };

  useEffect(() => {
    dispatch(setLoadingIssue());
    dispatch(setLoading(false));
    dispatch(setLoadingSubCatg());
  }, []);
  return (
    <div className="fixed w-full h-screen bg-[#00000094] z-40 flex items-center justify-center top-0 left-0">
      <div className="relative w-[450px] h-[65vh] bg-white flex items-center justify-center top-10 ">
        <RxCross1
          className="absolute right-0 -top-10 p-1 bg-white rounded-full cursor-pointer text-3xl"
          onClick={() => handleEditing()}
        />

        <div className="uppercase font-mono absolute top-0 py-3 text-center text-xl shadow-inner w-full ">
          Edit Issue
        </div>
        <div className="w-11/12 h-5/6 overflow-auto no-scrollbar">
          {/* Name of catagory */}
          <div className="w-full h-14 my-6 border relative">
            <input
              type="text"
              className="w-full h-full outline-none p-4"
              placeholder="Name"
              name="issueName"
              value={issue?.issueName}
              onChange={handleChange}
            />
            <span className="absolute -top-2 bg-white px-1 text-gray-400 text-xs left-4 capitalize">
              Issue Name
            </span>
          </div>
          {/* Review/ratings */}
          <div className="w-full h-14 flex gap-16 ">
            {/* review */}
            <div className="w-full h-full  border relative">
              <input
                type="text"
                className="w-full h-full outline-none p-4 opacity-70"
                placeholder="Review"
                name="issueReview"
                value={issue?.issueReview}
                onChange={handleChange}
                disabled
              />
              <span className="absolute -top-2 bg-white px-1 text-gray-400 text-xs left-4 capitalize">
                Reviews
              </span>
            </div>
            {/* Reating */}
            <div className="w-full h-full  border relative">
              <input
                type="text"
                className="w-full h-full outline-none p-4 opacity-70"
                placeholder="Rating"
                name="issueRating"
                value={issue?.issueRating}
                onChange={handleChange}
                disabled
              />
              <span className="absolute -top-2 bg-white px-1 text-gray-400 text-xs left-4 capitalize">
                Rating
              </span>
            </div>
          </div>
          {/* Issue Url */}
          <UploadFileTemp
            name={"issueIconUrl"}
            text={"Web Icon"}
            data={issue}
            handleUploaded={handleUpload}
          />
          <UploadFileTemp
            name={"appIssueIconUrl"}
            text={"App Icon"}
            data={issue}
            handleUploaded={handleUpload}
          />
          {/* description */}
          <div className="w-full h-max my-6 border relative">
            <textarea
              type="text"
              className="w-full  outline-none   text-sm   rounded-md   p-2 h-20"
              placeholder="Description"
              name="issueDescription"
              value={issue?.issueDescription}
              onChange={handleChange}
            />
            <span className="absolute -top-2 bg-white px-1 text-gray-400 text-xs left-4 capitalize">
              Description
            </span>
          </div>
          {/* price & Duration */}
          <div className="w-full h-14 flex gap-16 mb-8">
            {/* Price */}
            <div className="w-full h-full  border relative">
              <input
                type="text"
                className="w-full h-full outline-none p-4 opacity-70"
                placeholder="Price"
                name="issuePrice"
                value={issue?.issuePrice}
                onChange={handleChange}
              />
              <span className="absolute -top-2 bg-white px-1 text-gray-400 text-xs left-4 capitalize">
                Price
              </span>
            </div>
            {/* Duration */}
            <div className="w-full h-full  border relative">
              <input
                type="text"
                className="w-full h-full outline-none p-4 opacity-70"
                placeholder="Duration"
                name="issueDuration"
                value={issue?.issueDuration}
                onChange={handleChange}
              />
              <span className="absolute -top-2 bg-white px-1 text-gray-400 text-xs left-4 capitalize">
                Duration
              </span>
            </div>
          </div>
        </div>
        <div className="absolute bottom-0 w-full h-12 ">
          <button
            className="w-1/2 h-full uppercase bg-gray-200"
            onClick={clearState}>
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            className="w-1/2 h-full uppercase text-white bg-primary-max">
            {isLoadingSubCatg ? "Saving..." : "Save"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditIssue;
