import React, { useState, useEffect } from "react";
import { RxCross1 } from "react-icons/rx";
import { useDispatch } from "react-redux";
import { putFAQ } from "../../features/FAQ/FAQslice";

const EditFAQ = ({ handleClose, selectedId, FAQList }) => {
	const dispatch = useDispatch();

	// Find the selected FAQ details
	const selectedFAQ = FAQList.find((faq) => faq.id === selectedId);

	// State initialization with existing FAQ data
	const [isQuestion, setQuestion] = useState(selectedFAQ?.question || "");
	const [isAnswer, setAnswer] = useState(selectedFAQ?.ans || "");
	const [isSubCategory, setSubCategory] = useState(null); // Assume default is null unless you have existing sub-category
	const [isSelected, setSelected] = useState("");

	// Handlers for inputs
	const handleQuestion = (e) => {
		const ques = e.target.value;
		setQuestion(ques);
	};

	const handleAnswer = (e) => {
		const ans = e.target.value;
		setAnswer(ans);
	};

	const handleSubCategoryID = (e) => {
		const subCat = e.target.value;
		setSubCategory(subCat);
	};

	const handleSelect = (e) => {
		const opt = e.target.value;
		setSelected(opt);
	};

	// Handle form submission
	const handleAdd = (e) => {
		e.preventDefault();
		if (
			isQuestion === "" ||
			isAnswer === "" ||
			isSubCategory === null ||
			isSelected === ""
		) {
			alert("Please fill out all the fields");
		} else {
			// Dispatch the updated FAQ data
			dispatch(
				putFAQ({
					selectedId,
					data: { question: isQuestion, ans: isAnswer, context: isSelected },
				})
			);
			handleClose();
		}
	};

	return (
		<div className="fixed flex items-center justify-center top-0 bg-[#00000083] z-40 w-screen right-0 h-screen">
			<form
				className="flex flex-col relative h-[33rem] w-[35rem] rounded-xl bg-white"
				onSubmit={handleAdd}
			>
				<div className="absolute p-1 -top-7 -right-1 m-1 text-3xl font-bold bg-white rounded-3xl -my-4">
					<RxCross1
						className="cursor-pointer p-1 font-bold"
						onClick={handleClose}
					/>
				</div>
				<div className="flex justify-center items-center h-[75px] text-3xl font-mono font-bold">
					Edit FAQ
				</div>
				<div>
					<div className="overflow-auto mb-20">
						<div className="border border-black p-2 rounded-lg h-[50px] mx-6 mt-[1rem] relative">
							<span className="absolute -top-3 bg-white px-3 left-5 text-black font-semibold text-sm capitalize">
								Question
							</span>
							<input
								type="text"
								name="question"
								placeholder="Enter the question ?"
								className="w-full h-full px-4 outline-none text-sm font-semibold"
								value={isQuestion}
								onChange={handleQuestion}
							/>
						</div>
						<div className="border border-black px-2 rounded-lg mx-6 mt-10 relative">
							<span className="absolute -top-3 bg-white px-3 left-7 text-black font-semibold text-sm capitalize">
								Answer
							</span>
							<textarea
								name="answer"
								placeholder="Enter the answer for your question..."
								className="w-full py-[1rem] px-4 outline-none text-sm"
								value={isAnswer}
								onChange={handleAnswer}
							/>
						</div>
						<div className="flex mx-7 mt-10 gap-[2rem]">
							<div className="font-semibold">FAQ Type: </div>
							<select
								value={isSelected}
								onChange={handleSelect}
								className="flex items-center justify-center w-[9rem] text-sm py-[0.2rem] pl-[0.2rem] border-2 border-black rounded-lg"
							>
								<option>Select an option</option>
								<option value="Positive">Positive</option>
								<option value="Negative">Negative</option>
								<option value="Neutral">Neutral</option>
							</select>
						</div>
						<div className="flex mx-7 mt-8 gap-[2rem]">
							<div className="font-semibold">Sub-Category Id: </div>
							<input
								name=""
								type="number"
								placeholder="Xyz"
								className="flex items-center justify-center w-[12rem] text-sm py-[0.2rem] px-[0.8rem] border-2 border-black rounded-lg"
								value={isSubCategory}
								onChange={handleSubCategoryID}
							></input>
						</div>
					</div>
				</div>

				{/* Buttons */}
				<div className="flex flex-row absolute bottom-0 w-full h-[55px] rounded-b-xl overflow-hidden">
					<button
						type="button"
						className="flex w-1/2 justify-center items-center text-2xl bg-gray-300"
						onClick={handleClose}
					>
						Cancel
					</button>
					<button
						type="submit"
						className="flex w-1/2 justify-center items-center text-2xl bg-pink-500 text-white"
					>
						Add
					</button>
				</div>
			</form>
		</div>
	);
};

export default EditFAQ;
