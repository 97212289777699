import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import customFetch from "../../utils/axios";

import { getUserFromLocalStorage } from "../../utils/localStorage";
import { logoutUser } from "../user/UserSlice";

const initialState = {
  isLoadingAddress: false,
  addresses: [],
  allCity: [],
};
export const fetchAddresses = createAsyncThunk(
  "address/fetchAddresses",
  async (userId, thunkAPI) => {
    const { accessToken } = getUserFromLocalStorage();
    try {
      const resp = await customFetch.get(`/api/address/${userId}`, {
        headers: { authorization: `Bearer ${accessToken}` },
      });
      if (resp.data) {
        return resp.data;
      }
    } catch (error) {
      if (error.response.status === 401) {
        thunkAPI.dispatch(logoutUser());
        return thunkAPI.rejectWithValue("Unauthorization! Logging out... ");
      }
      return thunkAPI.rejectWithValue(error.response.data.msg);
    }
  }
);
export const AddAddress = createAsyncThunk(
  "address/addaddresss",
  async ({ address, userId }, thunkAPI) => {
    const { accessToken } = getUserFromLocalStorage();
    try {
      const resp = await customFetch.post(`/api/address/${userId}`, address, {
        headers: { authorization: `Bearer ${accessToken}` },
      });
      return resp.data;
    } catch (error) {
      if (error.response.status === 401) {
        thunkAPI.dispatch(logoutUser());
        return thunkAPI.rejectWithValue("Unauthorization! Logging out... ");
      }
      return thunkAPI.rejectWithValue(error.response.msg);
    }
  }
);
export const RemoveAddress = createAsyncThunk(
  "address/removeaadress",
  async (id, thunkAPI) => {
    const { accessToken } = getUserFromLocalStorage();
    try {
      const resp = await customFetch.delete(`api/address/${id}`, {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      });
      return resp.data;
    } catch (error) {
      if (error.response.status === 401) {
        thunkAPI.dispatch(logoutUser());
        return thunkAPI.rejectWithValue("Unauthorization! Logging out... ");
      }
      return thunkAPI.rejectWithValue(error.response.msg);
    }
  }
);
export const updateAddress = createAsyncThunk(
  "address/updateaddress",
  async ({ id, address }, thunkAPI) => {
    console.log(id);
    console.log(address);
    const { accessToken } = getUserFromLocalStorage();
    try {
      const resp = await customFetch.put(`api/address/${id}`, address, {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      });
      return resp.data;
    } catch (error) {
      if (error.response.status === 401) {
        thunkAPI.dispatch(logoutUser());
        return thunkAPI.rejectWithValue("Unauthorization! Logging out... ");
      }
      return thunkAPI.rejectWithValue(error?.response?.msg);
    }
  }
);

//All Cities
export const getAllCity = createAsyncThunk(
  "address/getAllCity",
  async (_, thunkAPI) => {
    try {
      const { accessToken } = getUserFromLocalStorage();
      const resp = await customFetch.get("/api/city", {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      });
      return resp?.data;
    } catch (error) {
      if (error.response.status === 401) {
        thunkAPI.dispatch(logoutUser());
        return thunkAPI.rejectWithValue("Unauthorization! Logging out... ");
      }
      return thunkAPI.rejectWithValue(error?.response?.msg);
    }
  }
);

const addressSlice = createSlice({
  name: "address",
  //   status: null,
  initialState,
  extraReducers: (builder) => {
    {
      builder
        .addCase(AddAddress.fulfilled, (state) => {
          alert("Address Added successfully");
          state.isLoadingAddress = false;
        })
        .addCase(AddAddress.pending, (state) => {
          state.isLoadingAddress = true;
        })
        .addCase(AddAddress.rejected, (state) => {
          state.isLoadingAddress = false;
        })
        .addCase(fetchAddresses.fulfilled, (state, { payload }) => {
          state.addresses = payload.content;
          state.isLoadingAddress = false;
        })
        .addCase(fetchAddresses.pending, (state) => {
          state.isLoadingAddress = true;
        })
        .addCase(fetchAddresses.rejected, (state) => {
          state.isLoadingAddress = false;
        })
        .addCase(RemoveAddress.fulfilled, (state) => {
          alert("Address Removed successfully");

          state.isLoadingAddress = false;
        })
        .addCase(RemoveAddress.pending, (state) => {
          state.isLoadingAddress = true;
        })
        .addCase(RemoveAddress.rejected, (state) => {
          state.isLoadingAddress = false;
        })
        .addCase(updateAddress.fulfilled, (state) => {
          alert("Address Updated successfully");

          state.isLoadingAddress = false;
        })
        .addCase(updateAddress.pending, (state) => {
          state.isLoadingAddress = true;
        })
        .addCase(updateAddress.rejected, (state) => {
          state.isLoadingAddress = false;
        })
        .addCase(getAllCity.fulfilled, (state, { payload }) => {
          state.allCity = payload;
          state.isLoadingAddress = false;
        })
        .addCase(getAllCity.pending, (state) => {
          state.isLoadingAddress = true;
        })
        .addCase(getAllCity.rejected, (state) => {
          state.isLoadingAddress = false;
        });
    }
  },
});

export default addressSlice.reducer;
