import React, { useEffect, useState } from "react";
import profilePic from "../../Images/nav_img.jpg";
import { FaRegEdit } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchProfile } from "../../features/Profile/ProfileSlice";
import Addresses from "../../components/AddBooking/Addresses";
import { updateUserProfile } from "../../features/user/UserSlice";



const formatDate = (date) => {
  if (!date) return ""; // Handle null or undefined dates
  const d = new Date(date);
  return d.toISOString().split("T")[0]; // Convert to 'YYYY-MM-DD'
};

// Example usage:
// const userDetail = {
//   dateOfBirth: formatDate("2000-01-15T00:00:00Z"), // Ensures correct format
// };


const initialState = {
  isLoading: null,
  status: null,
  error: null,
  uploadedFile: null,
  id: null,
  firstName: "",
  lastName: "",
  mobile: "",
  email: "",
  gender: null,
  dateOfBirth: null,
  profile_image_url: "",
};
const UserDetail = () => {
  const { userId } = useParams();
  const dispatch = useDispatch();
  const { userData } = useSelector((store) => store.profile);

  const [userDetail, setUserDetail] = useState(userData);
  const [editMode, setEditMode] = useState(false);


  useEffect(() => {
    dispatch(fetchProfile(userId));
  }, []);
  useEffect(() => {
    setUserDetail(userData);
  }, [userData]);

  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   setUserDetail((prev) => ({ ...prev, [name]: value }));
  // };

  // const handleEditToggle = () => {
  //   setEditMode(!editMode);
  //   if (!editMode) {
  //     setUserDetail(userData); // Reset to original data if cancel is clicked
  //   }
  // };

  // const handleSubmit = () => {
  //   dispatch(updateUserProfile({ userId: userDetail?.id, userDetail }));
  //   setEditMode(false);
  // };

// Handle input change
const handleInputChange = (e) => {
  const { name, value } = e.target;

  if (name === "fullName") {
    // Trim any leading or trailing spaces and split into words
    const trimmedValue = value.trim();
    const spaceIndex = trimmedValue.indexOf(" ");

    if (spaceIndex === -1) {
      // If there's no space, assume it's all firstName
      setUserDetail((prev) => ({ ...prev, firstName: trimmedValue, lastName: "" }));
    } else {
      // Split into firstName and lastName based on the first space
      const firstName = trimmedValue.substring(0, spaceIndex);
      const lastName = trimmedValue.substring(spaceIndex + 1);
      setUserDetail((prev) => ({ ...prev, firstName, lastName }));
    }
  } else {
    // Update other fields dynamically
    setUserDetail((prev) => ({ ...prev, [name]: value }));
  }
};


// Toggle edit mode
const handleEditToggle = () => {
  setEditMode(!editMode);
  if (!editMode) {
    setUserDetail(userData); // Reset to original data if cancel is clicked
  }
};

// Submit the updated profile
const handleSubmit = () => {
  dispatch(updateUserProfile({ userId: userDetail?.id, userDetail })); // Send the entire userDetail object
  setEditMode(false);
};











  return (
    <div>
      <div className="flex  py-[4rem] h-[36rem]  gap-[2rem] justify-center w-full">
        <div className="flex rounded-lg w-[50rem]">
          <div className="flex flex-col items-center  py-[2rem] bg-[#1d1d1d]  w-1/3 rounded-l-lg gap-[1rem]">
            <img
              src={userDetail?.profile_image_url}
              className="rounded-full h-[10rem] w-[10rem] shadow-sm shadow-black bg-white mb-[2rem] object-cover"
            />
            <div className=" text-white ">
              <span className="relative flex flex-col gap-[0.2rem]">
                <div className="absolute -top-3 left-4 px-3 text-sm bg-[#1d1d1d]">
                  Full Name
                </div>
                <input
                  type="text"
                  name="fullName" // Changed name to "fullName" for clarity
                  value={`${userDetail?.firstName || ""} ${userDetail?.lastName || ""}`}
                  disabled={!editMode}
                  onChange={handleInputChange}
                  className="h-[4rem] py-[1.3rem] pl-[1.2rem] pr-[2rem] w-[13rem] text-xl bg-[#1d1d1d] border border-white rounded-lg"
                />
              </span>
            </div>
            
              {editMode ? (
              <div className="flex gap-[1rem]  mt-[3rem]">
                <button
                  onClick={handleSubmit}
                  className="flex justify-center items-center gap-[1rem] rounded-lg text-lg bg-green-400 font-normal text-[#000] w-[7rem] h-[2.5rem]"
                >
                  Submit
                </button>
                <button
                  onClick={handleEditToggle}
                  className="flex justify-center items-center gap-[1rem] rounded-lg text-lg bg-red-400 font-normal text-[#000] w-[7rem] h-[2.5rem]"
                >
                  Cancel
                </button>
              </div>
            ) : (
              <div
                onClick={handleEditToggle}
                className="flex justify-center cursor-pointer items-center gap-[1rem] rounded-xl text-lg bg-primary-30 text-[#000] w-[12rem] h-[3rem] mt-[3rem]"
              >
                <FaRegEdit />
                EDIT
              </div>
            )}
          </div>
          <div className="flex flex-col h-full w-2/3 border-t border-r border-b border-black rounded-r-lg px-[2.5rem] py-[1rem] gap-[2.5rem]">
            <div className="flex items-center text-xl font-semibold border-b border-black w-full h-[3rem] ">
              Information
            </div>
            <div className="flex gap-[3rem]">
              <span className="relative flex flex-col gap-[0.2rem]">
                <div className="absolute -top-3 left-4 px-3 text-sm bg-white">
                  Email
                </div>
                <input
                  type="email"
                  name="email"
                  value={userDetail?.email}
                  disabled={!editMode}
                  onChange={handleInputChange}
                  className="h-[2rem] py-[1.3rem] pl-[0.8rem] pr-[2rem] w-[13rem] text-sm bg-white border border-black rounded-lg"
                />
              </span>
              <span className="relative flex flex-col gap-[0.2rem] ">
                <div className="absolute -top-3 left-4 px-3 text-sm bg-white">
                  Mobile
                </div>
                <input
                  type="text"
                  name="mobile"
                  value={userDetail?.mobile}
                  disabled={!editMode}
                  onChange={handleInputChange}
                  className="h-[2rem] py-[1.3rem] pl-[0.8rem] pr-[2rem] w-[10rem] text-sm bg-white border border-black rounded-lg"
                />
              </span>
            </div>
            <div className="flex gap-[1rem]">
              <span className="relative flex flex-col items-center gap-[0.2rem] ">
                <div className="absolute -top-3 left-4 px-3 z-10 text-sm bg-white">
                  Gender
                </div>

                <select
                  name="gender"
                  className="h-[2.66rem] py-[0.3rem] pl-[0.8rem] pr-[2rem] w-full text-center items-center justify-center text-sm bg-white border border-black rounded-lg appearance-none outline-none"
                  value={userDetail?.gender}
                  disabled={!editMode}
                  onChange={handleInputChange}
                  >
                  <option value="">
                    None
                  </option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </select>
              </span>
              <span className="relative flex w-[8rem] flex-col gap-[0.2rem] ">
                <div className="absolute -top-3 left-4 px-3 text-sm bg-white">
                  DOB
                </div>
                <input
                  type="date"
                  name="dateOfBirth"
                  value={formatDate(userDetail?.dateOfBirth) || ""} // Format the date
                  disabled={!editMode}
                  onChange={handleInputChange}
                  className="h-[2rem] py-[1.3rem] pl-[0.8rem]  w-[8rem] text-sm bg-white border border-black rounded-lg"
                />
              </span>
              {/* <span className="relative flex flex-col gap-[0.2rem] ">
                <div className="absolute -top-3 left-4 px-3 text-sm bg-white">
                  Location
                </div>
                <input
                  type="text"
                  value="Bangalore"
                  disabled={!editMode}
                  onChange={handleInputChange}
                  className="h-[2rem] py-[1.3rem] pl-[0.8rem] pr-[2rem] w-[10rem] text-sm bg-white border border-black rounded-lg"
                />
              </span> */}
            </div>
            {/* <div className="flex gap-[3rem]">
              <span className="relative flex flex-col gap-[0.2rem] ">
                <div className="absolute -top-3 left-4 px-3 text-sm bg-white">
                  Alternate Mobile Number
                </div>
                <input
                  type="text"
                  value="+917272727272"
                  disabled={!editMode}
                  onChange={handleInputChange}
                  className="h-[2rem] py-[1.3rem] pl-[0.8rem] pr-[2rem] w-[15rem] text-sm bg-white border border-black rounded-lg"
                />
              </span>
            </div> */}
          </div>
        </div>

        <div className="flex flex-col border border-black rounded-lg px-[2.5rem] py-[1rem] gap-[2rem] w-[33rem] ">
          {/* <div className="flex items-center text-xl font-semibold border-b border-black w-full h-[3rem] ">
            Saved Addresses
          </div>
          <div className="px-[2rem] py-[1rem] shadow-sm shadow-black rounded-xl">
            <div className="font-semibold pb-[0.2rem]">HOME</div>
            <div className="text-sm text-[#484848]">Dehri-on-Sone</div>
            <div className="text-sm text-[#484848]">Dehri</div>
            <div className="text-sm text-[#484848]">Bihar-221003</div>
          </div> */}
          <Addresses userId={userId} />
        </div>
      </div>
    </div>
  );
};

export default UserDetail;
