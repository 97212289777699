import axios from "axios";
import { useDispatch } from "react-redux";
import { clearStore } from "../features/user/UserSlice";
import { toast } from "sonner";

const customFetch = axios.create({
  baseURL: "https://api.emplux.com/",
});

export const partnerCustomFetch = axios.create({
  baseURL: "https://apipartner.emplux.com/",
});

// The logout function
const Logout = () => {
  const dispatch = useDispatch();
  dispatch(clearStore());
  console.log("Logging out...");
};

// Custom hook to set up interceptors
export const useAxiosInterceptors = () => {
  const dispatch = useDispatch();

  const Logout = () => {
    dispatch(clearStore());
    console.log("Logging out...");
  };

  // Interceptor for customFetch
  customFetch.interceptors.response.use(
    (response) => response, // Pass through successful responses
    (error) => {
      if (error.response && error.response.status === 401) {
        Logout(); // Call logout function on status 401
      }
      return Promise.reject(error); // Reject the promise with the error
    }
  );

  // Interceptor for partnerCustomFetch
  partnerCustomFetch.interceptors.response.use(
    (response) => response, // Pass through successful responses
    (error) => {
      if (error.response && error.response.status === 401) {
        // Logout(); // Call logout function on status 401
        toast.error("Please re-enter partner credentials!");
      }
      return Promise.reject(error); // Reject the promise with the error
    }
  );
};

export default customFetch;
