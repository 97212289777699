import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import React from "react";
import { getUserFromLocalStorage } from "../../utils/localStorage";
import customFetch from "../../utils/axios";
const initialState = {
  uploadUrl: "",
  isLoading: false,
};
export const uploadFile = createAsyncThunk(
  "file/uploadfile",
  async (file, thunkAPI) => {
    console.log(file);
    const { accessToken } = getUserFromLocalStorage();
    try {
      const resp = await customFetch.post("api/file/upload", file, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return resp.data;
    } catch (error) {
      if (error.response) {
        alert("Error Occured!");
      }
      return thunkAPI.rejectWithValue(error.response.msg);
    }
  }
);
const FilesSlice = createSlice({
  name: "file",
  initialState,
  reducers: {
    setLoading: (state, { payload }) => {
      state.isLoading = payload;
    },
    clearFile: (state) => {
      state.uploadUrl = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(uploadFile.fulfilled, (state, { payload }) => {
        alert("Icon Uploaded Successfully!");
        state.isLoading = false;
        state.uploadUrl = payload.image_url;
      })
      .addCase(uploadFile.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(uploadFile.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export default FilesSlice.reducer;
export const { setLoading, clearFile } = FilesSlice.actions;
