import React from "react";
import { RxCross1 } from "react-icons/rx";
import { BsSearch, BsImage } from "react-icons/bs";
import { FaFilter } from "react-icons/fa6";
import { AiOutlineRollback } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchAllSubCategory } from "../features/SubCategory/SubCategorySlice";

const ANSubSecSelectSubCategory = ({
  handleAdding,
  handleCategory,
  selectedId,
}) => {
  console.log(selectedId);
  const AllSubCategory = useSelector(
    (store) => store?.subcategory?.SubCategories
  );
  console.log(AllSubCategory);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchAllSubCategory());
  }, []);
  return (
    <div className="fixed flex items-center justify-center top-0 bg-[#00000083] z-40 w-screen h-screen right-0">
      <form className="relative flex flex-col h-[550px] w-[450px] bg-white rounded-xl">
        {/* this is for back sign  */}
        <div className="absolute right-0 -top-11 bg-white rounded-full text-3xl p-1 ">
          <AiOutlineRollback
            className="cursor-pointer p-1 font-bold text-black"
            onClick={handleAdding}
          />
        </div>

        {/* div for selecting sub-category  */}
        <div className="flex h-[12vh] justify-center items-center text-2xl font-bold capitalize ">
          Select Sub-Category
        </div>

        <div className="flex w-full h-18 mt-2">
          <div className="flex flex-row  px-4 w-2/3 ">
            <div className="flex items-center w-2/3  bg-[#A4DBF2] rounded-l-xl">
              <input
                type="Search"
                placeholder="Search.."
                className="bg-[#A4DBF2] w-32 outline-none ml-4 text-sm"
              />
            </div>
            <div className="flex items-center justify-center w-1/4 bg-[#EEBF00] rounded-r-xl">
              <BsSearch className=" font-extrabold text-white" />
            </div>
          </div>
          <div className="flex items-center justify-end mr-8 w-1/3 text-3xl ">
            <FaFilter className="px-1 py-2 rounded-md bg-black text-white" />
          </div>
        </div>
        <div className=" mt-6 h-full overflow-scroll">
          {AllSubCategory?.map((elem, index) => {
            console.log(elem);
            return (
              <div
                className={
                  "flex h-[5vh] border mx-4 mb-3 rounded-sm border-black cursor-pointer " +
                  (selectedId == elem?.id ? "bg-black text-white" : "")
                }
                onClick={() => handleCategory(elem?.id, elem?.name)}
                key={index}>
                <div className="flex items-center justify-center text-2xl w-1/6 max-[1100px]:text-sm ">
                  {/* <BsImage /> */}
                  <img src={elem?.iconUrls} alt="" className="h-6" />
                </div>
                <div className="flex items-center justify-center w-5/6 font-mono max-[1100px]:text-sm">
                  {elem?.name}
                </div>
                <div className="flex items-center justify-center w-1/6 max-[1000px]:text-sm">
                  {elem?.id}
                </div>
              </div>
            );
          })}
        </div>
      </form>
    </div>
  );
};

export default ANSubSecSelectSubCategory;
