import React, { useState, useEffect } from "react";
import "../../style/home.css";
import { MdAddCircle } from "react-icons/md";
import { IoIosSearch } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { getCatagories } from "../../features/Catagory/CatagorySlice";
import { FaCircleMinus } from "react-icons/fa6";
import {
  fetchAllSubCategory,
  fetchSubCategory,
} from "../../features/SubCategory/SubCategorySlice";
import { fetchIssue } from "../../features/Issue/IssuesSlice";
import { fetchUserFilter } from "../../features/Profile/ProfileSlice";
import Cart from "../../components/AddBooking/Cart";
import { clearCart, deleteCart, postCart } from "../../features/Cart/CartSlice";
import SlotOption from "../../components/AddBooking/SlotOption";
import Addresses from "../../components/AddBooking/Addresses";
import AddNewUser from "../../components/AddNewUser";
import { postBooking } from "../../features/Bookings/BookingSlice";
import { async } from "q";
import { toast } from "sonner";

const Home = () => {
  const dispatch = useDispatch();
  const [params, setParams] = useState({ page: 0, size: 10, origin: 0 });
  const [SCparams, setSCParams] = useState({ page: 0, size: 10 });
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selctedIssue, setSelectedIssue] = useState([]);
  const [SearchedNumber, setSearchNumber] = useState(null);
  const [cartItemsList, setCartItemsList] = useState([]);
  const [isAddingUser, setAddinguser] = useState(false);

  const bookingInitialState = {
    categoryName: "",
    subCategoryName: "",
    bookingSlot: "",
    status: "future",
    amount: 0,
    cityName: "",
    bookingAddressId: 0,
    paymentMode: "cash",
  };

  const [bookingState, setBookingState] = useState(bookingInitialState);
  const { allUsers } = useSelector((store) => store?.profile);
  const { totalPages, catagory } = useSelector((store) => store?.catagory);
  const { cartItems, totalAmount } = useSelector((store) => store?.cart);
  const { issuesList, subCategoryDetails } = useSelector(
    (store) => store?.issue
  );
  const { SubCategories, SCtotalPages, SCpageSize, SCcurrentPage } =
    useSelector((state) => state?.subcategory);

  const handleMore = (e) => {
    const name = e?.target?.name;
    if (name === "morecategory") {
      if (totalPages > 1) {
        const newSize = params?.size + 10;
        setParams({ ...params, size: newSize });
      } else {
        setParams({ ...params, size: 10 });
      }
    }
  };
  const handleSelectUserId = (data) => {
    console.log(data);
    setSelectedUserId(data);
    setSearchNumber(null);
    setSelectedCategory(null);
    setSelectedSubCategory(null);
  };

  const handleBookingStateChange = (state) => {
    setBookingState(state);
  };
  const handleCategorySelect = async (id) => {
    //###################
    if (id !== selectedCategory) {
      dispatch(fetchSubCategory(id));
      setSelectedSubCategory(null);
      const cartIds = cartItems?.map((elem) => elem?.id);
      await dispatch(
        clearCart({ userId: selectedUserId?.id, cartIds: cartIds })
      );
      setBookingState(bookingInitialState);
      setCartItemsList([]);
    }

    setSelectedCategory(id);
  };

  const handleSelectSubcategory = async (id) => {
    if (id !== selectedSubCategory) {
      dispatch(fetchIssue(id));
      setCartItemsList([]);
      const cartIds = cartItems?.map((elem) => elem?.id);
      await dispatch(
        clearCart({ userId: selectedUserId?.id, cartIds: cartIds })
      );
      setBookingState(bookingInitialState);
    }
    console.log(id);
    console.log(selectedSubCategory);
    setSelectedSubCategory(id);
  };

  //
  const handleIssueSelect = async (issueData) => {
    const {
      id,
      issueName,
      issueDuration,
      issuePrice,
      issueIconUrl,
      issueDescription,
    } = issueData;

    const data = {
      issueId: id,
      issueName: issueName,
      issueDuration: issueDuration,
      issueDescription: issueDescription,
      issuePrice: issuePrice,
      active: true,
    };

    if (selectedUserId?.id) {
      if (cartItemsList?.includes(id)) {
        const deleteCartId = cartItems?.find(
          (elem) => elem?.issueId?.toString() === id?.toString()
        );
        dispatch(
          deleteCart({
            userId: selectedUserId?.id?.toString(),
            cartId: deleteCartId?.id,
          })
        );
      } else {
        await dispatch(postCart({ userId: selectedUserId?.id, data: data }));
      }
    }
  };

  const hangleGetUser = (e) => {
    const { value } = e?.target;

    setSearchNumber(value);
    dispatch(fetchUserFilter({ mobile: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(bookingState);
    if (
      bookingState?.categoryName === "" ||
      bookingState?.categoryName === null ||
      bookingState?.subCategoryName === "" ||
      bookingState?.subCategoryName === null
    ) {
      toast.warning("Please select category or SubCategory!");
    } else if (
      bookingState?.bookingSlot === "" ||
      bookingState?.bookingSlot === null
    ) {
      toast.warning("Select booking slot");
    } else if (
      bookingState?.amount === 0 ||
      bookingState?.amount === null ||
      cartItemsList?.length === 0
    ) {
      toast.warning("Add item to cart!");
    } else if (
      bookingState?.paymentMode === "" ||
      bookingState?.paymentMode === null
    ) {
      toast.warning("Select payment mode!");
    } else {
      const {
        categoryName,
        paymentMode,
        bookingSlot,
        amount,
        subCategoryName,
        status,
        bookingAddressId,
        cityName,
      } = bookingState;
      dispatch(
        postBooking({
          userId: selectedUserId?.id,
          data: {
            categoryName: categoryName,
            paymentMode: paymentMode,
            bookingSlot: bookingSlot,
            amount: amount,
            subCategoryName: subCategoryName,
            status: status,
            bookingAddressId: bookingAddressId,
            cityName: cityName,
          },
        })
      );
      setSelectedUserId(null);
      setBookingState(bookingInitialState);
      setSelectedCategory(null);
      setSelectedIssue(null);
      setSelectedSubCategory(null);
    }
  };

  useEffect(() => {
    dispatch(getCatagories(params));
  }, [params]);

  useEffect(() => {
    if (selectedSubCategory) {
      const list = cartItems?.map((elem) => {
        if (elem?.active) {
          return elem?.issueId;
        }
      });
      console.log(cartItemsList);
      setCartItemsList(list);
    }
  }, [cartItems]);

  useEffect(() => {
    if (!selectedUserId?.id) {
      handleBookingStateChange(bookingInitialState);
    } else {
      const categoryName = subCategoryDetails?.categoryName;
      const name = subCategoryDetails?.name;
      if (categoryName && name) {
        handleBookingStateChange({
          ...bookingState,
          categoryName: categoryName,
          subCategoryName: name,
          amount: totalAmount,
        });
      }
    }
  }, [selectedUserId, subCategoryDetails, cartItemsList]);

  useEffect(() => {
    dispatch(fetchAllSubCategory(SCparams));
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="w-full 	">
      {/* Add user's number */}
      {isAddingUser && (
        <AddNewUser handleAddNewUser={() => setAddinguser((state) => !state)} />
      )}
      <div className="w-full  py-8 relative flex justify-between">
        <div className="h-max flex text-sm rounded-lg  w-max relative z-30 ml-8">
          <div className=" px-4 py-2  bg-light font-medium ">Mobile</div>
          <input
            type="number"
            className=" px-2 py-2 border  "
            placeholder="Search for user."
            onChange={hangleGetUser}
          />
          <div className=" min-h-full bg-secondary px-2 ">
            <IoIosSearch className="text-white  w-full h-full text-2xl " />
          </div>
          {SearchedNumber && (
            <div className="flex absolute top-10  bg-white gap-2 z-50 px-2  flex-col w-max h-max left-20 pb-2 ">
              <div className="px-2">Select User</div>
              {allUsers?.map((elem, index) => {
                const { firstName, mobile, id } = elem;
                return (
                  <div
                    key={index}
                    className=" py-1 px-2 bg-dark-gray cursor-pointer"
                    onClick={() =>
                      handleSelectUserId({
                        firstName: firstName,
                        mobile: mobile,
                        id: id,
                      })
                    }>
                    {firstName + "-" + mobile}
                  </div>
                );
              })}
            </div>
          )}
        </div>
        <div className=" flex items-center  absolute w-full">
          {selectedUserId?.id && (
            <div className="w-max m-auto py-2 px-4 bg-dark-gray rounded-md">
              {"Booking for " +
                selectedUserId?.firstName +
                "-" +
                selectedUserId?.mobile}
            </div>
          )}
        </div>
        <button
          className="bg-primary-max rounded-md text-white text-sm p-1 px-3 mr-8 z-30"
          onClick={() => setAddinguser((state) => !state)}>
          Add User
        </button>
      </div>

      {/* XXXXXXXXXXXXX */}
      <div className="relative ">
        {!selectedUserId?.id && (
          <div
            className="absolute h-full w-full bg-light top-0 left-0 z-20 cursor-not-allowed hover:bg-[#00000079] "
            onClick={() => toast.warning("Plese select user!")}></div>
        )}
        <div className="py-10 mx-10">
          {/* Catagory */}
          <div className="h-max flex text-sm rounded-md overflow-hidden w-max mx-auto ">
            <div className=" px-4 py-2  bg-light ">Category</div>
            <input
              className="outline-none px-2 py-2 border "
              placeholder="Search for caregory"
            />

            <select name="categoryType" className="border  px-2 min-h-full">
              <option>Select Category</option>
              <option value="mechanical">Mechanical</option>
              <option value="electrical">Electrical</option>
              <option value="electronics">Electronics</option>
              <option value="other">Other</option>
            </select>
            <div className=" min-h-full bg-secondary px-2 ">
              <IoIosSearch className="text-white  w-full h-full text-2xl " />
            </div>
          </div>
          {/* category list */}
          <div className=" bg-dark-gray rounded-sm flex flex-col items-center pb-4 mt-5">
            <div className="w-full flex flex-wrap gap-4   border py-8 mx-auto px-10">
              {catagory?.map((elem, index) => {
                const {
                  id,
                  categoryType,
                  categoryName,
                  categoryUrl,
                  showCategory,
                } = elem;
                //TODO: showCategory
                if (showCategory) {
                  return (
                    <div
                      key={index}
                      className={
                        " flex flex-col  items-center hover:bg-dark-gray p-2 px-4 rounded cursor-pointer " +
                        (selectedCategory === id && " bg-dark-gray")
                      }
                      onClick={() => handleCategorySelect(id)}>
                      <img
                        src={categoryUrl}
                        alt={categoryName}
                        className="w-[4rem] h-[4rem] rounded-full border-2 p-2  bg-pale"
                      />
                      <span className="text-sm text-center">
                        {categoryName}
                      </span>
                    </div>
                  );
                }
              })}
            </div>

            <button
              type="button"
              name="morecategory"
              className={
                "m-auto uppercase border border-secondary bg-pale  w-[40%] "
              }
              onClick={handleMore}>
              {totalPages > 1 ? "More" : "Hide"}
            </button>
          </div>

          {/* Sub category */}
          <div className="h-max flex text-sm rounded-md overflow-hidden w-max mx-auto mt-10 ">
            <div className=" px-4 py-2  bg-light ">Sub Category</div>
            <input
              className="outline-none px-2 py-2 border  "
              placeholder="Search for sub caregory"
            />

            {/* <select name="categoryType" className="border  px-2 min-h-full">
              <option>Select Category</option>
              <option value="mechanical">Mechanical</option>
              <option value="electrical">Electrical</option>
              <option value="electronics">Electronics</option>
              <option value="other">Other</option>
            </select> */}
            <div className=" min-h-full bg-secondary px-2 ">
              <IoIosSearch className="text-white  w-full h-full text-2xl " />
            </div>
          </div>
          {/* Sub category list */}
          <div className=" bg-dark-gray rounded-sm flex flex-col items-center pb-4 mt-5">
            <div className="w-full flex flex-wrap gap-4   border py-8 mx-auto px-10">
              {SubCategories?.map((elem, index) => {
                const { id, name, iconUrls, showCategory } = elem;
                //TODO: showCategory
                if (true) {
                  console.log(selectedSubCategory == id);
                  return (
                    <div
                      key={index}
                      className={
                        selectedSubCategory === id
                          ? " flex gap-2 items-center cursor-pointer hover:bg-dark-gray p-2 px-4 rounded w-[15rem] bg-dark-gray"
                          : " flex gap-2 items-center cursor-pointer hover:bg-dark-gray p-2 px-4 rounded bg-pale w-[15rem]"
                      }
                      onClick={() => handleSelectSubcategory(id)}>
                      <img
                        src={iconUrls}
                        alt={name}
                        className="w-[4rem] h-[4rem] rounded-full border-2 p-2  bg-pale"
                      />
                      <span className="text-center ">{name}</span>
                    </div>
                  );
                }
              })}
            </div>

            <button
              type="button"
              name="moreSubcategory"
              className={
                "m-auto uppercase border border-secondary bg-pale  w-[40%]   " +
                  (SCcurrentPage === 0 && SCtotalPages === 1) && " hidden"
              }
              onClick={handleMore}>
              {SCtotalPages > 1 ? "More" : "Hide"}
            </button>
          </div>

          {/* Issue List */}
          {selectedSubCategory && (
            <div className="flex gap-4 mt-5">
              <div className=" bg-dark-gray rounded-sm flex flex-col items-center pb-4 flex-1">
                <div className="w-full flex flex-wrap gap-4   border py-8 mx-auto px-10">
                  {issuesList?.map((elem, index) => {
                    const {
                      id,
                      issuePrice,
                      issueName,
                      issueIconUrl,
                      issueDuration,
                    } = elem;
                    //TODO: showCategory
                    if (true) {
                      return (
                        <div
                          key={index}
                          className=" flex gap-2 cursor-pointer items-center hover:bg-dark-gray p-2 px-4 rounded bg-pale w-[14rem] relative"
                          onClick={() => handleIssueSelect(elem)}>
                          {cartItemsList?.includes(id) ? (
                            <FaCircleMinus className="text-2xl absolute top-2 right-2" />
                          ) : (
                            <MdAddCircle className="text-2xl absolute top-2 right-2" />
                          )}
                          <img
                            src={issueIconUrl}
                            alt={issueName}
                            className="w-[4rem] h-[4rem] rounded-full border-2 p-2  bg-pale"
                          />
                          <div className="w-full flex flex-col ">
                            <div className="text-lg font-medium">
                              {issueName}
                            </div>
                            <div className="w-full flex justify-between text-sm">
                              <span>₹{issuePrice}</span>
                              <span>{issueDuration}</span>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  })}
                </div>

                <button
                  type="button"
                  name="moreSubcategory"
                  className={
                    "m-auto uppercase border border-secondary bg-pale  w-[40%]   " +
                      (SCcurrentPage === 0 && SCtotalPages === 1) && " hidden"
                  }
                  onClick={handleMore}>
                  {SCtotalPages > 1 ? "More" : "Hide"}
                </button>
              </div>
              {cartItemsList?.length > 0 && (
                <Cart userId={selectedUserId?.id} />
              )}
            </div>
          )}

          {/* Time Slot & address */}
          {selectedUserId?.id &&
            selectedSubCategory &&
            bookingState?.amount &&
            cartItemsList?.length > 0 && (
              <div className="flex mt-10 justify-between h-max">
                <div className="w-[49%] bg-dark-gray min-h-full rounded-md px-2">
                  <Addresses
                    handleDataChange={handleBookingStateChange}
                    userId={selectedUserId?.id}
                    bookingState={bookingState}
                  />
                </div>
                <div className="w-[49%] bg-dark-gray flex justify-center rounded-md min-h-full">
                  <SlotOption
                    bookingState={bookingState}
                    handleDataChange={handleBookingStateChange}
                  />
                </div>
              </div>
            )}

          {/* Payment */}
          {selectedUserId?.id &&
            cartItemsList?.length > 0 &&
            bookingState?.bookingSlot && (
              <div className="w-full flex mt-5 flex-col">
                <h1 className="text-xl ">Payment Mode</h1>
                <div className="w-1/3 border border-secondary-max mt-2">
                  <button
                    className="w-1/2 p-2 bg-secondary-max text-white"
                    onClick={() =>
                      handleBookingStateChange({
                        ...bookingState,
                        paymentMode: "online",
                      })
                    }>
                    Cash
                  </button>
                  <button
                    className="w-1/2 p-2 "
                    onClick={() => alert("Net yet integrated!")}>
                    Online
                  </button>
                </div>
              </div>
            )}
          <div className="w-full flex items-center justify-center mt-10">
            <button
              className="w-1/2 bg-primary-max text-white p-1  m-auto "
              type="submit"
              onClick={handleSubmit}>
              Add Booking
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
