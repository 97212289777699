import React, { useEffect, useState } from "react";
import { RxCross1 } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import { signUpUser } from "../features/user/UserSlice";
const initialState = {
	firstName: "",
	lastName: "",
	email: "",
	mobile: "",
	password: "",
};

const AddNewUser = ({ handleAddNewUser }) => {
	const [values, setValues] = useState(initialState);
	const dispatch = useDispatch();

	const handleChange = (e) => {
		const name = e.target.name;
		const value = e.target.value;
		setValues({ ...values, [name]: value });
	};

	const onSubmit = (e) => {
		e.preventDefault();
		console.log(values);
		const { firstName, lastName, mobile, email, password } = values;
		if (!firstName || !password || !mobile || !email) {
			alert("Please fill out all fields");
			return;
		}

		dispatch(
			signUpUser({
				firstName: firstName,
				lastName: lastName,
				mobile: mobile,
				email: email,
				password: password,
			})
		);
	};

	//  Regex code for validation
	const [errors, setErrors] = useState({
		firstName: "",
		lastName: "",
		email: "",
		password: "",
	});

	const validateName = (firstName) => {
		const regex = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z])$/;
		return regex.test(firstName);
	};

	const validateLastName = (lastName) => {
		const regex = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z])$/;
		return regex.test(lastName);
	};

	const validateEmail = (login) => {
		const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return regex.test(login);
	};
	// const validatePassword = (Password) => {
	// 	const regex = /^(?=.\d)(?=.[!@#$%^&])(?=.[a-z])(?=.*[A-Z]).{8,}$/;
	// 	return regex.test(Password);
	// };

	const handleInputFocusOut = (event) => {
		const { name, value } = event.target;
		if (name === "email" && !validateEmail(value)) {
			setErrors({ ...errors, email: "Please enter a valid email" });
		} else if (name === "firstName" && !validateName(value)) {
			setErrors({
				...errors,
				firstName:
					"Atleast 2 letters and should not start or end with a special character",
			});
		} else if (name === "lastName" && !validateLastName(value)) {
			setErrors({
				...errors,
				lastName:
					"Atleast 2 letters and should  not start or end with a special character",
			});
		}
		// else if (name === "password" && !validatePassword(value)) {
		// 	setErrors({
		// 		...errors,
		// 		password:
		// 			"Password should contain atleast 1 lowercase, 1 uppercase and atleast one number",
		// 	});
		// }
		else {
			// input is correct, remove error message for this field
			const newErrors = { ...errors };
			delete newErrors[name];
			setErrors(newErrors);
		}
	};

	return (
		<div className="fixed flex items-center justify-center top-0 bg-[#00000099] z-40 w-screen right-0 h-screen">
			<form
				className="flex flex-col relative  h-[32rem] w-[34rem] rounded-xl bg-white"
				onSubmit={onSubmit}>
				<div className="absolute p-1 -top-7 -right-1 m-1 text-3xl font-bold bg-white rounded-3xl -my-4">
					<RxCross1
						className="cursor-pointer p-1 font-bold"
						onClick={handleAddNewUser}
					/>
				</div>
				<div className="flex justify-center items-center h-[75px] text-3xl font-semibold">
					Add New User
				</div>

				<div className="">
					<div className="flex justify-between mx-6 gap-[2rem]">
						<div className="border border-black p-2 rounded-lg  h-[50px]  mt-[1.2rem] relative w-1/2">
							<span className="absolute -top-3 bg-white px-3  left-5 text-black font-semibold text-sm  capitalize">
								First Name
							</span>
							<input
								type="text"
								name="firstName"
								placeholder="Enter the first name"
								className="w-full h-full px-4 outline-none text-sm "
								onChange={handleChange}
								onBlur={handleInputFocusOut}
							/>
							{errors.firstName && (
								<div className="text-xs mt-[0.5rem]" style={{ color: "red" }}>
									{errors.firstName}
								</div>
							)}
						</div>
						<div className="border border-black p-2 rounded-lg  h-[50px] mt-[1.2rem] relative w-1/2">
							<span className="absolute -top-3 bg-white px-3  left-5 text-black font-semibold text-sm  capitalize">
								Last Name
							</span>
							<input
								type="text"
								name="lastName"
								placeholder="Enter the last name"
								className="w-full h-full px-4 outline-none text-sm"
								onChange={handleChange}
								onBlur={handleInputFocusOut}
							/>
							{errors.lastName && (
								<div className="text-xs mt-[0.5rem]" style={{ color: "red" }}>
									{errors.lastName}
								</div>
							)}
						</div>
					</div>
					<div className="border border-black p-2 rounded-lg  h-[50px] mx-6 mt-[2.5rem] relative">
						<span className="absolute -top-3 bg-white px-3  left-5 text-black font-semibold text-sm  capitalize">
							Email
						</span>
						<input
							type="text"
							name="email"
							placeholder="Enter the email address"
							className="w-full h-full px-4 outline-none text-sm"
							onChange={handleChange}
							onBlur={handleInputFocusOut}
						/>
						{errors.email && (
							<div className="text-xs mt-[0.5rem]" style={{ color: "red" }}>
								{errors.email}
							</div>
						)}
					</div>
					<div className="border border-black p-2 rounded-lg  h-[50px] mx-6 mt-[2.5rem] relative">
						<span className="absolute -top-3 bg-white px-3  left-5 text-black font-semibold text-sm  capitalize">
							Mobile Number
						</span>
						<input
							type=""
							name="mobile"
							placeholder="Enter the mobile number"
							className="w-full h-full px-4 outline-none text-sm"
							onChange={handleChange}
							onBlur={handleInputFocusOut}
						/>
					</div>

					<div className="border border-black p-2 rounded-lg  h-[50px] mx-6 mt-[2.5rem] relative">
						<span className="absolute -top-3 bg-white px-3  left-5 text-black font-semibold text-sm  capitalize">
							Password
						</span>
						<input
							type="text"
							name="password"
							placeholder="Enter the password"
							className="w-full h-full px-4 outline-none text-sm"
							onChange={handleChange}
							onBlur={handleInputFocusOut}
						/>
						{/* {errors.password && (
							<div className="text-xs mt-[0.5rem]" style={{ color: "red" }}>
								{errors.password}
							</div>
						)} */}
					</div>
				</div>

				<div className="flex flex-row absolute bottom-0 w-full h-[55px] rounded-b-xl overflow-hidden">
					<button
						type="button"
						className="flex w-1/2 justify-center items-center text-2xl bg-gray-300 "
						onClick={handleAddNewUser}>
						Cancel
					</button>
					<button
						type="submit"
						className="flex w-1/2 justify-center items-center text-2xl bg-pink-500 text-white">
						Add
					</button>
				</div>
			</form>
		</div>
	);
};

export default AddNewUser;
