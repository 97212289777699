import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { FaRegCopy } from "react-icons/fa";
import { handleCopy } from "../../utils/utilFunctions";
import {
  bookingCancel,
  getAllBooking,
  getBookingDetail,
  isCompleted,
  onGoingOTP,
  postRemovingCompany,
  postRemovingPartner,
  postStatus,
} from "../../features/Bookings/BookingSlice";
import AssignPartner from "../../components/AssignPartner";
import { IoMdTime } from "react-icons/io";
import { fetchCompany, fetchPartner } from "../../features/Partner/PartnerSlice";
import { toast } from "sonner";
import AssignCompany from "../../components/AssignCompany";

const BookingDetail = () => {
  const dispatch = useDispatch();

  const [isAssigningPartner, setAssigningPartner] = useState(null);
  const [isAssigningCompany, setAssigningCompany] = useState(null);
  const [showOtpPopup, setShowOtpPopup] = useState(false);
  const [otp, setOtp] = useState("");
  const navigate = useNavigate();

  //TEMPORARY SETUP AFTER THE ASSIGN API IS BACK TO WORK REMOVE THESE USESTATES AND CREATE AS BELOW ONES USING PARAMS 
  const [selectedCompanyId, setSelectedCompanyId] = useState(null);
  const [selectedCompanyName, setSelectedCompanyName] = useState(null);
  const { bookingId } = useParams();
  const { bookings } = useSelector((store) => store.bookings);

  const handleForComapnyId = (companyId, companyName) => {
    console.log("Assigned Company ID:", companyId);
    setSelectedCompanyId(companyId);
    setSelectedCompanyName(companyName);
  }


  // const { companyList } = useSelector((store) => store?.partner);



  const booking = bookings
    ?.find((elem) => elem?.confirmBooking?.slice(0, 1)[0]?.id == bookingId)
    ?.confirmBooking?.slice(0, 1)[0];

    //THE CODE TO BE IMPLEMENTED AFTER THE API IS FIXED
    // const company = companyList
    // ?.find((elem) => elem?.confirmBooking?.slice(0, 1)[0]?.id == bookingId);
    
    // console.log(companyList)



  const handleStatusChange = (e) => {
    const value = e?.target?.value;
    dispatch(postStatus({ bookingId: bookingId, data: { status: value } }));
  };
  //Assign partner popup
  const handleAssigningPartner = (id) => {
    if (isAssigningPartner) {
      setAssigningPartner(null);
    } else setAssigningPartner(id);
  };

  const handleAssigningCompany = (id) => {
    if (isAssigningCompany) {
      setAssigningCompany(null);
    } else setAssigningCompany(id);
  }
// Function to handle booking cancellation with OTP confirmation
const handleBookingOngoing = () => {
  setShowOtpPopup(true);
};
const [isOngoing, setIsOngoing] = useState(false); // Track if OTP confirmation is ongoing

const handleConfirmOtp = async (bookingId) => {
  if (otp) {
    try {
      const response = await dispatch(onGoingOTP({ bookingId, otp }));
      console.log(response);
      // Check if response has "ongoing" status
      if (booking?.status?.toUpperCase() === "ONGOING") {
        setIsOngoing(true); // Set ongoing state based on response data
      } else {
        setIsOngoing(false);
      }
      console.log("OTP Confirmed:", otp);
    } catch (error) {
      console.error("OTP Confirmation Failed:", error);
    } finally {
      setShowOtpPopup(false); // Close popup regardless of success or failure
      setOtp(""); // Clear OTP input after submission
    }
  }
};
// console.log(booking?.partnerDto?.partnerFirstName)
const handleCompleted = async (bookingId, partnerId) => {
  console.log(bookingId, partnerId)
  toast.dismiss();
  toast(
    `Are you sure you want to Confirm Completion of this booking?`,
    {
      action: {
        label: "Confirm",
        onClick: () => {
          dispatch(
            isCompleted({ bookingId, partnerId })
          );
        },
      },
    }
  );
};


const BookingCancel = async (bookingId, userId, couponId) =>{

  // const data = booking?.status?.toLowerCase();
  console.log(bookingId, userId, couponId);
  toast.dismiss();
  toast(
    `Are you sure you want to Cancel the Booking ?`,
    {
      action: {
        label: "Confirm",
        onClick: () => {
          dispatch(
            bookingCancel({bookingId: bookingId, userId: userId, couponId: couponId, data: { status: booking?.status?.toLowerCase() }})
          );
        },
      },
    }
  );
};






  const handleRemovePartner = (bookingId, partnerId) => {
    toast.dismiss();
    toast(`Are you sure you want to Remove the Partner?`, {
      position:"bottom-right" ,
      action: {
        label: "Confirm",
        onClick: async () => {
          const response = await dispatch(postRemovingPartner({ bookingId: bookingId, partnerId: partnerId }));
          if (response.meta.requestStatus === "fulfilled") {
            toast.success("Partner was successfully Removed");
          } else {
            toast.error("Failed to Remove Partner");
          }
        },
      },
    });
  }

  const handleRemoveCompany = (bookingId, companyId) => {
    toast.dismiss();
    toast(`Are you sure you want to Remove the Partner?`, {
      position:"bottom-right" ,
      action: {
        label: "Confirm",
        onClick: async () => {
          const response = await dispatch(postRemovingCompany({ bookingId: bookingId, companyId: companyId }));
          //TEMPORARY SETUP AFTER THE ASSIGN API IS BACK TO WORK REMOVE THESE USESTATES
          setSelectedCompanyId(null);
          setSelectedCompanyName(null);
          if (response.meta.requestStatus === "fulfilled") {
            toast.success("Partner was successfully Removed");
          } else {
            toast.error("Failed to Remove Partner");
          }
        },
      },
      
    });
    
  }




// console.log(useParams());
  // console.log(booking?.id);


  useEffect(() => {
    dispatch(fetchPartner());
  }, []);


  useEffect(() => {
    dispatch(fetchCompany());
  }, []);


  // useEffect(() => {
  //   if (!booking) {
  //     dispatch(getAllBooking());
  //   }
  //   dispatch(getBookingDetail(bookingId));
  // }, [bookingId, booking]);


  useEffect(() => {
    if (!booking) {
      dispatch(getAllBooking());
    }
  }, [booking, dispatch]);

  useEffect(() => {
    // console.log(booking?.status);
    if (booking?.status?.toUpperCase() === "ONGOING") {
      setIsOngoing(true);
    } else if (booking?.status?.toUpperCase() === "COMPLETED") {
      setIsOngoing(false);
    } else {
      setIsOngoing(true);
    }
  }, [booking?.status]);
  
  return (
    <div className="min-h-screen p-4">
      {/* Popups */}
      {isAssigningPartner && (
        <AssignPartner
          handlePartner={handleAssigningPartner}
          bookingId={isAssigningPartner}
        />
      )}
      {isAssigningCompany && (
        <AssignCompany
          handlePartner={handleAssigningCompany}
          bookingId={isAssigningCompany}
          onAssignCompany={handleForComapnyId}
        />
      )}
      {/* Heading */}
      <div className="relative mx-auto bg-primary-max tracking-wider py-4 rounded-t-xl font-bold uppercase text-white pl-8 pr-12 flex items-center justify-between w-full">
        <span className="text-xl">Service Bookings</span>
        <span className="text-sm">Booking id - {booking?.orderId}</span>
        <div className="text-sm text-black py-2 px-20 bg-white rounded-b-xl CustomShadow absolute top-[100%] right-0">
          Slot - {booking?.bookingSlot?.slice(0, 16)?.replace("T", " ")}
        </div>
      </div>
      {/* User and status */}
      <div className="container mx-auto bg-secondary-min CustomShadow pt-4 pb-8 px-6 flex">
        <div className="w-1/3  h-full flex justify-between ">
          <div className="flex flex-col text-[#000000CC] gap-2  text-sm h-full">
            <div className="  mb-2 font-medium w-1/2  ">
              User Details
              <div className="bg-[#00000046] h-[1.5px]"></div>
            </div>
            <div>
              <strong>Name - </strong>
              {booking?.user?.firstName + " " + booking?.user?.lastName}
            </div>
            <div>
              <strong>Mobile Number - </strong>
              {booking?.user?.mobile}
            </div>
            <div className=" flex">
              <strong className=" whitespace-normal ">Email Address - </strong>
              {booking?.user?.email}
            </div>
          </div>
          
        </div>
        <div className=" w-1/3  flex flex-col justify-center min-h-full ">
            <div className="flex gap-4 text-sm mb-2  w-full">
              <div className="w-max font-medium">Address-</div>
              <div>
                {booking?.addresses?.address +
                  ", " +
                  booking?.addresses?.city +
                  ", " +
                  booking?.addresses?.state +
                  ", " +
                  booking?.addresses?.pinCode}
              </div>
              <div
                className="flex items-center justify-end cursor-pointer"
                onClick={() => handleCopy(booking?.addresses?.address)}>
                <FaRegCopy className="h-4" />
              </div>
            </div>
            {/* <div className="w-full text-white py-1 bg-primary-max text-center font-semibold text-sm rounded-md">
              Change Address
            </div> */}
          </div>
        <div className=" w-1/3 flex gap-2 items-end pt-8 justify-end flex-col">
          <div className=" flex gap-4 flex-row">
          <div>Booking Status-</div>
          <div
          name=""
          id=""
          className=" text-sm px-8 py-1 rounded-md border-[0.5px] border-[#00000057]">
          {booking?.status?.toUpperCase()}
          </div>  
          </div>


          {booking?.status?.toLowerCase() === "future" &&
                booking?.partner?.id > 0 && (
                  <div
                    onClick={handleBookingOngoing}
                    className="text-sm px-7 py-2 justify-end self-end rounded-md bg-gray-600 text-white font-bold cursor-pointer">
                    Ongoing
                  </div>
          )}

              {booking?.status?.toLowerCase() === "ongoing" && (
                <div
                  onClick={() =>
                    handleCompleted(booking?.id, booking?.partner?.id)
                  }
                  className="text-sm px-7 py-2 justify-end self-end rounded-md bg-gray-600 text-white font-bold cursor-pointer">
                  Completed
                </div>
              )}

              {/* OTP Confirmation Popup */}
              {showOtpPopup && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
                  <div className="bg-white p-6 rounded-lg w-80">
                    <h2 className="text-lg font-semibold mb-4 text-center">
                      Enter OTP to Confirm
                    </h2>
                    <input
                      type="text"
                      value={otp}
                      onChange={(e) => setOtp(e.target.value)}
                      placeholder={booking?.bookingOtp}
                      className="w-full px-3 py-2 mb-4 border border-gray-300 rounded focus:outline-none"
                      inputMode="numeric" // Ensures the numeric keypad on mobile devices
                      maxLength="6" // Limits input length to 6 characters
                    />

                    <div className="flex justify-end gap-2">
                      <button
                        onClick={() => setShowOtpPopup(false)}
                        className="px-4 py-2 rounded-md bg-gray-300 text-gray-800 font-semibold">
                        Cancel
                      </button>
                      <button
                        onClick={() => handleConfirmOtp(booking?.id)} // Wrap in an anonymous function
                        className="px-4 py-2 rounded-md bg-blue-600 text-white font-semibold">
                        Confirm
                      </button>
                    </div>
                  </div>
                </div>
              )}





          {booking?.status?.toLowerCase() === "future" && (
        <div
        onClick={() => BookingCancel(booking?.id, booking?.user?.id, booking?.couponId)}
        className=" text-sm px-9 py-2  rounded-md  bg-red-600 text-white font-bold cursor-pointer">
        Cancel
        </div>
        )}         
      </div>
               
      </div>
      {/* Partner detail */}
      <div className="container mx-auto bg-secondary-min CustomShadow pt-4 pb-8 px-6  mt-2 ">
        <div>
        <div className="  mb-2 font-medium w-1/6  ">
          Partner Details
          <div className="bg-[#00000046] h-[1.5px]"></div>
        </div>

        <div className="flex text-sm items-center justify-between">
          <div>
            <span className="font-medium">Name -</span>
            <span className="capitalize">
              {booking?.partner?.partnerFirstName || "--"}
            </span>
            <br />
            <span className="font-medium">Mobile -</span>
            <span>{booking?.partner?.partnerMobile || " -- "}</span>
          </div>
          <div>
            <span className="font-bold">Partner ID - </span>
            <span className="font-bold">{booking?.partner?.id || " -- "}</span>
            <br />
            {/* <span className="font-medium">Mobile -</span> */}
          </div>
          <div className=" flex flex-row gap-4">
          {!booking?.partner?.id && booking?.status?.toLowerCase() === "future" && (
          <div className="">
          <div
            className="cursor-pointer font-semibold px-10 py-3 bg-primary-max rounded-md text-white"
            onClick={() => handleAssigningPartner(booking?.id)}>
            Assign Partner
          </div>
          </div>
          )}
{booking?.partner?.id && booking?.status?.toLowerCase() === "future" && (
  <div
    className="cursor-pointer font-semibold px-9 py-3 bg-primary-max rounded-md text-white"
    onClick={() => handleRemovePartner(booking?.id, booking?.partner?.id)}
  >
    Remove Partner
  </div>
)}

          </div>
        </div>
        </div>
        <div>
        <div className=" mt-4 font-medium w-1/6  ">
          Company Details
          <div className="bg-[#00000046] h-[1.5px]"></div>
        </div>
        <div className="flex text-sm items-center justify-between">
          <div>
            <span className="font-medium">Name -</span>
            <span className="capitalize ">
            <span>{selectedCompanyName}</span>
            </span>
            <br />
            {/* <span className="font-medium">Mobile -</span>
            <span>{booking?.partner?.partnerMobile || " -- "}</span> */}
          </div>
          <div className=" pl-12">
            <span className="font-bold">Company ID -</span>
            <span className="font-bold">{selectedCompanyId}</span>
            <br />
            
          </div>
          <div className=" flex flex-row gap-4">
          {!selectedCompanyId && booking?.status?.toLowerCase() === "future" &&  (
          <div className="">
          <div
            className="cursor-pointer font-semibold px-8 py-3 bg-primary-max rounded-md text-white"
            onClick={() => handleAssigningCompany(booking?.id)}>
            Assign Company
          </div>
          </div>
          )}
          {selectedCompanyId && booking?.status?.toLowerCase() === "future" && (
  <div
    className="cursor-pointer font-semibold px-8 py-3 bg-primary-max rounded-md text-white"
    onClick={() => handleRemoveCompany(booking?.id, selectedCompanyId)}
  >
    Remove Company
  </div>
)}
          </div>
        </div>
        </div>
      </div>
      {/* Items and slot */}
      <div className="flex w-full gap-2 mt-2">
        <div className="flex-1 min-h-full flex flex-col">
          <div className="w-full py-3 px-7 bg-secondary-min CustomShadow flex-1 ">
            <div className="  mb-2 font-medium w-1/6  ">
              Booking Details
              <div className="bg-[#00000046] h-[1.5px]"></div>
            </div>
            <div className="flex items-center gap-4 mt-5 w-full">
              <div className="w-1/2 flex items-center">
                <span className="font-bold text-sm">Category Name -</span>
                <div className="px-2 py-1 text-sm  bg-white rounded-md border-[0.5px] border-[#00000057] ml-1 w-1/2 min-w-max">
                  {booking?.categoryName}
                </div>
              </div>
              <div className="w-1/2 flex items-center">
                <span className="font-bold text-sm">Sub-Category Name -</span>
                <div className="px-2 py-1 text-sm  bg-white rounded-md border-[0.5px] border-[#00000057] ml-1 w-1/2 min-w-max">
                  {booking?.subCategoryName}
                </div>
              </div>
            </div>
          </div>
          <div className="w-full py-3 px-7 bg-secondary-min CustomShadow mt-2">
            <div className="  mb-2 font-medium w-1/6  ">
              Slot Selection
              <div className="bg-[#00000046] h-[1.5px]"></div>
            </div>
            <div className="flex items-center w-full ">
              <div className="w-1/2 flex items-center ">
                <span className="font-bold text-sm min-w-max">
                  Sub-Category Name -
                </span>
                <div className="px-2 py-1 text-sm bg-white rounded-md border-[0.5px] border-[#00000057] ml-1 w-1/2 min-w-max">
                  {booking?.bookingSlot?.slice(0, 16)?.replace("T", " ")}
                </div>
              </div>
              <button className="bg-primary-max px-4 py-1 rounded-md text-white text-sm">
                Change Slot
              </button>
            </div>
          </div>
        </div>
        {/*issues  */}
        <div className="w-5/12 py-3 px-7 bg-secondary-min CustomShadow ">
          <div className="  mb-6 font-medium w-1/3  ">
            List of Issues
            <div className="bg-[#00000046] h-[1.5px]"></div>
          </div>
          {booking?.cardConfirmData?.map((elem, index) => {
            return (
              <div
                key={index}
                className="relative p-3 border-[0.5px] mt-1 border-[#00000099] bg-white rounded-md flex justify-between items-center ">
                <span className="font-bold text-[#000000CC]">
                  {elem?.issueName}
                </span>
                <span className="text-[#00000099] font-bold text-sm absolute left-1/2">
                  {"₹ " + elem?.issuePrice}
                </span>
                <span className="text-[#00000099] font-bold text-sm flex gap-1 items-center">
                  <IoMdTime className="text-xl" />
                  {elem?.issueDuration + " mins"}
                </span>
              </div>
            );
          })}
        </div>
      </div>
      {/* Price details */}
      <div className="flex w-full gap-3">
        {/* Consumer */}
        <div className="container mx-auto bg-secondary-min CustomShadow pt-4 pb-8 px-6  mt-2 ">
          <div className="flex items-center justify-between relative">
            <div className="  font-medium  w-1/3 min-w-max  ">
              Price Break-Up (User)
              <div className="bg-[#00000046] h-[1.5px]"></div>
            </div>
            <span className="text-sm font-medium absolute m-auto transform -translate-x-1/2   left-1/2">
              Payment Mode :
              <span className="uppercase text-[#CD0E0E]">
                {booking?.paymentMode}
              </span>
            </span>
            <span className="underline">Edit</span>
          </div>

          <div className=" flex flex-col w-3/4 mx-auto gap-5 text-sm mt-10">
            <div className="flex w-full items-center justify-between ">
              <span>Base Price</span>
              <span className="font-bold text-primary-max">₹ XXX</span>
            </div>
            <div className="flex w-full items-center justify-between ">
              <span>Tax</span>
              <span className=" text-[#F15B40]">+₹ XXX</span>
            </div>
            <div className="flex w-full items-center justify-between  ">
              <span>Extra Charges</span>
              <span className=" text-[#F15B40]">+₹ XXX</span>
            </div>
            <div className="flex w-full items-center justify-between ">
              <span>Cuppon Code</span>
              <span className=" text-primary-max">#EMP8386XX</span>
            </div>
            <div className="flex w-full items-center justify-between ">
              <span>Discount</span>
              <span className="font-bold text-[#44AF12]">-₹ XXX</span>
            </div>
            <div className="flex w-full items-center justify-between text-lg text-primary-max border-y px-1 border-[#00000033] py-3">
              <span className="font-medium">Total</span>
              <span className="font-bold">{"₹ " + booking?.amount}</span>
            </div>
            <div className="flex w-full items-center justify-between ">
              <span className="text-primary-max">Amount Paid</span>
              <span className="font-bold text-[#44AF12]">₹ XXX</span>
            </div>
            <div className="flex w-full items-center justify-between ">
              <span className="text-primary-max">Remaining Amount</span>
              <span className="font-bold text-[#BB2A10]">₹ XXX</span>
            </div>
          </div>
        </div>
        {/* Partner */}
        <div className="container mx-auto bg-secondary-min CustomShadow pt-4 pb-8 px-6  mt-2 ">
          <div className="flex items-center justify-between relative">
            <div className="  font-medium  w-1/3 min-w-max  ">
              Price Break-Up (Partner)
              <div className="bg-[#00000046] h-[1.5px]"></div>
            </div>

            <span className="underline">Edit</span>
          </div>

          <div className=" flex flex-col w-3/4 mx-auto gap-5 text-sm mt-10">
            <div className="flex w-full items-center justify-between ">
              <span>Total Amount</span>
              <span className="font-bold text-primary-max">
                {"₹ " + booking?.amount}
              </span>
            </div>
            <div className="flex w-full items-center justify-between ">
              <span>Company Commission</span>
              <span className=" text-[#F15B40]">+₹ XXX</span>
            </div>
            <div className="flex w-full items-center justify-between ">
              <span>Partner Credit</span>
              <span className=" text-primary-max">#EMP8386XX</span>
            </div>
            <div className="flex w-full items-center justify-between ">
              <span>Discount</span>
              <span className="font-bold text-[#44AF12]">-₹ XXX</span>
            </div>
            <div className="flex w-full items-center justify-between text-lg text-primary-max border-y px-1 border-[#00000033] py-3">
              <span className="font-medium">Payable Amount</span>
              <span className="font-bold">{"₹ " + booking?.amount}</span>
            </div>
            <div className="flex w-full items-center justify-between ">
              <span className="text-primary-max">Amount Paid</span>
              <span className="font-bold text-[#44AF12]">₹ XXX</span>
            </div>
            <div className="flex w-full items-center justify-between ">
              <span className="text-primary-max">Remaining Amount</span>
              <span className="font-bold text-[#BB2A10]">₹ XXX</span>
            </div>
            <button className="w-full py-3 font-bold text-white bg-primary-max rounded-md">
              Pay Now
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingDetail;
