import React from "react";
import { RxCross1 } from "react-icons/rx";
import { TbCameraPlus } from "react-icons/tb";
import { useState } from "react";
import ANSubSecSelectSubCategory from "./ANSubSecSelectSubCategory";
import UploadFileTemp from "./UploadFileTemp";
import { useDispatch, useSelector } from "react-redux";
import { editHomeSubSection } from "../features/Home/homeSlice";

const EditSubSectionHomeLayout = ({
  handleAdding,
  editId,
  subsectionData,
  homeId,
}) => {
  const dispatch = useDispatch();

  const [isSubCategory, setSubCategory] = useState(false);
  const homeSubSectionData = subsectionData?.find((elem) => elem?.id == editId);
  const [homeSubSection, setHomeSubSection] = useState(homeSubSectionData);
  const [subCategoryName, setSubCategoryName] = useState("");
  console.log(homeSubSectionData);
  const handleSubCategory = () => {
    setSubCategory((prev) => !prev);
  };

  const handleUploaded = (name, url) => {
    if (url != null || url?.trim() != "") {
      setHomeSubSection({ ...homeSubSection, [name]: url });
    }
  };

  const handleCategory = (id, name) => {
    setHomeSubSection({ ...homeSubSection, subCategoryId: id });
    setSubCategoryName(name);
    handleSubCategory();
  };

  const handleChange = (e) => {
    const { name, value } = e?.target;
    if (value) {
      setHomeSubSection({ ...homeSubSection, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (homeSubSection == homeSubSectionData) {
      alert("No changes found!");
      return;
    } else if (
      homeSubSection?.appThumbnailUrl == null ||
      homeSubSection?.appThumbnailUrl?.trim() === "" ||
      homeSubSection?.subCategoryId == null ||
      homeSubSection?.thumbnailUrl == null ||
      homeSubSection?.thumbnailUrl?.trim() === "" ||
      homeSubSection?.title == null ||
      homeSubSection?.title?.trim() === ""
    ) {
      alert("Please fill all the details!");
      return;
    } else {
      const resp = await dispatch(
        editHomeSubSection({
          subSectionId: editId,
          homeId: homeId,
          data: homeSubSection,
        })
      );
      if (resp?.type?.indexOf("fulfilled")) {
        handleAdding();
      }
    }
  };
  return (
    <div className="fixed flex items-center justify-center top-0 bg-[#00000083] z-50 w-screen h-screen right-0 overflow-auto">
      {/* select sub section  */}
      {isSubCategory && (
        <ANSubSecSelectSubCategory
          handleAdding={handleSubCategory}
          handleCategory={handleCategory}
          selectedId={homeSubSection?.subCategoryId}
        />
      )}
      <form
        className="relative flex flex-col  h-[550px] w-[450px]  bg-white rounded-xl"
        onSubmit={handleSubmit}>
        <div className="absolute right-0 -top-11 bg-white rounded-full text-3xl p-1 ">
          <RxCross1
            className="cursor-pointer p-1 font-bold text-black"
            onClick={handleAdding}
          />
        </div>

        {/* div for add new sub-section  */}
        <div className="flex h-[12vh] justify-center items-center text-2xl font-bold capitalize py-3 ">
          Edit sub-section
        </div>
        <div className=" items-center flex-1 overflow-y-auto no-scrollbar w-full px-5">
          {/* div for the title of the add new sub-section  */}
          <div className="relative flex border h-[7vh]  my-2 rounded-lg border-gray-500 p-2">
            <span className="flex absolute justify-center items-center border-[#989696] w-12 -top-3 left-6 bg-white text-sm">
              Title
            </span>
            <input
              type="text"
              value={homeSubSection?.title}
              name="title"
              onChange={handleChange}
              placeholder="Enter the Title of Sub-Section"
              className="w-full outline-none p-1 px-3 "></input>
          </div>

          <UploadFileTemp
            data={homeSubSection}
            name={"thumbnailUrl"}
            text={"Web Tumbnail"}
            handleUploaded={handleUploaded}
          />
          <UploadFileTemp
            data={homeSubSection}
            name={"appThumbnailUrl"}
            text={"App Tumbnail"}
            handleUploaded={handleUploaded}
          />
          <div className="relative flex border h-[10vh]  my-2 mt-3 rounded-lg border-gray-500 p-2">
            <span className="flex absolute justify-center items-center border-[#989696] w-24 -top-3 left-6 bg-white text-sm">
              Description
            </span>
            <input
              type="text"
              name="description"
              value={homeSubSection?.description}
              placeholder="Enter the Description of Sub-Section"
              className="w-full outline-none p-1 px-3 "></input>
          </div>
          <div
            className="flex items-center justify-center border h-[7vh]  mb-5 mt-3 border-[#989696] rounded-lg text-[#7f7c7c] text-lg cursor-pointer"
            onClick={setSubCategory}>
            {homeSubSection?.subCategoryId ? (
              <span className="text-black">
                {/* {subCategoryId} */}
                {subCategoryName || homeSubSection?.subCategoryId}
              </span>
            ) : (
              "--Select Sub-Category--"
            )}
          </div>
        </div>
        <div className="flex h-[10%] text-2xl mt-4 overflow-hidden bottom-0 rounded-b-xl">
          <button className="w-[50%] bg-[#c5c3c3]" onClick={handleAdding}>
            Cancel
          </button>
          <button className="w-[50%] bg-primary-max text-white">Save</button>
        </div>
      </form>
    </div>
  );
};

export default EditSubSectionHomeLayout;
