import React, { useState } from "react";
import { RxCross1 } from "react-icons/rx";
import { BiImageAdd } from "react-icons/bi";
import UploadFileTemp from "./UploadFileTemp";
import { useDispatch } from "react-redux";
import { editHomeSection } from "../features/Home/homeSlice";

const DBChange = ({ handleAdding, homeData }) => {
  const dispatch = useDispatch();

  const [bannerData, setBannerData] = useState(homeData);

  const handleUpload = (name, url) => {
    if (url != null && url?.trim() != "") {
      setBannerData({ ...bannerData, [name]: url });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e?.target;
    if (value != null || value?.trim() != "") {
      setBannerData({ ...bannerData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (bannerData == homeData) {
      alert("No changes found!");
      return;
    } else if (
      bannerData?.title == null ||
      bannerData?.title?.trim() == "" ||
      bannerData?.appBannerUrl == null ||
      bannerData?.appBannerUrl?.trim() == "" ||
      bannerData?.bannerImgUrl == null ||
      bannerData?.bannerImgUrl?.trim() == ""
    ) {
      alert("Please fill all details!");
      return;
    } else {
      const resp = await dispatch(
        editHomeSection({ id: homeData?.id, data: bannerData })
      );
      if (resp?.type?.indexOf("fulfilled")) {
        handleAdding();
      }
    }
  };
  console.log(bannerData);
  return (
    <div className="fixed flex items-center justify-center top-0 bg-[#00000083] z-40 w-screen right-0 h-screen">
      <form
        className="flex flex-col relative  h-[450px] w-[450px] rounded-xl bg-white"
        onSubmit={handleSubmit}>
        <div className="absolute p-1 -top-7 -right-1 m-1 text-3xl font-bold bg-white rounded-3xl -my-4">
          <RxCross1
            className="cursor-pointer p-1 font-bold"
            onClick={handleAdding}
          />
        </div>
        <div className="flex justify-center items-center h-[75px] text-3xl font-mono font-bold">
          Change Banner
        </div>

        {/* This div contains the options for Cards or Banners   */}
        <div className="flex flex-row h-[30px] gap-4 mx-6 my-1">
          <div className="flex justify-center border-2 w-20 border-pink-500 rounded-md text-[#f14ba9]">
            Banner
          </div>
        </div>

        {/* This div contains title */}
        <div className="flex-1 w-full mb-20 overflow-y-auto no-scrollbar">
          <div className="border border-black p-2 rounded-lg  h-[50px] mx-6 mt-10 relative">
            <span className="absolute -top-3 bg-white px-4  left-6 text-black font-semibold text-sm  capitalize">
              Title
            </span>
            <input
              type="text"
              name="title"
              placeholder="Enter the title of your Card"
              value={bannerData?.title}
              onChange={handleChange}
              className="w-full h-full px-4 outline-none"
            />
          </div>
          <div className="border border-black p-2 rounded-lg  h-[50px] mx-6 mt-10 relative">
            <span className="absolute -top-3 bg-white px-4  left-6 text-black font-semibold text-sm  capitalize">
              Redirection Link
            </span>
            <input
              name="interLinkUrl"
              type="text"
              placeholder="Enter the title of your Card"
              value={bannerData?.interLinkUrl}
              onChange={handleChange}
              className="w-full h-full px-4 outline-none"
            />
          </div>

          {/* This div contains the image file to be uploaded in the Banner */}
          <UploadFileTemp
            name={"bannerImgUrl"}
            text={"Web banner"}
            data={bannerData}
            handleUploaded={handleUpload}
          />
          <UploadFileTemp
            name={"appBannerUrl"}
            text={"App banner"}
            data={bannerData}
            handleUploaded={handleUpload}
          />
          {/* Sequence id */}
          <div className="border border-black p-2 rounded-lg  h-[50px] mx-6 mt-10 relative">
            <span className="absolute -top-3 bg-white px-4  left-6 text-black font-semibold text-sm  capitalize">
              Sequency Id
            </span>
            <input
              name="seqId"
              type="number"
              placeholder="Enter the title of your Card"
              value={bannerData?.seqId}
              onChange={handleChange}
              className="w-full h-full px-4 outline-none"
            />
          </div>
        </div>

        <div className="flex flex-row absolute bottom-0 w-full h-[55px] rounded-b-xl overflow-hidden">
          <button
            // onClick={handleNew}
            className="flex w-1/2 justify-center items-center text-2xl bg-gray-300 "
            onClick={handleAdding}>
            Cancel
          </button>
          <button
            type="submit"
            className="flex w-1/2 justify-center items-center text-2xl bg-primary-max text-white">
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

export default DBChange;
