import React from "react";

import { useState } from "react";
import DBChange from "./DBChange";
import { FaEyeSlash } from "react-icons/fa6";
import { BiSolidShow } from "react-icons/bi";
import { useDispatch } from "react-redux";
import Confirm from "./Confirm";
import GetCode from "./GetCode";
import { hideHomeSection, showHomeSection } from "../features/Home/homeSlice";


const BannerDropdown = ({ image, homeData, isVisible , homeId}) => {
  const [isChange, setChange] = useState(false);
  const dispatch = useDispatch();
  const [changingVisibility, setChangingVisibility] = useState(null);
  const [isAskingCode, setAskingCode] = useState(null);
  const [catagoryName, setCatagoryName] = useState("");
  const [catagoryHidden, setCatagoryHidden] = useState(false);

  const handleConfirm = (confirmation = false) => {
    if (confirmation) {
      setAskingCode(changingVisibility);
    }
    setChangingVisibility(null);
    // setAction(null);
    setCatagoryName("");
  };


  // console.log(homeSubSection);
  const handleVisibility = async (confirmation = "") => {
    if (confirmation === "correct" && isVisible) {
      await dispatch(
        hideHomeSection(homeId)
      );
    }
    else if(confirmation === "correct" && !isVisible){
      await dispatch(
        showHomeSection(homeId)
      );
    }
    
    else if (confirmation !== "close") {
      alert("Unauthorized!");
    }
    setAskingCode(null);
  };









  const handleChange = () => {
    setChange(!isChange);
  };
  return (
    <div>
      {isChange && <DBChange handleAdding={handleChange} homeData={homeData} />}
      <div className="border mx-3 mt-4 mb-4 shadow-lg shadow-gray-500">
        <div className="relative flex justify-center items-start border h-44">
          <img src={image} alt="" className="w-[60%] h-[90%]" />
        </div>
      </div>
      <div>
      {/* confirm popup */}
      {changingVisibility && (
        <Confirm
          question={
            <span className="lowercase">
              {"Are you sure to "} <strong>{catagoryHidden}</strong>
              {` Catagory ${catagoryName}?`}
            </span>
          }
          handleConfirmation={handleConfirm}
        />
      )}
      {/* code popup */}
      {isAskingCode && (
        <GetCode
          question={"Enter authentication code."}
          handleCode={handleVisibility}
        />
      )}
      
      </div>
      <div
        className="flex flex-row mx-1 h-10 my-3 gap-4"
        >
        <div onClick={handleChange} className="flex justify-center items-center border w-full font-semibold bg-[#EEBF00] text-white text-lg uppercase cursor-pointer">
          Change
        </div>
        {isVisible ? (
            <div
              className="flex flex-col justify-center items-center border w-1/12 font-semibold bg-primary text-white bg-black px-y cursor-pointer text-sm"
              onClick={() => {
              setCatagoryHidden("Hide");
              setChangingVisibility(homeId);
              }}>
              <FaEyeSlash className="text-xl" />
              Hide
            </div>
          ) : (
            <div
              className="flex flex-col justify-center items-center border w-1/12 font-semibold bg-primary text-black bg-white px-y cursor-pointer text-sm"
              onClick={() => {
              setCatagoryHidden("Show");
              setChangingVisibility(homeId);
              }}>
              <BiSolidShow className="text-xl" />
              Show
            </div>
          )}
      </div>
    </div>
  );
};

export default BannerDropdown;
