import React, { useState } from "react";
import { RxCross1 } from "react-icons/rx";
import { BiImageAdd } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { addHome } from "../features/Home/homeSlice";
import { useEffect } from "react";
import { clearFile, uploadFile } from "../features/Files/FilesSlice";

const AddNew = ({ handleAdding }) => {
  const dispatch = useDispatch();

  const { isLoading, uploadUrl } = useSelector((store) => store.file);
  const [isBanner, setBanner] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [changeFile, setChangedFile] = useState(null);
  const [title, setTitle] = useState("");
  const [orientation, setOrientation] = useState("");
  const [appBannerUrl, setAppBannerUrl] = useState("");
  const [interLinkUrl, setInterLinkUrl] = useState("");
  const [bannerImgUrl, setBannerImgUrl] = useState("");

  // const dispatch = useDispatch();

  const handleBannerClick = (location) => {
    location === "cards" ? setBanner(false) : setBanner(true);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile({
        ...selectedFile,
        file: file,
        filepreview: URL.createObjectURL(file),
      });
    }
  };

  const handleUpload = () => {
    const fileData = new FormData();
    if (selectedFile?.file) {
      fileData.append("file", selectedFile.file, selectedFile?.file?.name);
      dispatch(uploadFile(fileData));
    }
  };

  const handleChange = (e) => {
    const value = e?.target?.value;
    const name = e?.target?.name;
    if (name === "orientation") {
      setOrientation(value);
    } else if (name === "interLinkUrl") {
      setInterLinkUrl(value);
    } else setTitle(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const dateTime = new Date();
    if (isBanner === true) {
      if (title === "" || bannerImgUrl === "") {
        alert("Please fill out all the fields");
      } else {
        dispatch(
          addHome({
            title: title,
            bannerImgUrl: bannerImgUrl,
            interLinkUrl: interLinkUrl,
            appBannerUrl: appBannerUrl,
            completed: false,
            updatedDataTime: dateTime.toISOString(),
          })
        );
        dispatch(clearFile());
        handleAdding();
      }
    } else {
      if (title === "") {
        alert("Please enter the title for your Card!");
      } else if (title !== "") {
        dispatch(
          addHome({
            title: title,
            bannerImgUrl: null,
            orientation: orientation,
            completed: false,
            updatedDataTime: dateTime.toISOString(),
          })
        );
        handleAdding();
      }
    }
  };

  useEffect(() => {
    if (uploadUrl) {
      setBannerImgUrl(uploadUrl);
    }
  }, [uploadUrl]);

  useEffect(() => {
    dispatch(clearFile());
  }, []);

  return (
    <div className="fixed flex items-center justify-center top-0 bg-[#00000083] z-40 w-screen right-0 h-screen">
      <form
        className={`handleBannerClick ${
          isBanner === true
            ? "flex flex-col relative  h-[450px] w-[450px] rounded-xl bg-white"
            : "flex flex-col relative  h-[400px] w-[450px] rounded-xl bg-white"
        }`}
        onSubmit={handleSubmit}>
        <div className="absolute p-1 -top-7 -right-1 m-1 text-3xl font-bold bg-white rounded-3xl -my-4">
          <RxCross1
            className="cursor-pointer p-1 font-bold"
            onClick={handleAdding}
          />
        </div>
        <div className="flex justify-center items-center h-[75px] text-3xl font-mono font-bold">
          Add New
        </div>

        {/* This div contains the options for Cards or Banners   */}
        <div className="flex flex-row h-[30px] gap-4 mx-6 my-1">
          <div
            className={` ${
              isBanner === true
                ? "flex justify-center border-2 w-20 border-gray-500 rounded-md text-gray-400 cursor-pointer"
                : "flex justify-center border-2 w-20 border-primary-600 rounded-md text-primary-max"
            }`}
            onClick={() => handleBannerClick("cards")}>
            Cards
          </div>
          <div
            className={` ${
              isBanner === true
                ? "flex justify-center border-2 w-20 border-primary-600 rounded-md text-primary-max"
                : "flex justify-center border-2 w-20 border-gray-500 rounded-md text-gray-400 cursor-pointer"
            }`}
            onClick={() => handleBannerClick("banner")}>
            Banner
          </div>
        </div>
        <div className=" flex-1 overflow-auto no-scrollbar">
          {/* This div contains title */}
          <div
            className={`handleBannerClick ${
              isBanner === true
                ? "border border-black p-2 rounded-lg  h-[50px] mx-6 mt-10 relative"
                : "border border-black p-2 rounded-lg  h-[50px] mx-6 mt-10 relative"
            }`}>
            <span className="absolute -top-3 bg-white px-4  left-6 text-black font-semibold text-sm  capitalize">
              Title
            </span>
            <input
              type="text"
              name="title"
              placeholder="Enter the title of your Card"
              className="w-full h-full px-4 outline-none"
              value={title}
              onChange={handleChange}
            />
          </div>
          {/* Link */}
          {isBanner && (
            <div className="border border-black p-2 rounded-lg  h-[50px] mx-6 mt-10 relative">
              <span className="absolute -top-3 bg-white px-4  left-6 text-black font-semibold text-sm  capitalize">
                Redirection Link
              </span>
              <input
                type="text"
                name="interLinkUrl"
                placeholder="Enter Banner Redirection Link"
                className="w-full h-full px-4 outline-none"
                value={interLinkUrl}
                onChange={handleChange}
              />
            </div>
          )}

          {/* Orientation */}
          {!isBanner && (
            <div className="border border-black p-2 rounded-lg  h-[50px] mx-6 mt-10 relative">
              <span className="absolute -top-3 bg-white px-4  left-6 text-black font-semibold text-sm  capitalize">
                orientation
              </span>

              <select
                name="orientation"
                id="orientation"
                className="w-full h-full px-4 outline-none"
                value={orientation}
                onChange={handleChange}>
                <option value="" selected disabled>
                  Select Orientation
                </option>
                <option value="wide">Wide</option>
                <option value="tall">Tall</option>
              </select>
            </div>
          )}

          {/* This div contains the image file to be uploaded in the Banner */}
          {isBanner && <div className="text-center mt-5">Web Banner</div>}
          <div
            className={`handleBannerClick ${
              isBanner === true ? "h-[180px] text-center m-3" : "hidden"
            }`}>
            <label>
              <input
                id="fileInput"
                type="file"
                onChange={handleFileChange}
                style={{ display: "none" }}
              />

              <div>
                {selectedFile ? (
                  <img
                    src={selectedFile?.filepreview}
                    alt=""
                    className="h-24 w-24 object-cover bg-gray-50 text-xs cursor-pointer mx-[165px] 	my-2"
                  />
                ) : (
                  <span>
                    <BiImageAdd className="text-8xl mx-[165px] 	my-2 text-[#848383] cursor-pointer" />
                  </span>
                )}
              </div>
              <div className="flex justify-center items-center">
                <div
                  className="border bg-[#AFAFAF] text-white mx-1 px-4 font-mono"
                  type="button">
                  Change
                </div>

                <button
                  type="button"
                  className="border bg-[#EEBF00] text-white mx-1 px-4 font-mono "
                  onClick={handleUpload}>
                  {isLoading ? (
                    <span className="text-sm">Uploading...</span>
                  ) : (
                    "Upload"
                  )}
                </button>
              </div>
            </label>
          </div>

          {/* Mobile banner */}
          {isBanner && <div className="text-center">Mobile Banner</div>}
          <div
            className={`handleBannerClick ${
              isBanner === true ? "h-[200px] text-center m-3" : "hidden"
            }`}>
            <label>
              <input
                id="fileInput"
                type="file"
                onChange={handleFileChange}
                style={{ display: "none" }}
              />

              <div>
                {selectedFile ? (
                  <img
                    src={selectedFile?.filepreview}
                    alt=""
                    className="h-24 w-24 object-cover bg-gray-50 text-xs cursor-pointer mx-[165px] 	my-2"
                  />
                ) : (
                  <span>
                    <BiImageAdd className="text-8xl mx-[165px] 	my-2 text-[#848383] cursor-pointer" />
                  </span>
                )}
              </div>
              <div className="flex justify-center items-center">
                <div
                  className="border bg-[#AFAFAF] text-white mx-1 px-4 font-mono"
                  type="button">
                  Change
                </div>

                <button
                  type="button"
                  className="border bg-[#EEBF00] text-white mx-1 px-4 font-mono "
                  onClick={handleUpload}>
                  {isLoading ? (
                    <span className="text-sm">Uploading...</span>
                  ) : (
                    "Upload"
                  )}
                </button>
              </div>
            </label>
          </div>
        </div>

        <div className="flex flex-row absolute bottom-0 w-full h-[55px] rounded-b-xl overflow-hidden">
          <button
            // onClick={handleNew}
            className="flex w-1/2 justify-center items-center text-2xl bg-gray-300 "
            onClick={handleAdding}>
            Cancel
          </button>
          <button
            type="submit"
            className="flex w-1/2 justify-center items-center text-2xl bg-primary-max border-primary-600 text-white">
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddNew;
