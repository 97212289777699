import React, { useEffect, useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  fetchCompany,
  // fetchPartner,
} from "../../features/Partner/PartnerSlice";
import ManagePartner from "../../components/ManagePartner";

const Companies = () => {
  const { companyList } = useSelector((store) => store.partner);
  const dispatch = useDispatch();
  const [isAdding, setAdding] = useState(false);
  console.log(companyList);



  const handleAddingPartner = (flag) => {
    setAdding(flag);
  };

  useEffect(() => {
    dispatch(fetchCompany({ pageNo: 0, pageSize: 20 }));
  }, []);
  return (
    <div className="relative w-full h-full bg-white mb-10 min-h-screen">
      {/* popus */}
      {isAdding && <ManagePartner handleAdding={handleAddingPartner} />}
      {/* Search and add */}
      <div className="px-4 relative flex justify-between items-center pb-4 pt-8 m-auto ">
        <input
          type="text"
          className="p-2 my-4 rounded-md outline-none border"
          placeholder="Search "
        />
        <button
          className="bg-primary-600 text-white px-4 py-2 rounded-md"
          onClick={() => handleAddingPartner(true)}>
          Add New
        </button>
      </div>
      {/* data */}
      <div className=" w-full px-4  m-auto min-h-max max-[1300px]:text-xs max-[1000px]:text-[8px] max-[1450px]:text-sm">
        {/* Head of the table */}
        <div className="h-10 border-x border-t justify-center shadow-md bg-primary-70 text-white  flex items-center  mb-3 overflow-hidden rounded-md">
          <span className="w-[25%]  h-full text-center capitalize py-2 overflow-hidden">
            Company ID
          </span>
          <span className="w-[25%] h-full text-center capitalize py-2">Partner ID</span>

          <span className="w-[25%] h-full text-center capitalize py-2">Company Name</span>

          <span className="w-[25%] h-full text-center capitalize py-2 ">
          Company Domain
          </span>
        </div>

        {companyList?.map((elem, index) => {
          return (
            <Link
              to={`/company/company-detail/${elem?.id}`}
              className={
                "h-12 w-full hover:border-gray-400 flex items-center border-x  rounded-md relative  tracking-wider text-sm  " +
                (index % 2 !== 0 && " bg-gray-200")
              }
              key={index}>
              <div className="w-[25%] pl-5 text-center capitalize  py-2 overflow-hidden font-semibold">
                {elem?.id}
              </div>
              <span className="w-[25%]  text-center capitalize py-2 ">
                {elem?.partnerId}
              </span>
              <span className="w-[25%] justify-center items-center capitalize py-2 overflow-hidden ">
                <div className=" w-full text-center ">
                {elem?.companyName}
                </div>
              </span>
              <span className="w-[25%]   text-center capitalize py-2 overflow-hidden ">
                {elem?.companyDomain}
              </span>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default Companies;
