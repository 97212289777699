import React, { useEffect } from "react";
import { useState } from "react";
import { IoAdd } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { BsThreeDotsVertical, BsHddStackFill } from "react-icons/bs";
import { AiFillEdit, AiTwotoneEyeInvisible } from "react-icons/ai";
import { Link, useParams } from "react-router-dom";
import { fetchSubCategory } from "../../features/SubCategory/SubCategorySlice";
import AddSubCategory from "../../components/AddSubCategory";
import EditSubCategory from "../../components/EditSubCategory";
import { fetchIssue } from "../../features/Issue/IssuesSlice";
import AddIssue from "../../components/AddIssue";
import EditIssue from "../../components/EditIssue";

const Issues = () => {
  const dispatch = useDispatch();
  const { subCategoryId } = useParams();

  const [isAddingCatagory, setAdding] = useState(false);
  const [action, setAction] = useState(null);
  const [editing, setEditing] = useState(null);
  const { SubCategories } = useSelector((state) => state.subcategory);
  const { issuesList } = useSelector((state) => state.issue);
  // console.log(SubCategories);

  const handleAddCatagoryClick = () => {
    setAdding(!isAddingCatagory);
  };
  const handleAction = (id) => {
    if (action === id) {
      setAction(null);
      return;
    }
    setAction(id);
  };
  const handleEditing = (index) => {
    if (index > 0) {
      setEditing(index);
    } else {
      setEditing(null);
    }

    handleAction(null);
  };
  useEffect(() => {
    dispatch(fetchIssue(subCategoryId));
    window.scrollTo(0, 0);
  }, []);

  //   console.log(SubCategories.find(({ id }) => id == subCategoryId).name);

  return (
    <div className="relative w-full h-full bg-white mb-10 min-h-screen">
      {/* popus */}
      {/* Add Catagory  */}
      {isAddingCatagory && (
        <AddIssue
          handleAdding={handleAddCatagoryClick}
          subCatagoryId={subCategoryId}
        />
      )}
      {/* Edit Catagory */}
      {editing != null && (
        <EditIssue
          edit={issuesList.filter((item) => item.id == editing)[0]}
          handleEditing={handleEditing}
          subCatagoryId={subCategoryId}
        />
      )}
      {/* Search and add */}
      <h1 className="m-auto w-max text-4xl uppercase font-mono font-semibold mt-8">
        {/* {SubCategories[subCategoryId - 1]?.name} */}
        {"Issues-" + SubCategories.find((obj) => obj.id == subCategoryId)?.name}
      </h1>
      <div className="w-11/12  relative flex justify-start items-center pb-4 pt-2 m-auto ">
        <input
          type="text"
          className="p-2 my-4 mx-2 rounded-md outline-none bg-[#f3f9c4]"
          placeholder="Search "
        />
        <button
          className="text-white p-2 absolute right-6  rounded-sm bg-green-500 font-semibold"
          onClick={handleAddCatagoryClick}>
          Add New
        </button>
      </div>
      {/* data */}
      <div className=" w-11/12 m-auto min-h-max ">
        {/* Head of the table */}
        <div className="h-10 border-x border-t  shadow-md bg-primary-70 text-white  flex items-center  mb-3 overflow-hidden rounded-md">
          <span className="w-1/12 hover:bg-gray-100 h-full text-left pl-2 py-2 overflow-hidden">
            SL. No.
          </span>
          <span className="w-2/12 hover:bg-gray-100 h-full text-left py-2">
            Created
          </span>
          <span className="w-2/12 hover:bg-gray-100 h-full text-left py-2">
            Modified
          </span>
          <span className="w-2/12 hover:bg-gray-100 h-full text-left py-2">
            Name
          </span>
          <span className="w-1/12 hover:bg-gray-100 h-full text-left py-2">
            Ratings
          </span>
          <span className="w-1/12 hover:bg-gray-100 h-full text-left py-2">
            Review
          </span>
          <span className="w-1/12 hover:bg-gray-100 h-full text-left py-2">
            Price-Duration
          </span>
          <span className="w-1/12 hover:bg-gray-100 h-full text-center py-2">
            Icon
          </span>
          <span className="w-1/12 hover:bg-gray-100 h-full text-center py-2">
            Action
          </span>
        </div>
        {/* Catagories card*/}
        {issuesList?.map((element, index) => {
          const {
            id,
            issueName,
            issueReview,
            issueRating,
            issueDuration,
            issuePrice,
            issueIconUrl,
            createdDateTime,
            updatedDataTime,
          } = element;

          return (
            <div
              className={
                " h-9 w-full hover:border-gray-400 flex items-center border-x  rounded-md relative " +
                (index % 2 == 0 && " bg-gray-200")
              }
              key={index}>
              <div className="w-1/12  h-full flex items-center text-left py-2 pl-4">
                {id}
              </div>
              <div className="w-2/12  h-full flex items-center text-left py-2">
                {createdDateTime?.slice(0, 10).replaceAll("-", "/")}
              </div>
              <div className="w-2/12  h-full flex items-center text-left py-2">
                {updatedDataTime?.slice(0, 10).replaceAll("-", "/")}
              </div>
              <div className="w-2/12  h-full flex items-center text-left py-2">
                <h1>{issueName}</h1>
              </div>
              <div className="w-1/12  h-full flex items-center text-left py-2">
                <span className="float-right mr-10 px-2 text-sm   rounded-md text-white bg-green-400">
                  {issueRating}
                </span>
              </div>
              <div className="w-1/12  h-full flex items-center text-left py-2">
                <h1>{issueReview}</h1>
              </div>
              <div className="w-1/12  h-full flex items-center text-left py-2">
                <h1>{issuePrice + "-" + issueDuration}</h1>
              </div>
              <div className="w-1/12  h-full   py-2">
                <h1 className="w-max m-auto">
                  <img
                    src={issueIconUrl}
                    alt={issueName}
                    className="h-6 w-6 object-fill"
                  />
                </h1>
              </div>
              <span
                className="w-1/12  h-full flex justify-center py-2 hover:text-xl cursor-pointer"
                onClick={() => handleAction(id)}>
                <BsThreeDotsVertical />
              </span>
              {/* Actions Popup */}
              {action === id && (
                <div className="w-[150px]  h-[80px] bg-white absolute right-0 top-9  z-10   rounded-b-md shadow-lg flex flex-col  justify-center text-[14px] overflow-hidden pt-1">
                  <div
                    className="flex gap-2 hover:bg-gray-50 p-2 rounded-md font-mono text-gray-600 items-center cursor-pointer"
                    onClick={() => handleEditing(id)}>
                    <span>
                      <AiFillEdit />
                    </span>
                    <span>Edit</span>
                  </div>
                  <div className="flex gap-2 hover:bg-gray-50 p-2 rounded-md font-mono text-gray-600 items-center cursor-pointer">
                    <span>
                      <AiTwotoneEyeInvisible />
                    </span>
                    <span>Visibility</span>
                  </div>
                </div>
              )}
            </div>
          );
        })}

        {/* Add New */}
        <div
          className="w-full mt-4 border-2 border-dashed flex  items-center justify-center h-16 gap-4 text-2xl font-semibold text-gray-400 hover:bg-gray-50 cursor-pointer"
          onClick={handleAddCatagoryClick}>
          <span>
            <IoAdd />
          </span>
          <span>Add More Catagories</span>
        </div>
      </div>
    </div>
  );
};

export default Issues;
