import React, { useEffect, useState } from "react";
import { RxCross1 } from "react-icons/rx";
import { FaStar } from "react-icons/fa";
import { BsSearch } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { fetchCompany } from "../features/Partner/PartnerSlice";
import { postAssignCompany } from "../features/Bookings/BookingSlice";

const AssignCompany = ({ handlePartner, bookingId , onAssignCompany }) => {
  const dispatch = useDispatch();
  const { companyList } = useSelector((store) => store.partner);



  const handleAssignCompany = (bookingId, companyId, companyName) => {
    dispatch(postAssignCompany({ bookingId: bookingId, companyId: companyId }));
    if (onAssignCompany) {
        onAssignCompany(companyId , companyName); // Call the callback with companyId
      }
    handlePartner();
  };


  useEffect(() => {
    dispatch(fetchCompany());
  }, []);
  
  return (
    <div className="w-full h-full fixed top-0 left-0 z-50  flex items-center justify-center bg-[#0005]">
      <div className="h-[37vw] w-[38.5vw] bg-white relative min-w-max rounded-xl flex-col flex items-center">
        <RxCross1
          className="absolute -right-0 -top-11 p-2 bg-white rounded-full cursor-pointer text-4xl"
          onClick={() => handlePartner()}
        />
        <div className="flex justify-center items-center w-full  h-[5vw] py-4">
          <div className="flex justify-center w-[10.53vw] text-black font-bold text-2xl border-b border-black ">
            <p className="pb-[5px]">Company List</p>
          </div>
        </div>
        <div className="flex justify-center items-center w-full  h-[5vw] pb-4">
          <div className="flex w-[20vw] h-[2.5vw] border border-black rounded-[5px] ">
            <div className="flex items-center justify-center w-1/5 ">
              <BsSearch className="text- font-bold" />
            </div>
            <div className="flex items-center w-3/4 ">
              <input
                type="search"
                placeholder="Search by Company name"
                className="outline-none w-full text-sm"
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center  w-full overflow-auto  gap-4  no-scrollbar pb-4">
          {/* div containing details of a single partner  */}
          {companyList?.map((elem, index) => {
            return (
              <div key={elem.id} className="flex w-[35.28vw] py-4 bg-primary-40 rounded-[5px] ">
                <div className="flex flex-col gap-2 justify-center items-center  w-1/4">
                  <img
                    src={elem?.profile_image_url}
                    className="h-[4vw] w-[4vw] rounded-full "
                  />
                  <div className="flex justify-center items-center text-xs p-1 rounded-[5px] w-[6vw] text-white font-bold bg-primary-600">
                    {elem?.id}
                  </div>
                </div>
                <div className="flex flex-col justify-center w-1/2 pl-3">
                  <div className="text-lg font-bold">
                    {elem?.companyName}
                  </div>
                  <div className="text-sm">{elem?.companyDomain}</div>
                  {/* <div className="text-xs">{elem?.partnerEmail}</div> */}
                  <div className="flex text-xs pt-1">
                    <strong className="pr-1">Experience:</strong>8 years
                  </div>
                  <div className="flex gap-[2px] items-center text-xs">
                    <strong className="pr-1">Ratings:</strong>
                    <FaStar className="text-[#A49E07]" />
                    <FaStar className="text-[#A49E07]" />
                    <FaStar className="text-[#A49E07]" />
                    <FaStar className="text-[#A49E07]" />
                  </div>
                </div>
                <div className="flex items-center justify-center w-1/4">
                  <button
                    className="bg-[#23A030] text-white font-bold text-sm px-6 py-3 rounded-md"
                    onClick={() => handleAssignCompany(bookingId, elem?.id, elem?.companyName)}>
                    Assign
                  </button>
                </div>
              </div>
            );
          })}

          {/* repeated code from here  */}
        </div>
      </div>
    </div>
  );
};

export default AssignCompany;