import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

const CompanyDetails = () => {

  const location = useLocation();
  const [bookingType, setBookingType] = useState("future");
  const [bookingsData, setBookingData] = useState();
  const { bookings } = useSelector((store) => store.bookings);

  const handleTypeChange = (type) => {
    setBookingType(type);
  };

  useEffect(() => {
    setBookingData(
      bookings
        ?.slice(0, 5)
        ?.filter(
          (elem) =>
            elem?.bookingConfirms?.slice(0, 1)[0]?.status?.toLowerCase() ===
            bookingType
        )
    );
  }, [bookingType, bookings]);













  return (
    <div className=' flex flex-row gap-3'>

      <div className=' w-[709px] flex flex-col bg-white rounded-b rounded-t-xl shadow-[0px_4px_8px_0px_#00000040] h-[745px] my-5 '>
        <div className=' w-full bg-[#065668] rounded-t-xl h-[54px]'>
            <h1 className=' font-roboto text-[20px] font-bold leading-[23.44px] tracking-[0.04em] text-left text-white pl-6 py-4'>COMPANY DETAILS</h1>
        </div>
        <div className=' flex flex-row w-[709px] gap-5 '>
          <div className=' flex flex-row w-[404px] h-[113px] rounded-b-[10px] shadow-[0px_4px_8px_0px_#00000040] justify-center items-center gap-[30px] pl-5 pr-6'>
            <div className='w-[76px] h-[74px]  gap-0 rounded-[10px] border-[2px]'>
              <img src="" alt="" />
            </div>
            <div>
              <h1 className='font-roboto text-[14px] whitespace-nowrap font-bold leading-[18.75px] text-left'>Balaji electronic and Mobile Repair</h1>
              <h2 className='font-roboto text-[12px] font-normal leading-[16.41px] text-[#065668] text-left underline decoration-solid decoration-skip-ink-none'>balajielectronic.com</h2>
              <p className='font-roboto text-[12px] font-normal leading-[16.41px] text-[#00000099]'>Bangalore-49</p>
            </div>
          </div>
          <div className=' w-[172px] h-[113px] rounded-b-[10px] shadow-[0px_4px_8px_0px_#00000040] flex flex-col justify-center items-center gap-3'>
            <h1 className=' font-roboto text-[14px] font-semibold leading-[16.41px] text-center text-[#000000CC]'>Company’s Wallet Amount</h1>
            <h1 className='font-roboto text-[20px] font-extrabold leading-[23.44px] text-center text-[#000000CC]'>₹4,000</h1>
          </div>
          <div className=' w-[172px] h-[113px] rounded-b-[10px] shadow-[0px_4px_8px_0px_#00000040] flex flex-col justify-center items-center gap-3'>
            <h1 className=' font-roboto text-[14px] font-semibold leading-[16.41px] text-center text-[#000000CC]'>Total Transaction Amount</h1>
            <h1 className='font-roboto text-[20px] font-extrabold leading-[23.44px] text-center text-[#000000CC]'>₹4,000</h1>
          </div>
        </div>
        <div className=' h-[564px] shadow-[0px_4px_8px_0px_#00000040] mt-[14px] rounded-b pt-[14px] px-7'>
          <h1 className=' font-medium text-[#000000CC] text-[16px]'>Members List</h1>
          <div className=' mt-[10px]'>
<div className="rounded-lg shadow-md">
  {/* Combined Table Header and Search Bar */}
  <div className="w-full  rounded-lg shadow-md">
{/* Table Header */}
<div className="grid grid-cols-4  md:-ml-0 gap-2 md:gap-4 items-center h-[32px] justify-center text-center text-white text-[12px] md:text-[16px] font-bold bg-[#065668] rounded-t-[6px]">
  <div className="text-[14px] -ml-7 col-span">Member I'D</div>
  <div className="text-[14px] col-span">Profile Image</div>
  <div className="text-[14px] col-span">Member Name </div>
  <div className="text-[14px] col-span">Mobile Number</div>
</div>


{/* Search Bar (Aligned under Table Header) */}
<div className="grid grid-cols-4 justify-center pl-2  items-center  h-[38px] bg-[#06566880] text-white rounded-b-[6px]">
  <div className=' '>
    <input
      type="text"
      placeholder="Search"
      className=" placeholder-white text-white w-[128px]  bg-inherit pl-4 text-[12px]  focus:outline-none h-7 rounded-[5px] border border-white"
    />
  </div>
  <div className=' w-[128px] pl-4'>
    {/* <input
      type="text"
      placeholder="Search"
      className=" placeholder-white md:w-[128px] mt-2 bg-[#CC527E] pl-4 text-[12px] md:text-[14px] focus:outline-none h-7 rounded-[5px] border border-white"
    /> */}
  </div>
  <div>
    <input
      type="text"
      placeholder="Search"
      className="placeholder-white w-[128px] ml-2  bg-inherit pl-4 text-[12px] md:text-[14px] focus:outline-none h-7 rounded-[5px] border border-white"
    />
  </div>
  <div>
    <input
      type="text"
      placeholder="Search"
      className=" placeholder-white w-[128px] ml-4 bg-inherit pl-4 text-[12px] md:text-[14px] focus:outline-none h-7 rounded-[5px] border border-white"
    />
  </div>
</div>
  </div>
{/* Table Rows */}
{/* <div className="w-full  flex flex-col text-center ">
  {currentMembers.map((row, index) => (
    <div key={index} className="grid grid-cols-4  gap-2 md:gap-4 h-16 text-[14px] font-medium md:text-[16px] ml-[22px] mr-[21px] md:mr-0 md:ml-0  justify-between items-center text-center mb-2 bg-[#13111A] rounded-lg">
      <div className="text-[14px] px-2 py-4">{row.id}</div>
      <div className="text-[14px] px-2 py-4">{row.phone}</div>
      <div className="text-[14px] px-2 py-4">{row.amount}</div>
      <div className="text-[14px] px-2 py-4">{row.requestedDate}</div>
    </div>
  ))}
</div> */}
</div>
          </div> 
        </div>
      </div>

      <div className=' w-[600px] flex flex-col px-6 py-4 bg-white rounded shadow-[0px_4px_8px_0px_#00000040] h-[745px] my-5'>
      <div className="pb-4 border-b border-black flex justify-between ">
        <h1 className="text-xl font-bold">Bookings</h1>
        <div className="flex gap-3 text-xs font-medium ">
          {bookingType === "future" ? (
            <button className=" px-5 py-3 rounded-xl bg-primary-max text-white">
              Upcoming
            </button>
          ) : (
            <button
              className=" px-5 py-3 rounded-xl bg-[#D5F7FF]"
              onClick={() => handleTypeChange("future")}>
              Upcoming
            </button>
          )}
          {bookingType === "ongoing" ? (
            <button className=" px-5 py-3 rounded-xl bg-primary-max text-white">
              Ongoing
            </button>
          ) : (
            <button
              className=" px-5 py-3 rounded-xl bg-[#D5F7FF]"
              onClick={() => handleTypeChange("ongoing")}>
              Ongoing
            </button>
          )}
          {bookingType === "completed" ? (
            <button className=" px-5 py-3 rounded-xl bg-primary-max text-white">
              Completed
            </button>
          ) : (
            <button
              className=" px-5 py-3 rounded-xl bg-[#D5F7FF]"
              onClick={() => handleTypeChange("completed")}>
              Completed
            </button>
          )}
        </div>
      </div>
      <div className="flex flex-col justify-between flex-1">
        {/* <BookingCards bookings={bookingsData} /> */}
        {/* {location.pathname === "/" && (
          <Link
            to={
              bookingType === "completed"
                ? "/bookings/completed"
                : bookingType === "ongoing"
                ? "/bookings/ongoing"
                : "/bookings/upcoming"
            }
            className="p-2 underline mx-auto hover:bg-gray-50 cursor-pointer">
            Show More
          </Link>
        )} */}
      </div>
      </div>
    </div>
  )
}

export default CompanyDetails
