import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  addPartnerToLocalStorage,
  addUserToLocalStorage,
  getPartnerFromLocalStorage,
  getUserFromLocalStorage,
  removeUserFromLocalStorage,
} from "../../utils/localStorage";
import customFetch, { partnerCustomFetch } from "../../utils/axios";
import { clearStoreThunk } from "./userThunk";

const initialState = {
  isLoading: false,
  user: getUserFromLocalStorage(),
  partner: getPartnerFromLocalStorage(),
};

// signup api calling

// console.log(getValue);
// signin/ login api calling
export const loginUser = createAsyncThunk(
  "user/loginUser",
  async (user, thunkAPI) => {
    console.log(user);
    try {
      const resp = await customFetch.post("api/auth/login", user);
      console.log(resp);
      return resp.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        alert("Invalid credentials");
      } else if (error.response && error.response.status === 10001) {
        alert("Username already existing");
      } else if (error.response && error.response.status === 10002) {
        alert("Email already existing");
      } else if (error.response && error.response.status === 10003) {
        alert("Company already existing");
      }
      return thunkAPI.rejectWithValue(error.response.data.msg);
    }
  }
);



export const updateUserProfile = createAsyncThunk("user/updateUserProfile",
  async ({ userId, userDetail }, thunkAPI) => {
    let { accessToken } = getUserFromLocalStorage();
    try {
      const resp = await customFetch.put(`api/profile/${userId}`, userDetail,{
        headers: {
          authorization: `Bearer ${accessToken}`,
        }
    });
      console.log(resp);
      // alert(resp.data);
      return resp.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        alert("Invalid Email or Mobile");
      } else {
        alert(error.response.data.msg);
      }
    }
  }
)






//Login partner
export const loginPartner = createAsyncThunk(
  "user/loginPartner",
  async (user, thunkAPI) => {
    console.log(user);
    try {
      const resp = await partnerCustomFetch.post("api/auth/login", user);
      if (resp?.data?.role !== "ROLE_ADMIN") {
        throw new Error("Invalid Role");
      }

      return resp?.data;
    } catch (error) {
      if (error) {
        alert("Invalid credentials");
      }
      return thunkAPI.rejectWithValue(error.response.data.msg);
    }
  }
);

export const signUpUser = createAsyncThunk(
  "user/signUpUser",
  async (user, thunkAPI) => {
    console.log(user);
    try {
      const resp = await customFetch.post("api/auth/register", user);
      console.log(resp);
      return resp.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        alert("Invalid credentials");
      } else if (error.response && error.response.status === 400) {
        alert("Email already existing");
      } else if (error.response && error.response.status === 10001) {
        alert("Username already existing");
      } else if (error.response && error.response.status === 10002) {
        alert("Email already existing");
      } else if (error.response && error.response.status === 10003) {
        alert("Company already existing");
      } else {
        alert(error.response.data.msg);
      }
      return thunkAPI.rejectWithValue(error.response.data.msg);
    }
  }
);

export const forgotPassword = createAsyncThunk(
  "user/forgotPassword",
  async (email, thunkAPI) => {
    try {
      const resp = await customFetch.put("api/auth/forgetPassword", email);
      console.log(resp);
      alert(resp.data);
      return resp.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        alert("Invalid Email or Mobile");
      } else {
        alert(error.response.data.msg);
      }
    }
  }
);

export const setPassword = createAsyncThunk(
  "user/setPassword",
  async (password, thunkAPI) => {
    const url = window.location.href;
    const urls = url.split("=");
    const key = urls[1];
    console.log(key);

    try {
      const resp = await customFetch.put("api/auth/set-password", password, {
        headers: { key: key },
      });
      console.log(resp);
      return resp.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        alert("Invalid Credentials");
      } else {
        alert(error.response.data.msg);
      }
    }
  }
);
// signin/ login api calling

export const clearStore = createAsyncThunk("user/clearStore", clearStoreThunk);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logoutUser: (state, { payload }) => {
      // console.log(payload)
      state.user = null;
      removeUserFromLocalStorage();
      if (payload) {
        alert(payload);
      }
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(loginUser.fulfilled, (state, { payload }) => {
        const user = payload;
        state.isLoading = false;
        state.user = user;
        addUserToLocalStorage(user);
      })
      .addCase(loginPartner.rejected, (state, { payload }) => {
        state.isLoading = false;
      })
      .addCase(loginPartner.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(loginPartner.fulfilled, (state, { payload }) => {
        const user = payload;
        alert("Partner login successfully!");
        state.isLoading = false;
        state.partner = user;
        addPartnerToLocalStorage(user);
      })
      .addCase(loginUser.rejected, (state, { payload }) => {
        state.isLoading = false;
      })
      .addCase(clearStore.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(signUpUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(signUpUser.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(signUpUser.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(forgotPassword.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(forgotPassword.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(forgotPassword.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(setPassword.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(setPassword.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(setPassword.fulfilled, (state) => {
        state.isLoading = false;
      });
  },
});

export const { toggleSidebar, logoutUser } = userSlice.actions;

export default userSlice.reducer;
