import React, { useEffect, useState } from "react";
import { RxCross1 } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import { editHomeSection, fetchHome } from "../features/Home/homeSlice";

const EditSectionHomeLayout = ({ handleAdding, homeLayoutEdit, editId }) => {
  const dispatch = useDispatch();
  const [title, setTitle] = useState(homeLayoutEdit?.title);
  const [orientation, setOrientation] = useState(homeLayoutEdit?.orientation);
  const handleTitle = (e) => {
    setTitle(e.target.value);
  };
  const handleOrientation = (e) => {
    setOrientation(e.target.value);
  };

  const [seqId, setSeqId] = useState(homeLayoutEdit?.seqId);
  const handleSeqId = (e) => {
    setSeqId(e.target.value);
  };

  const handleEditSection = async (e) => {
    e.preventDefault();
    if (title === "") {
      alert("Please enter the title for your Card!");
    } else if (
      title === homeLayoutEdit?.title &&
      seqId === homeLayoutEdit?.seqId &&
      orientation == homeLayoutEdit?.orientation
    ) {
      alert("Please edit something before saving..!");
    } else {
      await dispatch(
        editHomeSection({
          id: editId,
          data: {
            title: title,
            seqId: seqId,
            bannerImgUrl: null,
            orientation: orientation,
            completed: homeLayoutEdit?.completed,
          },
        })
      );
      handleAdding();
    }
  };

  return (
    <div className="fixed flex items-center justify-center top-0 bg-[#00000083] z-50 w-screen right-0 h-screen">
      <form
        className="flex flex-col relative  h-[400px] w-[450px] rounded-xl bg-white"
        onSubmit={handleEditSection}>
        <div className="absolute p-1 -top-7 -right-1 m-1 text-3xl font-bold bg-white rounded-3xl -my-4">
          <RxCross1
            className="cursor-pointer p-1 font-bold"
            onClick={() => handleAdding()}
          />
        </div>
        <div className="flex justify-center items-center h-[75px] text-3xl font-mono font-bold">
          Edit Section
        </div>

        {/* This div contains the options for Cards or Banners   */}
        <div className="flex flex-row h-[30px] gap-4 mx-6 my-1">
          <div className="flex justify-center border-2 w-20 border-pink-500 rounded-md text-[#f14ba9]">
            Cards
          </div>
        </div>

        <div className="flex-1 w-full overflow-y-auto no-scrollbar pb-20">
          {/* This div contains title */}
          <div className="border border-black p-2 rounded-lg  h-[50px] mx-6 mt-[2.4rem] relative">
            <span className="absolute -top-3 bg-white px-4  left-6 text-black font-semibold text-sm  capitalize">
              Title
            </span>
            <input
              type="text"
              placeholder="Enter the title of your Card"
              value={title}
              className="w-full h-full px-4 outline-none"
              onChange={handleTitle}
            />
          </div>
          <div className="border border-black p-2 rounded-lg  h-[50px] mx-6 mt-10 relative">
            <span className="absolute -top-3 bg-white px-4  left-6 text-black font-semibold text-sm  capitalize">
              orientation
            </span>
            {/* Orientation */}
            <select
              name="orientation"
              id="orientation"
              className="w-full h-full px-4 outline-none"
              value={orientation}
              onChange={handleOrientation}>
              <option value="" selected disabled>
                Select Orientation
              </option>
              <option value="wide">Wide</option>
              <option value="tall">Tall</option>
            </select>
          </div>
          {/* This div contains seqid */}
          <div className="flex items-center p-2 rounded-lg  h-[50px] mx-6 mt-[2rem] gap-[1rem]">
            <div className=" bg-white text-black font-semibold text-sm  capitalize">
              Sequence Id
            </div>
            <div className=" border border-black rounded-lg p-1 w-[7rem]">
              <input
                type="number"
                placeholder="Eg. 2"
                value={seqId}
                className="w-full h-full px-[0.7rem] outline-none text-sm"
                onChange={handleSeqId}
              />
            </div>
          </div>
        </div>

        <div className="flex flex-row absolute bottom-0 w-full h-[55px] rounded-b-xl overflow-hidden">
          <button
            // onClick={handleNew}
            type="button"
            className="flex w-1/2 justify-center items-center text-2xl bg-gray-300 "
            onClick={() => handleAdding()}>
            Cancel
          </button>
          <button
            type="submit"
            className="flex w-1/2 justify-center items-center text-2xl bg-primary-max text-white">
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditSectionHomeLayout;
