import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getUserFromLocalStorage } from "../../utils/localStorage";
import customFetch from "../../utils/axios";

const initialState = {
	isLoadingFAQ: false,
	FAQList: [],
};

//GET API for FAQs
export const getFAQ = createAsyncThunk("faq/getFAQ", async (thunkAPI) => {
	const { accessToken } = getUserFromLocalStorage();
	try {
		const resp = await customFetch.get(`api/faq`, {
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
		});
		return resp?.data;
	} catch (error) {
		if (error?.resp) {
			alert("Error adding FAQS");
		}
		return thunkAPI.rejectWithValue(error.resp);
	}
});

//POST API for FAQs
export const postFAQ = createAsyncThunk(
	"faq/postFAQ",
	async ({ subCategoryId, data }, thunkAPI) => {
		console.log(data);
		const { accessToken } = getUserFromLocalStorage();

		try {
			const resp = await customFetch.post(`api/faq/${subCategoryId}`, data, {
				headers: {
					Authorization: `Bearer ${accessToken}`,
				},
			});

			return resp?.data;
		} catch (error) {
			if (error.response) {
				alert("Error Occured!");
			}
			return thunkAPI.rejectWithValue(error.response.msg);
		}
	}
);

//Delete API
export const deleteFAQ = createAsyncThunk(
	"faq/deleteFAQ",
	async (faqId, thunkAPI) => {
		const { accessToken } = getUserFromLocalStorage();
		try {
			const resp = await customFetch.delete(`api/faq/${faqId}`, {
				headers: {
					Authorization: `Bearer ${accessToken}`,
				},
			});
			console.log(resp);
			return resp?.data;
		} catch (error) {
			if (error?.resp) {
				alert("Error adding FAQS");
			}
			return thunkAPI.rejectWithValue(error.resp);
		}
	}
);



//EDIT FAQ API
export const putFAQ = createAsyncThunk(
	"faq/putFAQ",
	async ({ selectedId, data }, thunkAPI) => {
		console.log(data);
		const { accessToken } = getUserFromLocalStorage();

		try {
			const resp = await customFetch.put(`api/faq/${selectedId}`, data, {
				headers: {
					Authorization: `Bearer ${accessToken}`,
				},
			});

			return resp?.data;
		} catch (error) {
			if (error.response) {
				alert("Error Occured!");
			}
			return thunkAPI.rejectWithValue(error.response.msg);
		}
	}
);













const FAQslice = createSlice({
	name: "faq",
	initialState,
	reducers: {
		setLoadingFAQ: (state, { payload }) => {
			state.isLoadingFAQ = payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(postFAQ.fulfilled, (state) => {
				alert("FAQ Successfully Added!");
				state.isLoadingFAQ = false;
			})
			.addCase(postFAQ.pending, (state) => {
				state.isLoadingFAQ = true;
			})
			.addCase(postFAQ.rejected, (state) => {
				state.isLoadingFAQ = false;
				alert("Error in adding FAQ!");
			})
			.addCase(getFAQ.fulfilled, (state, { payload }) => {
				// alert("FAQ Successfully Added!");
				state.isLoadingFAQ = false;
				state.FAQList = payload;
			})
			.addCase(getFAQ.pending, (state) => {
				state.isLoadingFAQ = true;
			})
			.addCase(getFAQ.rejected, (state) => {
				state.isLoadingFAQ = false;
				alert("Error in adding FAQ!");
			})
			.addCase(deleteFAQ.fulfilled, (state) => {
				state.isLoadingFAQ = false;
			})
			.addCase(deleteFAQ.pending, (state) => {
				state.isLoadingFAQ = true;
			})
			.addCase(deleteFAQ.rejected, (state) => {
				state.isLoadingFAQ = false;
			});
	},
});

export default FAQslice.reducer;
