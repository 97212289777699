import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchPartner } from "../../features/Partner/PartnerSlice";
import ManagePartner from "../../components/ManagePartner";
import ManagePartnerAadhaar from "../../components/ManagePartnerAadhaar";
import ManagePartnerPan from "../../components/ManagePartnerPan";

const PartnerDetails = () => {
  const { partnerId } = useParams();
  const dispatch = useDispatch();

  const { partnerList } = useSelector((store) => store.partner);

  const [partnerData, setPartnerData] = useState(
    partnerList?.find((elem) => elem?.id == partnerId)
  );
  const [isManagingAadhaar, setManagingAadhaar] = useState(false);
  const [isManagingPan, setManagingPan] = useState(false);

  const handleAddAadhaar = () => {
    setManagingAadhaar((status) => !status);
  };

  const handleAddPan = () => {
    setManagingPan((status) => !status);
  };

  useEffect(() => {
    if (!partnerData) {
      dispatch(fetchPartner());
    }
  }, []);
  useEffect(() => {
    setPartnerData(partnerList?.find((elem) => elem?.id == partnerId));
  }, [partnerList]);

  console.log(partnerData);
  return (
    <div>
      {/* popups */}
      {isManagingAadhaar && (
        <ManagePartnerAadhaar
          handleManaging={handleAddAadhaar}
          partnerId={partnerId}
        />
      )}
      {isManagingPan && (
        <ManagePartnerPan handleManaging={handleAddPan} partnerId={partnerId} />
      )}
      <div className="flex  py-[4rem] h-[36rem]  gap-[2rem] justify-center w-full">
        <div className="flex rounded-lg w-[50rem]">
          <div className="flex flex-col items-center  py-[2rem] bg-[#1d1d1d]  w-1/3 rounded-l-lg gap-[1rem]">
            <img
              src={partnerData?.profile_image_url}
              className="rounded-full h-[10rem] w-[10rem] shadow-sm shadow-black bg-white mb-[2rem] object-cover"
            />

            <div className=" text-white">
              <span className="relative flex flex-col gap-[0.2rem]">
                <div className="absolute -top-3 left-4 px-3 text-sm bg-[#1d1d1d]">
                  Full Name
                </div>
                <input
                  type="text"
                  value={
                    partnerData?.partnerFirstName +
                    " " +
                    partnerData?.partnerLastName
                  }
                  className="h-[4rem] py-[1.3rem] pl-[1.2rem] pr-[2rem] w-[13rem] text-xl bg-[#1d1d1d] border border-white rounded-lg"
                />
              </span>
            </div>
            {/* <div className="text-white">Front - End Web Developer</div> */}
            <div className="flex justify-center items-center gap-[1rem] rounded-xl text-lg bg-primary-30 text-[#000]   w-[12rem] h-[3rem] mt-[3rem]">
              {/* <FaRegEdit /> */}
              EDIT
            </div>
          </div>
          <div className="flex flex-col h-full w-2/3 border-t border-r border-b border-black rounded-r-lg px-[2.5rem] py-[1rem] gap-[2.5rem]">
            <div className="   font-medium w-1/2  ">
              Partner Detail
              <div className="bg-[#00000046] h-[1.5px]"></div>
            </div>
            <div className="flex gap-[3rem]">
              <span className="relative flex flex-col gap-[0.2rem]">
                <div className="absolute -top-3 left-4 px-3 text-sm bg-white">
                  Email
                </div>
                <input
                  type="email"
                  name="email"
                  value={partnerData?.partnerEmail}
                  className="h-[2rem] py-[1.3rem] pl-[0.8rem] pr-[2rem] w-[13rem] text-sm bg-white border border-black rounded-lg"
                />
              </span>
              <span className="relative flex flex-col gap-[0.2rem] ">
                <div className="absolute -top-3 left-4 px-3 text-sm bg-white">
                  Mobile
                </div>
                <input
                  type="tel"
                  name="mobile"
                  value={partnerData?.partnerMobile}
                  className="h-[2rem] py-[1.3rem] pl-[0.8rem] pr-[2rem] w-[10rem] text-sm bg-white border border-black rounded-lg"
                />
              </span>
            </div>
            <div className="flex gap-[1rem]">
              <span className="relative flex flex-col gap-[0.2rem] ">
                <div className="absolute -top-3 left-4 px-3 text-sm bg-white">
                  Gender
                </div>

                <select
                  className="h-[2.66rem] py-[0.3rem] pl-[0.8rem] pr-[2rem] w-[8rem] text-sm bg-white border border-black rounded-lg appearance-none outline-none"
                  value={partnerData?.gender}>
                  <option selected value={null}>
                    None
                  </option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </select>
              </span>
              <span className="relative flex flex-col gap-[0.2rem] ">
                <div className="absolute -top-3 left-4 px-3 text-sm bg-white">
                  DOB
                </div>
                <input
                  type="date"
                  value={partnerData?.dateOfBirth}
                  className="h-[2rem] py-[1.3rem] pl-[0.8rem]  w-[6rem] text-sm bg-white border border-black rounded-lg"
                />
              </span>
              <span className="relative flex flex-col gap-[0.2rem] ">
                <div className="absolute -top-3 left-4 px-3 text-sm bg-white">
                  Location
                </div>
                <input
                  type="text"
                  value="Bangalore"
                  className="h-[2rem] py-[1.3rem] pl-[0.8rem] pr-[2rem] w-[10rem] text-sm bg-white border border-black rounded-lg"
                />
              </span>
            </div>
            <div className="flex gap-[1rem]">
              <span className="relative flex flex-col gap-[0.2rem] ">
                <div className="absolute -top-3 left-4 px-3 text-sm bg-white">
                  Alternate Mobile Number
                </div>
                <input
                  type="text"
                  value="+9172727272"
                  className="h-[2rem] py-[1.3rem] pl-[0.8rem] pr-[2rem] w-[15rem] text-sm bg-white border border-black rounded-lg"
                />
              </span>
              <span className="relative flex flex-col gap-[0.2rem] ">
                <div className="absolute -top-3 left-4 px-3 text-sm bg-white">
                  Category
                </div>
                <select
                  className="h-[2.66rem] py-[0.3rem] pl-[0.8rem] pr-[2rem] w-[10rem] text-sm bg-white border border-black rounded-lg appearance-none outline-none"
                  value={partnerData?.categoryType}>
                  <option selected value={null}>
                    None
                  </option>
                  <option value="mechanical">mechanical</option>
                  <option value="electrical">electrical</option>
                  <option value="electronics">electronics</option>
                </select>
              </span>
            </div>
            <div className="flex gap-[3rem]">
              <span className="relative flex flex-col gap-[0.2rem] ">
                <div className="absolute -top-3 left-4 px-3 text-sm bg-white">
                  Skilset
                </div>

                <div className=" flex gap-2 h-[2rem] py-[0.25rem] pl-[0.8rem] pr-[2rem] w-[15rem] text-sm bg-white border border-black rounded-lg">
                  {partnerData?.skillSet?.map((elem, index) => {
                    return <div key={index}>{elem + " ,"}</div>;
                  })}
                </div>
              </span>
            </div>
          </div>
        </div>

        <div className="flex flex-col border border-black rounded-lg px-[2.5rem] py-[1rem] gap-[1rem] w-[33rem] ">
          <div className="flex flex-col">
            <div className="  mb-2 font-medium w-1/3  ">
              Aadhaar
              <div className="bg-[#00000046] h-[0.5px] "></div>
            </div>
            {partnerData?.aadharNo || partnerData?.aadharFrontImgUrl ? (
              <div>
                <span className="relative flex flex-col gap-[0.2rem] mt-4 ">
                  <div className="absolute -top-3 left-4 px-3 text-sm bg-white">
                    Aadhaar No.
                  </div>
                  <input
                    type="text"
                    value={partnerData?.aadharNo}
                    className="h-[2rem] py-[1.3rem] pl-[0.8rem] pr-[2rem] w-full text-sm bg-white border border-black rounded-lg"
                  />
                </span>
                <div className="flex mt-4 gap-2">
                  <img
                    src={partnerData?.aadharFrontImgUrl}
                    alt="aadharFrontImgUrl"
                    className="h-20 w-full border border-black bg-white rounded-lg object-cover"
                  />
                  <img
                    src={partnerData?.aadharBackImgUrl}
                    alt="aadharBackImgUrl"
                    className="h-20 w-full border border-black bg-white rounded-lg object-cover"
                  />
                  <div className="w-full flex flex-col gap-2 justify-between text-sm">
                    <button className="w-full p-1 bg-primary-max uppercase text-white font-medium rounded-lg">
                      Verify
                    </button>
                    <button className="w-full p-1 bg-primary-max uppercase text-white font-medium rounded-lg">
                      Edit
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div
                className="text-xl border-dashed border-2 py-4 rounded-xl border-gray-400 text-center text-gray-400 cursor-pointer"
                onClick={handleAddAadhaar}>
                + Add Aadhaar
              </div>
            )}

            <div className="  mb-2 font-medium w-1/3 mt-5">
              Pan
              <div className="bg-[#00000046] h-[0.5px] "></div>
            </div>
            {partnerData?.panNo || partnerData?.panFrontImgUrl ? (
              <div>
                <span className="relative flex flex-col gap-[0.2rem] mt-4 ">
                  <div className="absolute -top-3 left-4 px-3 text-sm bg-white">
                    Pan No.
                  </div>
                  <input
                    type="text"
                    value={partnerData?.panNo}
                    className="h-[2rem] py-[1.3rem] pl-[0.8rem] pr-[2rem] w-full text-sm bg-white border border-black rounded-lg"
                  />
                </span>

                <div className="flex mt-4 gap-2">
                  <img
                    src={partnerData?.panFrontImgUrl}
                    alt="panFrontImgUrl"
                    className="h-20 w-full border border-black bg-white rounded-lg"
                  />

                  <div className="w-full flex flex-col gap-2 justify-between text-sm">
                    <button className="w-full p-1 bg-primary-max uppercase text-white font-medium rounded-lg">
                      Verify
                    </button>
                    <button className="w-full p-1 bg-primary-max uppercase text-white font-medium rounded-lg">
                      Edit
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div
                className="text-xl border-dashed border-2 py-4 rounded-xl border-gray-400 text-center text-gray-400 cursor-pointer"
                onClick={handleAddPan}>
                + Add PAN
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartnerDetails;
