import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getUserFromLocalStorage } from "../../utils/localStorage";
import customFetch from "../../utils/axios";

const initialState = {
  isLoadingCatg: false,
  totalPages: null,
  catagory: [],
};

export const getCatagories = createAsyncThunk(
  "catagry/getcatagories",
  async (queryParams, thunkAPI) => {
    console.log();
    const { accessToken } = getUserFromLocalStorage();

    try {
      const resp = await customFetch.get(
        `api/category/${queryParams?.origin >= 0 ? queryParams?.origin : 1}`,
        {
          params: queryParams,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      console.log(resp);
      return resp?.data;
    } catch (error) {
      if (error) {
        alert("Fialed to load catagory!");
      }
      return thunkAPI.rejectWithValue(error.response.msg);
    }
  }
);

// Change visibility of catagory
export const changeVisibility = createAsyncThunk(
  "catagry/changeVisibility",
  async ({ id, page }, thunkAPI) => {
    console.log(id);
    const { accessToken } = getUserFromLocalStorage();

    try {
      const resp = await customFetch.get(`/api/category/display/${id}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      thunkAPI.dispatch(
        getCatagories({
          page: page,
          size: 10,
        })
      );
      return resp?.data;
    } catch (error) {
      if (error) {
      }
      return thunkAPI.rejectWithValue(error.response.msg);
    }
  }
);

export const postCatagory = createAsyncThunk(
  "catagory/postcatagory",
  async (data, thunkAPI) => {
    console.log(data);
    const { accessToken } = getUserFromLocalStorage();

    try {
      const resp = await customFetch.post("api/category", data, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      console.log(resp);
      return resp?.data;
    } catch (error) {
      if (error.response) {
        alert("Error Occured!");
      }
      return thunkAPI.rejectWithValue(error.response.msg);
    }
  }
);

export const updateCategory = createAsyncThunk(
  "catagory/updatecategory",
  async (data, thunkAPI) => {
    console.log(data);
    const { accessToken } = getUserFromLocalStorage();
    try {
      const resp = await customFetch.put(`api/category/${data.id}`, data, {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      });
      return resp.data;
    } catch (error) {
      if (error.response) {
        alert("Error Occured!");
      }
      return thunkAPI.rejectWithValue(error.response.msg);
    }
  }
);

const CatagorySlice = createSlice({
  name: "catagory",
  initialState,
  reducers: {
    setLoadingCatg: (state, { payload }) => {
      state.isLoadingCatg = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postCatagory.fulfilled, (state) => {
        alert("Catagory Successfully Added!");
        state.isLoadingCatg = false;
      })
      .addCase(postCatagory.pending, (state) => {
        state.isLoadingCatg = true;
      })
      .addCase(postCatagory.rejected, (state) => {
        state.isLoadingCatg = false;
      })
      .addCase(getCatagories.fulfilled, (state, { payload }) => {
        state.catagory = payload.content;
        state.totalPages = payload.totalPages;
        // alert(payload.message);
        state.isLoadingCatg = false;
      })
      .addCase(getCatagories.pending, (state) => {
        state.isLoadingCatg = true;
      })
      .addCase(getCatagories.rejected, (state) => {
        state.isLoadingCatg = false;
      })
      .addCase(updateCategory.fulfilled, (state) => {
        alert("Updated Successfully!");
        state.isLoadingCatg = false;
      })
      .addCase(updateCategory.pending, (state) => {
        state.isLoadingCatg = true;
      })
      .addCase(updateCategory.rejected, (state) => {
        state.isLoadingCatg = false;
      })
      .addCase(changeVisibility.fulfilled, (state) => {
        alert("Updated Successfully!");
        state.isLoadingCatg = false;
      })
      .addCase(changeVisibility.pending, (state) => {
        state.isLoadingCatg = true;
      })
      .addCase(changeVisibility.rejected, (state) => {
        state.isLoadingCatg = false;
      });
  },
});
export default CatagorySlice.reducer;
export const { setLoadingCatg } = CatagorySlice.actions;
