import { configureStore } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import { combineReducers } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import UserSlice from "./features/user/UserSlice";
import homeSlice from "./features/Home/homeSlice";
import ProfileSlice from "./features/Profile/ProfileSlice";
import FilesSlice from "./features/Files/FilesSlice";
import CatagorySlice from "./features/Catagory/CatagorySlice";
import SubCategorySlice from "./features/SubCategory/SubCategorySlice";
import IssuesSlice from "./features/Issue/IssuesSlice";
import BookingSlice from "./features/Bookings/BookingSlice";
import PartnerSlice from "./features/Partner/PartnerSlice";
import CartSlice from "./features/Cart/CartSlice";
import FAQslice from "./features/FAQ/FAQslice";
import addressSlice from "./features/address/addressSlice";

const persitConfig = {
  key: "root",
  version: 1,
  storage,
};

const reducer = combineReducers({
  user: UserSlice,
  home: homeSlice,
  profile: ProfileSlice,
  file: FilesSlice,
  catagory: CatagorySlice,
  subcategory: SubCategorySlice,
  issue: IssuesSlice,
  bookings: BookingSlice,
  partner: PartnerSlice,
  cart: CartSlice,
  faq: FAQslice,
  address: addressSlice,
});

const persistedReducer = persistReducer(persitConfig, reducer);

export const store = configureStore({
  reducer: persistedReducer,
});
