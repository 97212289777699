import React, { useEffect, useState } from "react";
import { RxCross1, RxCross2 } from "react-icons/rx";
import { useDispatch, useSelector, useStore } from "react-redux";
import {
  addPartner,
  removePartnerData,
} from "../features/Partner/PartnerSlice";
import { IoIosAddCircle } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { FaPlus } from "react-icons/fa";

const initialState = {
  id: 0,
  partnerFirstName: "",
  partnerLastName: "",
  partnerMobile: "",
  partnerEmail: "",
  skillSet: [],
  categoryType: [],
  companyRegistration: false,
  companyId: 0,
  password: "",
  companyEmployer: false,
};

const ManagePartner = ({ handleAdding, edit }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [partnerData, setPartnerData] = useState(edit || initialState);
  const [isFocusedSkill, setFocusedSkill] = useState(false);
  const [skillSet, setSkillSet] = useState([]);
  const [inputValue, setInputValue] = useState("");

  const { isLoadingPartner, addedPartnerData } = useSelector(
    (store) => store.partner
  );

  //Add skills
  const handleSkill = () => {
    if (inputValue.trim() !== "" && skillSet?.length < 3) {
      setSkillSet([...skillSet, inputValue.trim()]);
      setInputValue("");
    }
  };
  const handleAddSkill = () => {
    handleSkill(); // Save the current skill
    setInputValue(''); // Clear the input for the next skill
  };

  const handleSkillSetInputChange = (event) => {
    if (skillSet?.length < 3) {
      setInputValue(event.target.value);
    }
  };
  //EYEPASSWORD
  // const togglePasswordVisibility = () => {
  //   setPasswordVisible((prev) => !prev);
  // };

  const handleSkillSetKeyDown = (event, next) => {
    if (event.key === "Enter") {
      handleSkill();
    }
    // else if (skillSet?.length === 3) {
    //   document.getElementById(next)?.focus();
    // }
  };
  const removeSkill = (indexToRemove) => {
    setSkillSet(skillSet.filter((_, index) => index !== indexToRemove));
  };

  const handleInputChange = (e) => {
    const name = e?.target?.name;
    const value = e?.target?.value;
    console.log(partnerData);
    if (name === "categoryType") {
      setPartnerData((prevData) => ({
        ...prevData,
        categoryType: [value],
      }));
    } else if (name === "partnerMobile") {
      setPartnerData({ ...partnerData, [name]: value?.slice(0, 10) });
    } else setPartnerData({ ...partnerData, [name]: value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      partnerData?.partnerFirstName?.trim() === "" ||
      partnerData?.partnerLastName?.trim() === "" ||
      partnerData?.partnerEmail?.trim() === "" ||
      skillSet?.length <= 0 ||
      partnerData?.categoryType?.length <= 0
    ) {
      alert("Please fill all the details!");
      return;
    }
    if (edit) {
      if (edit === partnerData) {
        alert("No changes found!");
      } else dispatch();
    } else {
      const password =
        partnerData?.partnerFirstName?.toLowerCase() +
        partnerData?.partnerMobile?.slice(-4);
      await dispatch(
        addPartner({ ...partnerData, skillSet: skillSet, password: password })
      );
    }
    handleAdding();
  };

  useEffect(() => {
    if (addedPartnerData?.userId) {
      navigate(`/partner/partner-detail/${addedPartnerData?.userId}`);
      dispatch(removePartnerData());
    }
  }, [addedPartnerData]);
  return (
    <div className="fixed flex items-center justify-center top-0 bg-[#00000083] z-40 w-screen right-0 h-screen">
      <form
        className={
          "flex flex-col relative  h-[450px] w-[450px] rounded-xl bg-white px-4"
        }
        onSubmit={handleSubmit}>
        <div className="absolute p-1 -top-7 -right-1 m-1 text-3xl font-bold bg-white rounded-3xl -my-4">
          <RxCross1
            className="cursor-pointer p-1 font-bold"
            onClick={() => handleAdding(false)}
          />
        </div>
        <div className="flex justify-center items-center h-[75px] text-3xl font-mono font-bold">
          {edit ? "Edit" : "Add New"}
        </div>
        {/* ############################################### */}

        {/* Sign-up UI starts here */}
        <div className="mb-2 mt-1 flex gap-2">
          {/* First Name*/}
          <div className="w-full">
            <input
              className="block w-full px-4 py-2 mt-2 text-neutral-800 bg-stone-100 border rounded-md focus:border-neutral-800 focus:ring-indigo-600 focus:outline-none focus:ring focus:ring-opacity-40"
              type="text"
              name="partnerFirstName"
              id="partnerFirstName"
              placeholder="First Name"
              required
              onChange={handleInputChange}
            />
          </div>
          <input
            type="text"
            name="partnerLastName"
            id="partnerLastName"
            placeholder="Last Name"
            onChange={handleInputChange}
            className="block w-full px-4 py-2 mt-2 text-neutral-800 bg-stone-100 border rounded-md focus:border-neutral-800 focus:ring-indigo-600 focus:outline-none focus:ring focus:ring-opacity-40"
          />
        </div>
        <div className="mb-2 mt-1 flex gap-2 ">
          {/* Category*/}
          <div className="w-2/4">
            <select
              className="block w-full px-2 py-2 mt-2 text-neutral-800 bg-stone-100 border rounded-md focus:border-neutral-800 focus:ring-indigo-600 focus:outline-none focus:ring focus:ring-opacity-40 capitalize appearance-none"
              type="text"
              name="categoryType"
              id="categoryType"
              required
              value={partnerData?.categoryType?.slice(0, 1)[0]}
              onChange={handleInputChange}>
              <option value="" disabled selected>
                Select Category
              </option>
              <option value="mechanical">mechanical</option>
              <option value="electrical">electrical</option>
              <option value="electronics">electronics</option>
            </select>
          </div>
          {/* Skills */}
          <div
            className="relative w-full h-full flex items-center overflow-x-scroll  border rounded-md"
            onClick={() => {
              document?.getElementById("skillSet")?.focus();
            }}>
            <div className="flex items-center gap-1 pl-2 ">
              {skillSet?.map((elem, index) => {
                return (
                  <div className=" bg-black text-white px-2 py-1 flex justify-between max-w-[9rem] overflow-hidden items-center rounded-md">
                    <span className="w-[5rem] overflow-hidden inline-block whitespace-nowrap overflow-ellipsis ">
                      {elem}
                    </span>
                    <div>
                      <RxCross2
                        onClick={() => removeSkill(index)}
                        className="cursor-pointer text-lg  "
                      />
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="flex items-center w-full">
  <input
    type="text"
    id="skillSet"
    value={inputValue}
    placeholder={
      skillSet?.length < 3 ? "Skills eg.TV repair,laptop repair" : ""
    }
    onKeyDown={(e) => handleSkillSetKeyDown(e, "")}
    onBlur={handleSkill}
    onChange={handleSkillSetInputChange}
    className="w-full h-full text-[1vw] max-[1000px]:text-[1.5vw] px-[1vw] py-[0.5vw] rounded-md"
  />
  {skillSet?.length < 3 && (
        <button
        type="button"
          onClick={handleAddSkill}
          className="ml-2    px-3 py-1"
        >
          <FaPlus />
        </button>
      )}
            </div>

            {/* <span className="bg-white absolute -top-[0.8vw] px-2 left-2 text-[1vw] h-[0.9vw]">
              Skills
            </span> */}
          </div>
        </div>

        <div className="mb-2 mt-5">
          {/* Email field */}
          <input
            type="number"
            name="partnerMobile"
            id="partnerMobile"
            required
            min={10}
            placeholder="Mobile Number"
            value={partnerData?.partnerMobile}
            onChange={handleInputChange}
            className="block w-full px-4 py-2 mt-2 text-neutral-800 bg-stone-100 border rounded-md focus:border-neutral-800 focus:ring-indigo-600 focus:outline-none focus:ring focus:ring-opacity-40"
          />
        </div>
        <div className="mb-2 mt-5">
          {/* Email field */}
          <input
            type="email"
            name="partnerEmail"
            id="partnerEmail"
            placeholder="Email Address"
            value={partnerData?.partnerEmail}
            onChange={handleInputChange}
            className="block w-full px-4 py-2 mt-2 text-neutral-800 bg-stone-100 border rounded-md focus:border-neutral-800 focus:ring-indigo-600 focus:outline-none focus:ring focus:ring-opacity-40"
          />
        </div>

        <div className="flex flex-row absolute bottom-0 w-full h-[55px] rounded-b-xl overflow-hidden left-0">
          <button
            // onClick={handleNew}
            type="button"
            className="flex w-1/2 justify-center items-center text-2xl bg-gray-300 "
            onClick={() => handleAdding(false)}>
            Cancel
          </button>
          <button
            type="submit"
            className="flex w-1/2 justify-center items-center text-2xl bg-primary-max border-primary-600 text-white"
            disabled={isLoadingPartner}>
            {isLoadingPartner ? "Loading..." : "Save"}
          </button>
        </div>

        {/* ################################################################################################ */}
      </form>
    </div>
  );
};

export default ManagePartner;
